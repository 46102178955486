.mainnav {
    $self: &;
    position: absolute;
    left: 0;
    top: rem(80);
    width: 100%;
    &.show {
        #{$self}__close-button {
            opacity: 1;
        }
    }
    &__collapse-inner {
        min-height: rem(590);
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top: rem(54);
        padding: rem(54) 0 rem(80);
        background-color: white;
        max-height: calc(100vh - rem(80));
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }
    &__inner{
        @include make-container(rem(30));
        max-width: rem(1520);
    }
    &__inner-row {
        margin-left: rem(-30);
        margin-right: rem(-30);
    }
    &__inner-col {
        padding-left: rem(30) !important;
        padding-right: rem(30) !important;
        display: flex;
        flex-flow: column nowrap;
    }
    &__col-title {
        font-size: rem(26);
        line-height: rem(30);
        margin-bottom: rem(34);
        text-decoration: none;
        cursor: default;
        pointer-events: none;
        &:before {
            display: none !important;
        }
    }
    &__list {
        display: flex;
        flex-flow: column nowrap;
        padding-left: 0;
        margin-bottom: 0;
    }
    &__sub-list {
        display: none;
        flex-flow: column nowrap;
        width: 100%;
        padding-left: 0;
        margin-bottom: 0;
    }
    &__li {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        border-bottom: rem(1) solid rgba(#0F1D37, .3);
        &:last-child {
            border-bottom-color: transparent;
        }
        &:hover {
            > #{$self}__list-item {
                color: $pr_green;
                font-weight: 600;
            }
            #{$self}__list-item-chevron {
                background-color: $pr_green;
                svg {
                    path {
                        fill: white;
                    }
                }
            }
        }
        &--sub-li {
            padding-left: rem(20);
            &:hover {
                > #{$self}__list-item {
                    font-weight: 500;
                    color: $pr_green;
                    text-decoration: underline;
                }
            }
        }
        &--has-sub-list {
            #{$self}__list-item-chevron {
                display: flex;
                transform: translateX(rem(6)) rotate(90deg);
            }
        }
        &--open {
            > #{$self}__list-item {
                color: $pr_green;
                font-weight: 600;
            }
            #{$self}__sub-list {
                display: flex;
            }
            #{$self}__list-item-chevron {
                background-color: $pr_green;
                transform: translateX(rem(6)) rotate(270deg);
                svg {
                    path {
                        fill: white;
                    }
                }
            }
        }
    }
    &__list-item {
        font-size: rem(16);
        line-height: rem(32);
        padding: rem(4) 0;
        color: $txt_dark_grey;
        text-decoration: none;
        width: 100% ;
        &:focus-visible {
            &:before {
                top: 0;
            }
            @include focus(rem(40), rem(30));
        }
    }
    &__list-item-chevron {
        position: absolute;
        display: none;
        justify-content: center;
        align-items: center;
        top: rem(10);
        right: rem(6);
        width: rem(19);
        height: rem(19);
        border-radius: 50%;
        transform: translateX(rem(6));
        pointer-events: none;
        svg {
            transform: translateX(rem(0)) scale(.85);
        }
    }
    &__close-button {
        position: absolute;
        right: rem(45);
        bottom: rem(50);
        font-weight: 500;
        &:focus-visible {
            @include focus(rem(10), rem(20), absolute, rem(20));
        }
    }
    &__bottom-nav {
        display: none;
    }
    @media  (max-width: 1440px),
            (max-height: 1024px) {
        &__collapse-inner {
            padding: rem(30) 0 rem(40);
            min-height: rem(460);
        }
        &__col-title {
            font-size: rem(24);
            margin-bottom: rem(26);
        }
        &__list-item {
            font-size: rem(14);
            line-height: rem(30);
            padding: rem(0);
        }
        &__list-item-chevron {
            top: rem(4);
        }
        &__close-button {
            bottom: rem(30);
            right: rem(30);
        }
    }
    @include media-breakpoint-down(xl) {
        &__collapse-inner {
            padding: rem(20) 0 rem(40);
            min-height: 0;
        }
        &__inner-col {
            width: 50%;
            max-width: 50%;
            flex-basis: 50%;
            margin-bottom: rem(24);
        }
        &__col-title {
            font-size: rem(22);
            line-height: rem(28);
            margin-bottom: rem(16);
        }
    }
    @include media-breakpoint-down(lg) {
        top: rem(46);
        &__inner {
            padding-left: rem(30);
            padding-right: rem(30);
        }
        &__bottom-nav {
            margin-top: rem(20);
            display: flex;
            flex-flow: column nowrap;
            gap: rem(20);
        }
        &__bottom-nav-link {
            display: flex;
            align-items: center;
            text-decoration: none;
            &:focus-visible {
                @include focus(rem(10), rem(0), relative, rem(10));
            }
        }
        &__bottom-nav-link-icon-container {
            display: flex;
            justify-content: center;
            width: rem(22);
            margin-right: rem(10);
        }
        &__bottom-nav-link-icon {
            height: rem(22);
            width: auto;
            &--my-apeldoorn {
                height: rem(17);
            }
            &--search {
                height: rem(18);
            }
        }
        &__bottom-nav-link-text {
            font-family: $interstate;
            font-size: rem(16);
            line-height: rem(19);
            color: $pr_green;
        }
    }
    @include media-breakpoint-down(md) {
        //height: 100vh;

        &__collapse-inner {
            padding: rem(20) 0 rem(100);
        }
        &__inner-col {
            width: 100%;
            max-width: 100%;
            flex-basis: 100%;
            margin-bottom: 0;
            padding-left: rem(20) !important;
            padding-right: rem(20) !important;
            &--open {
                #{$self}__list {
                    max-height: rem(1000);
                    transition: max-height 1s ease;
                    visibility: visible;
                    display: flex;
                }
                #{$self}__col-title {
                    color: $pr_green;
                    font-weight: 700;
                    &:after {
                        background: $pr_green url("/public/svg/chevron-right-white.svg") center center / rem(7) no-repeat;
                        transform: translateY(-50%) translateX(rem(5)) rotate(90deg);
                    }
                }
            }
        }
        &__col-title {
            position: relative;
            font-size: rem(18);
            line-height: rem(24);
            padding: rem(8) 0;
            margin-bottom: 0;
            border-bottom: rem(1) solid rgba(#0F1D37, .3);
            pointer-events: auto;
            &:focus-visible {
                @include focus(rem(10), rem(10), relative, rem(-6));
                &:before {
                    top: rem(3);
                    display: block !important;
                }
            }
            &:after {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%) translateX(rem(5));
                content: "";
                width: rem(22);
                height: rem(22);
                border-radius: 50%;
                transition: transform .2s ease;
                background: transparent url("/public/svg/chevron-right.svg") center center / rem(7) no-repeat;
            }
        }
        &__list {
            height: 100%;
            max-height: 0;
            display: none;
            transition: max-height .4s ease;
            visibility: hidden;
        }
        &__li {
            &--sub-li {
                #{$self}__list-item {
                    padding: rem(10) 0 rem(10) rem(20);
                    color: $txt_dark_grey !important;
                    font-weight: 500 !important;
                }
            }
            &--has-sub-list {
                border-bottom: 0;
                #{$self}__list-item {
                    color: $txt_dark_grey !important;
                    font-weight: 500 !important;
                }
                #{$self}__list-item-chevron {
                    right: rem(6);
                }
                //#{$self}__list-item-chevron {
                //    width: rem(40);
                //    height: rem(40);
                //    top: 0;
                //    justify-content: flex-end;
                //    padding-right: rem(7);
                //    z-index: 10;
                //    background: transparent !important;
                //    &:before {
                //        position: absolute;
                //        top: rem(9);
                //        right: 0;
                //        width: rem(22);
                //        height: rem(22);
                //        border-radius: 50%;
                //        content: "";
                //        transition: background-color .2s ease;
                //    }
                //    svg {
                //        position: relative;
                //        transition: transform .2s ease;
                //        path {
                //            fill: $txt_dark_grey !important;
                //        }
                //    }
                //}
            }
            &--open {
                border-bottom: rem(1) solid rgba(#0F1D37, .3);
                > #{$self}__list-item {
                    color: $pr_green !important;
                    font-weight: 700 !important;
                }
                //#{$self}__list-item-chevron {
                //    right: rem(7);
                //    &:before {
                //        position: absolute;
                //        top: rem(9);
                //        right: 0;
                //        width: rem(22);
                //        height: rem(22);
                //        border-radius: 50%;
                //        background-color: $pr_green;
                //        content: "";
                //    }
                //    svg {
                //        transform: rotate(90deg);
                //        path {
                //            fill: white !important;
                //        }
                //    }
                //}
                #{$self}__sub-list {
                    max-height: rem(1000);
                    transition: max-height 1s ease;
                }
            }
        }
        &__sub-list {
            display: flex;
            flex-flow: column nowrap;
            width: 100%;
            padding-left: 0;
            height: 100%;
            max-height: 0;
            transition: max-height .4s ease;
            background-color: #f6f7f7;
            border-top: rem(1) solid rgba(#0F1D37, .3);
            overflow: hidden;
        }
        &__list-item {
            font-size: rem(15);
            line-height: rem(20);
            padding: rem(10) 0 rem(10) rem(10);
            &:last-child {
                border-bottom-color: rgba(#0F1D37, .3);
            }
            &:focus-visible {
                @include focus(rem(10), rem(10), relative, rem(-6));
                &:before {
                    top: rem(3);
                }
            }
        }
        &__list-item-chevron {
            top: rem(10);
            transform: translateX(rem(4)) translateY(0);
            svg {
                width: rem(8);
            }
        }
        &__close-button {
            display: none;
        }
    }
}