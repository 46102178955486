.tiles {
    padding-top: rem(40);
    padding-bottom: rem(20);
    @include media-breakpoint-down(md) {
        padding-top: 0;
    }
}

.tile {
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
    border-radius: rem(45) 0 rem(45) 0;
    padding-bottom: rem(16);
    height: 100%;
    &__top {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        padding: rem(20) rem(40);
        min-height: rem(104);
        background: rgb(25,130,81);
        background: linear-gradient(30deg, rgba(25,130,81,1) 0%, rgba(96,181,211,1) 100%);
    }
    &__title {
        font-family: $playfair_display;
        font-size: rem(28);
        line-height: rem(34);
        font-weight: 900;
        color: white;
        margin-bottom: 0;
    }
    &__bottom {
        display: flex;
        flex-flow: column nowrap;
        padding: rem(28) rem(40) rem(40) rem(40);
        background-color: #fff;
        flex-grow: 1;
        border-radius: 0 0 rem(45) 0;
    }
    p {
        font-size: rem(16);
        line-height: rem(23);
        margin-bottom: rem(26);
        font-weight: 300;
    }
    a {
        font-size: rem(16);
        line-height: rem(23);
        font-weight: 700;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    @include media-breakpoint-down(md) {
        border-radius: rem(25) 0 rem(25) 0;
        height: auto;
        &__top {
            padding: rem(19) rem(25);
            min-height: rem(60);
        }
        &__title {
            font-size: rem(17);
            line-height: rem(27);
        }
        &__bottom {
            padding: rem(20) rem(25);
            flex-grow: 0;
            border-radius: 0 0 rem(25) 0;
        }
        p {
            margin-bottom: rem(19);
            font-size: rem(14);
            line-height: rem(20);
        }
        a {
            font-size: rem(14);
            line-height: rem(20);
            text-decoration: underline;
        }
    }
}


//.tile {
//    width: rem(370);
//    display: flex;
//    flex-flow: column nowrap;
//    &__image-container {
//        display: flex;
//        height: rem(370);
//    }
//    &__image {
//        width: 100%;
//        height: 100%;
//        object-fit: cover;
//    }
//    &__content {
//        display: flex;
//        flex-flow: column nowrap;
//        align-items: center;
//        padding: rem(20);
//    }
//    &__cat-date {
//        text-align: center;
//        margin-bottom: rem(10);
//        div {
//            font-size: rem(16);
//        }
//    }
//    &__title {
//        font-size: rem(40);
//        text-align: center;
//        &--red {
//            color: red;
//        }
//        &--large {
//            font-size: rem(70);
//            background-color: #fff;
//        }
//    }
//}