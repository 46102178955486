.footer {
    $self: &;
    position: relative;
    display: flex;
    padding: rem(40) 0 rem(60);
    margin-top: rem(100);
    overflow: hidden;
    border-radius: rem(100) 0 0 0;
    color: white;
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        background: rgb(14,122,61);
        background: linear-gradient(185deg, rgba(14,122,61,1) 0%, rgba(26,78,68,1) 76%, rgba(30,65,70,1) 100%);
    }
    > .container {
        position: relative;
    }
    a {
        color: white;
        &:before {
            outline-color: white !important;
        }
    }
    &__top-row {
        padding-bottom: rem(32);
    }
    &__socials {
        display: inline-flex;
        align-items: center;
    }
    &__socials-text {
        font-size: rem(17);
        line-height: rem(24);
        color: white;
    }
    &__socials-list {
        display: flex;
        flex-flow: row nowrap;
        padding-left: 0;
        margin-bottom: 0;
        margin-left: rem(11);
        list-style-type: none;
    }
    &__socials-list-item {
        .footer__socials-link {
            &:before {
                outline-color: $se_green_lighter !important;
            }
        }
    }
    &__socials-link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(40);
        height: rem(40);
        margin: 0 rem(8);
        border-radius: 50%;
        &:focus-visible {
            @include focus(50%, rem(10), relative, rem(10));
        }
        &:hover {
            opacity: .8;
            &:before {
                opacity: .8;
            }
        }
        &--instagram {
            background: url("/public/svg/footer-ig.svg") center center / 100% no-repeat;
        }
        &--twitter {
            background: url("/public/svg/footer-tw.svg") center center / 100% no-repeat;
        }
        &--facebook {
            background: url("/public/svg/footer-fb.svg") center center / 100% no-repeat;
        }
    }
    &__mid-row {
        position: relative;
        padding-top: rem(48);
        padding-bottom: rem(48);
        padding-left: rem(20);
        padding-right: rem(20);
        &:before,
        &:after {
            position: absolute;
            width: calc(100% - rem(40));
            height: rem(1);
            left: rem(20);
            background-color: rgba(white, .5);
            content: "";
        }
        &:before {
            top: 0;
        }
        &:after {
            bottom: 0;
        }
    }
    &__col {
        &--no-padding-left {
            padding-left: 0;
        }
    }
    &__col-title {
        font-family: $playfair_display;
        font-size: rem(17);
        line-height: rem(24);
        font-weight: 600;
        color: white;
        margin-bottom: rem(18);
    }
    &__nav-list {
        display: inline-flex;
        flex-flow: column nowrap;
    }
    &__nav-list-item {
        display: inline-flex;
        font-size: rem(14);
        line-height: rem(16);
        margin-bottom: rem(10);
        color: white;
        text-decoration: none;
        &:focus-visible {
            @include focus(rem(10), rem(20));
        }
        &:hover {
            color: white;
            text-decoration: underline;
        }
    }
    &__ul {
        display: flex;
        flex-flow: column nowrap;
        padding-left: 0;
        margin-bottom: 0;
    }
    &__text {
        display: flex;
        font-size: rem(14);
        line-height: rem(16);
        margin-bottom: rem(10);
        font-weight: 700;
        color: white;
        &:focus-visible {
            @include focus(rem(10), rem(20));
        }
        dt, dd {
            line-height: rem(16);
            font-weight: 500;
            margin-bottom: 0;
        }
        &--phone-link,
        &--mail-link {
            padding-left: rem(26);
            text-decoration: none;
            &:hover {
                text-decoration: underline;
                color: white;
            }
            &:after {
                position: absolute;
                top: 0;
                left: 0;
                content: "";
                opacity: .5;
            }
        }
        &--phone-link {
            position: relative;
            &:after {
                top: rem(1);
                width: rem(16);
                height: rem(16);
                background: url("/public/svg/phone.svg") top left / rem(16) no-repeat;
            }
        }
        &--mail-link {
            position: relative;
            &:after {
                top: rem(2);
                width: rem(16);
                height: rem(16);
                background: url("/public/svg/at.svg") top left / rem(16) no-repeat;
            }
        }
        &--split {
            display: flex;
            justify-content: space-between;
            max-width: rem(170);
        }
        &--regular {
            font-weight: 500;
        }
        &--link {
            position: relative;
            display: inline-flex;
            text-decoration: none;
            &:focus-visible {
                @include focus(rem(10), rem(30));
            }
            &:after {
                position: absolute;
                top: rem(3);
                right: rem(-15);
                width: rem(8);
                height: rem(15);
                content: "";
                background: url("/public/svg/chevron-right-white.svg") top left / rem(7) no-repeat;
            }
            &:hover {
                text-decoration: underline;
                color: white;
            }
        }
    }
    &__bottom-row {
        padding-top: rem(34);
    }
    &__bottom-inner {
        display: flex;
    }
    &__copyright {
        font-size: rem(11);
        line-height: rem(14);
        color: white;
        font-weight: 700;
    }
    &__bottom-nav {
        display: flex;
        flex-flow: row wrap;
        padding-left: rem(20);
    }
    &__bottom-nav-item {
        font-size: rem(11);
        line-height: rem(14);
        color: white;
        margin: 0 rem(16);
        text-decoration: none;
        margin-bottom: rem(10);
        &:focus-visible {
            @include focus(rem(10), rem(20), relative, rem(6));
        }
        &:hover {
            text-decoration: underline;
            color: white;
        }
    }
    &__mobile-only {
        display: none;
    }
    &__right-col {
        padding-top: .3rem;
    }
    &__contact-col {
        #{$self}__col {
            padding-top: rem(4);
        }
    }
    @include media-breakpoint-down(xxl) {
        &__top-row {
            padding-bottom: rem(25);
        }
        &__mid-row {
            padding-top: rem(30);
            padding-bottom: rem(30);
            .col-lg-6 {
                width: 100%;
                &:nth-of-type(2) {
                    margin-top: rem(20);
                }
            }
        }
        &__col-title:not(.footer__col-title--large) {
            margin-bottom: rem(10);
        }
    }
    @include media-breakpoint-down(lg) {
        margin-top: rem(80);
        &__top-row {
            padding-bottom: rem(3);
        }
        &__mid-row {
            &:before {
                display: none;
            }
            .col-lg-6 {
                &:nth-of-type(1) {
                    border-bottom: rem(1) solid rgba(white, .5);
                }
            }
            .col-6 {
                &:nth-of-type(2) {
                    padding-right: rem(20) !important;
                }
                &:nth-of-type(3) {
                    padding-left: rem(20) !important;
                }
            }
        }
        &__col {
            padding-left: 0 !important;
            padding-right: 0 !important;
            margin-bottom: rem(20);
            &--has-nav {
                &:nth-of-type(1) {
                    padding-right: rem(20) !important;
                }
                &:nth-of-type(2) {
                    padding-left: rem(20) !important;
                }
            }
        }
        &__socials {
            flex-flow: column nowrap;
        }
        &__socials-text {
            font-size: rem(18);
            margin-bottom: rem(24);
        }
        &__socials-list {
            margin-right: 0;
        }
        &__col-title:not(.footer__col-title--large) {
            font-size: rem(18);
            min-width: rem(125);
            line-height: rem(20);
            transform: translateY(rem(-3));
        }
        &__bottom-inner {
            flex-flow: column nowrap;
        }
        &__copyright {
            margin-bottom: rem(10);
            text-align: center;
        }
    }
    @include media-breakpoint-down(md) {
        border-radius: rem(50) 0 0 0;
        padding: rem(40) 0;
        margin-top: rem(60);
        &__mid-row {
            padding-top: rem(20);
            padding-bottom: rem(70);
            .col-6 {
                &:nth-of-type(1) {
                    border-right: rem(1) solid rgba(white, .5);
                    padding-right: rem(15) !important;
                }
                &:nth-of-type(2) {
                    padding-left: rem(15) !important;
                }
            }
        }
        &__socials-text {
            font-size: rem(15);
        }
        &__socials-link {
            width: rem(40);
            height: rem(40);
        }
        &__nav-list-item {
            font-size: rem(14);
            line-height: rem(18);
            margin-bottom: rem(8);
        }
        &__row-centered {
            justify-content: center;
        }
        &__col {
            margin-bottom: rem(20);
            &--has-nav {
                display: flex;
                flex-flow: row nowrap;
                &:nth-of-type(1) {
                    padding-right: 0 !important;
                }
                &:nth-of-type(2) {
                    padding-left: 0 !important;
                }
            }
        }
        &__col-title {
            &--large {
                text-align: center;
                font-size: rem(28);
            }
        }
        &__main-info-col {
            display: flex;
            justify-content: center;
            padding-bottom: rem(20);
            border-bottom: rem(1) solid rgba(white, .5);
        }
        &__main-info {
            display: inline-flex;
            flex-flow: column nowrap;
        }
        &__bottom-nav {
            justify-content: center;
            padding-left: 0;
        }
        &__bottom-nav-item {
            font-size: rem(12);
            margin: 0 rem(10) rem(10) rem(10);
        }
        &__text {
            font-size: rem(14);
            line-height: rem(18);
            margin-bottom: rem(5);
            &--title {
                font-weight: 700;
                font-size: rem(16);
                margin-bottom: rem(11);
            }
            &--phone-link {
                &:after {
                    top: rem(1);
                    opacity: .7;
                }
            }
            &--mail-link {
                &:after {
                    top: rem(3);
                    opacity: .7;
                }
            }
            &--link {
                text-decoration: underline;
                &:after {
                    top: rem(9);
                }
            }
        }
        &__appointment-link {
            position: absolute;
            bottom: rem(26);
            left: 50%;
            transform: translateX(-50%);
            font-size: rem(18);
            line-height: rem(26);
            &:before {
                top: rem(8);
            }
        }
        &__quick-col {
            display: none;
        }
        &__bottom-inner {
            &--working-at {
                padding: rem(20) 0;
                border-bottom: rem(1) solid rgba(white, .5);
                #{$self}__bottom-nav-item {
                    font-size: rem(14);
                    line-height: rem(18);
                    text-decoration: underline;
                    margin: 0 0 rem(10) 0;
                    padding: 0 rem(10);
                    border-right: rem(1) solid white;
                    &:last-child {
                        border-right: 0;
                    }
                }
            }
        }
        &__contact-col {
            padding-top: rem(20);
            margin-top: 0 !important;
            border-top: rem(1) solid rgba(white, .5);
        }
        &__mobile-only {
            display: flex;
        }
        &__working-at {
            align-self: center;
            font-size: rem(16);
            line-height: rem(36);
            font-weight: 700;
            margin-bottom: rem(11);
        }
    }
}