.form,
form {
    &__field,
    fieldset {
        position: relative;
        padding-top: rem(50);
        legend {
            font-size: rem(21);
            line-height: rem(26);
            font-weight: 700;
            margin-bottom: rem(10);
        }
        > label {
            position: absolute;
            bottom: rem(16);
            left: rem(14);
            font-size: rem(19);
            margin-bottom: 0;
            transition: all .3s ease;
        }
        &--textarea {
            .form__info-button {
                bottom: auto;
                top: rem(52);
            }
        }

    }
    &__field-error {
        position: absolute;
        bottom: rem(-24);
        left: 0;
        font-size: rem(17);
        line-height: rem(24);
        color: $pr_orange;
        font-weight: 700;
    }
    &__info-button {
        position: absolute;
        bottom: rem(6);
        right: rem(6);
        width: rem(50);
        height: rem(50);
        appearance: none;
        border: 0;
        padding: 0;
        background: url('/public/svg/icons/info-orange.svg') center center / rem(8) no-repeat;
    }
    &-control {
        &:focus-visible {
            outline: rem(3) solid $se_focus;
            box-shadow: 0 0 0 0;
            border-color: $se_focus;
        }
        &:focus {
            outline: 0;
        }
        @include media-breakpoint-down(sm) {
            &:focus-visible {
                outline-width: rem(3);
            }
        }
    }
    &-check {
        display: flex;
        margin-bottom: 0;
        min-height: 0;
        padding-top: rem(10);
        padding-bottom: rem(10);
        padding-left: rem(32);
        &-inline {
            display: inline-flex;
        }
    }
    &-check-input {
        flex-shrink: 0;
        width: rem(34);
        height: rem(34);
        border-radius: rem(5) !important;
        border: rem(2) solid $pr_orange;
        background-color: white;
        margin-top: 0;
        outline: rem(0) solid white !important;
        box-shadow: 0px 0px 0px 7px #FFFFFF !important;
        appearance: none;
        //box-shadow: 0 0 0 0 !important;
        &:focus,
        &:focus-visible {
            box-shadow: 0px 0px 0px 7px rgba($pr_orange, .25) !important;
            border-color: $pr_orange;
        }
        &:checked {
            background-color: $pr_orange;
            border-color: $pr_orange;
            background-image: none !important;
        }
        &[type="radio"] {
            &:focus,
            &:focus-visible {
                box-shadow: 0px 0px 0px 7px rgba($pr_orange, .25) !important;
            }
        }
    }
    &-check-label {
        padding-left: rem(26);
        padding-top: rem(5);
    }
    input[type=file] {
        @include media-breakpoint-down(sm) {
            font-size: rem(14);
        }
    }
    input[type=file]::file-selector-button {
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        height: rem(42);
        padding: 0 rem(24) 0 rem(24);
        font-family: $interstate;
        font-weight: 700;
        font-size: rem(15);
        line-height: 1;
        border: 0;
        border-radius: rem(21);
        color: white;
        text-decoration: none !important;
        background-color: $pr_orange;
        transition: background-color .2s ease, color .2s ease, border-color .2s ease;
        white-space: nowrap;
        cursor: pointer;
        margin-right: rem(10);
        &:hover,
        &:focus-visible {
            background-color: $pr_green;
            color: white;
            &:before {
                display: none;
            }
        }
        @include media-breakpoint-down(sm) {
            height: rem(30);
            padding: 0 rem(15) 0 rem(15);
            font-size: rem(11);
            border-radius: rem(15);
            &:after {
                top: rem(5);
                right: rem(5);
                width: rem(20);
                height: rem(20);
            }
        }
    }
    input[type=file]::-ms-browse {
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        height: rem(42);
        padding: 0 rem(24) 0 rem(24);
        font-family: $interstate;
        font-weight: 700;
        font-size: rem(15);
        line-height: 1;
        border: 0;
        border-radius: rem(21);
        color: white;
        text-decoration: none !important;
        background-color: $pr_orange;
        transition: background-color .2s ease, color .2s ease, border-color .2s ease;
        white-space: nowrap;
        cursor: pointer;
        margin-right: rem(10);
        &:hover,
        &:focus-visible {
            background-color: $pr_green;
            color: white;
            &:before {
                display: none;
            }
        }
        @include media-breakpoint-down(sm) {
            height: rem(30);
            padding: 0 rem(15) 0 rem(15);
            font-size: rem(11);
            border-radius: rem(15);
            &:after {
                top: rem(5);
                right: rem(5);
                width: rem(20);
                height: rem(20);
            }
        }
    }
    &__field {
        padding-top: rem(46);
        > input[type="text"],
        > input[type="textarea"],
        > input[type="email"],
        > input[type="password"],
        > input[type="search"],
        > input[type="url"],
        > input[type="number"],
        > input[type="tel"],
        select,
        textarea {
            height: rem(62);
            font-size: rem(23);
            line-height: rem(28);
            color: black;
            font-weight: 500;
            padding: 0 rem(60) 0 rem(27);
            border-radius: rem(10);
            border: rem(2.5) solid #868686;
            width: 100%;
            background-color: white;
            &:focus-visible {
                outline: rem(1) solid #868686;
            }
            &:focus {
                outline: 0;
            }
            &:disabled,
            &[readonly] {
                background-color: transparent;
            }
            &[aria-invalid="true"] {
                border: rem(6) solid $pr_orange;
            }
        }
        select {
            background-color: white;
            font-size: rem(17);
            width: 100%;
            color: #767676;
            background: white url("/public/svg/chevron-down-black.svg") calc(100% - rem(17)) rem(25) / rem(16) no-repeat;
            &[multiple] {
                height: auto;
                padding: rem(10) 0;
                option {
                    padding: 0 rem(10);
                    min-height: rem(32);
                    display: flex;
                    align-items: center;
                    font-size: rem(17);
                    line-height: rem(20);
                    font-weight: 500;
                }
            }
            &.form__select-large {
                font-size: rem(23);
                color: black;
            }
        }
        textarea {
            resize: none;
            height: rem(220);
            padding: rem(19) rem(60) rem(19) rem(27);
            + label {
                bottom: auto;
                top: rem(65);
            }
        }
        > input[type="range"] {
            + label {
                transform: translateX(rem(-27)) translateY(rem(-26));
                color: black;
            }
            &::-webkit-slider-thumb {
                -webkit-appearance: none; /* Override default look */
                appearance: none;
                background: $pr_orange; /* Green background */
            }
            &::-moz-range-thumb {
                background: $pr_orange; /* Green background */
            }
        }
        > label {
            font-size: rem(17);
            left: rem(27);
            bottom: rem(19);
            white-space: nowrap;
            color: #767676;
        }
        &--filled {
            > label {
                transform: translateX(rem(-27)) translateY(rem(-46));
                font-size: rem(17);
            }
        }
        &--range {
            margin-top: rem(20);
        }
        &--submit {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            a {
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
            a, button {
                margin-bottom: rem(16);
            }
            a + .button {
                margin-left: rem(27);
            }
        }
    }
    &--in-editor {
        position: relative;
        margin-bottom: rem(26);

    }
    &--decision-tree {
        fieldset {
            padding: rem(20) 0;
        }
    }
    @include media-breakpoint-down(xl) {
        &__info-button {
            width: rem(40);
            height: rem(40);
        }
        &__field-error {
            bottom: rem(-19);
            left: 0;
            font-size: rem(14);
            line-height: rem(20);
        }
        &__field {
            padding-top: rem(38);
            > input[type="text"],
            > input[type="textarea"],
            > input[type="email"],
            > input[type="password"],
            > input[type="search"],
            > input[type="url"],
            > input[type="number"],
            > input[type="tel"],
            select,
            textarea {
                font-size: rem(18);
                line-height: rem(24);
                height: rem(52);
                padding: 0 rem(60) 0 rem(17);
                &[aria-invalid="true"] {
                    border: rem(4) solid $pr_orange;
                }
            }
            select {
                font-size: rem(14);
                background-position: calc(100% - rem(17)) rem(21);
            }
            textarea {
                height: rem(220);
                padding: rem(10) rem(30) rem(10) rem(17);
                + label {
                    bottom: auto;
                    top: rem(53);
                }
            }
            > input[type="range"] {
                + label {
                    transform: translateX(rem(-17)) translateY(rem(-26));
                }
            }
            > label {
                bottom: rem(15);
                left: rem(17);
                font-size: rem(14);
            }
            &--textarea {
                .form__info-button {
                    bottom: auto;
                    top: rem(44);
                }
            }
            &--filled {
                > label {
                    transform: translateX(rem(-17)) translateY(rem(-38));
                    font-size: rem(14);
                }
            }
        }
    }
    @include media-breakpoint-down(lg) {
        fieldset {
            padding-top: rem(30);
            legend {
                font-size: rem(17);
                line-height: rem(22);
            }
        }
        &-check {
            padding-left: rem(26);
        }
        &-check-input {
            width: rem(18);
            height: rem(18);
            border-radius: rem(2) !important;
            border: rem(2) solid $pr_orange;
            box-shadow: 0px 0px 0px 4px #FFFFFF !important;
            appearance: none;
            //box-shadow: 0 0 0 0 !important;
            &:focus {
                border-color: $pr_orange;
            }
            &:checked {
                background-color: $pr_orange;
                border-color: $pr_orange;
                background-image: none !important;
            }
        }
        &-check-label {
            font-size: rem(14);
            line-height: rem(20);
            font-weight: 400;
            padding-top: 0;
            padding-left: rem(14);
        }
        &__info-button {
            bottom: 0;
            right: 0;
            background-size: rem(6);
        }
        &__field-error {
            bottom: rem(-17);
            left: 0;
            font-size: rem(10);
        }
        &__field {
            padding-top: rem(27);
            > input[type="text"],
            > input[type="textarea"],
            > input[type="email"],
            > input[type="password"],
            > input[type="search"],
            > input[type="url"],
            > input[type="number"],
            > input[type="tel"],
            select,
            textarea {
                height: rem(40);
                padding: 0 rem(30) 0 rem(15);
                border-width: rem(1);
                border-radius: rem(5);
                font-size: rem(14);
                line-height: rem(20);
            }
            select {
                font-size: rem(10);
                background-size: rem(10);
                background-position: calc(100% - rem(12)) rem(16);
                &[multiple] {
                    height: auto;
                    padding: rem(10) 0 rem(10) rem(15);
                    option {
                        padding: 0 rem(10);
                        min-height: rem(20);
                        display: flex;
                        align-items: center;
                        font-size: rem(14);
                        line-height: rem(18);
                        font-weight: 500;
                    }
                }
                &.form__select-large {
                    font-size: rem(14);
                }
            }
            textarea {
                height: rem(180);
                padding: rem(10) rem(30) rem(10) rem(17);
                + label {
                    bottom: auto;
                    top: rem(36);
                }
            }
            > input[type="range"] {
                + label {
                    transform: translateX(rem(-14)) translateY(rem(-26));
                }
            }
            > label {
                bottom: rem(13);
                left: rem(15);
                font-size: rem(10);
            }
            &--filled {
                > label {
                    transform: translateX(rem(-15)) translateY(rem(-28));
                    font-size: rem(10);
                }
            }
            &--textarea {
                .form__info-button {
                    bottom: auto;
                    top: rem(24);
                }
            }
            &--submit {
                a {
                    font-size: rem(11);
                }
                a, button {
                    margin-bottom: rem(16);
                }
                a + .button {
                    margin-left: rem(16);
                }
            }
        }
        &--decision-tree {
            fieldset {
                padding: rem(10) 0;
            }
        }
    }
}

hr + .form__field--submit {
    padding-top: rem(0);
}