.accordion {
    $self: &;
    &-item {
        padding-bottom: rem(56);
    }
    &-header {
        padding-top: 0 !important;
        margin-bottom: 0 !important;
        border-bottom: rem(1) solid #707070;
    }
    &-button {
        font-size: rem(32);
        line-height: 1.3;
        font-weight: 700;
        background: transparent;
        border: 0;
        padding: 0;
        display: flex;
        text-align: left;
        margin-bottom: rem(14);
        color: black;
        &:not(.collapsed) {
            #{$self}-toggle {
                &:after {
                    transform: rotate(180deg);
                }
            }
        }
    }
    &-toggle {
        position: relative;
        width: rem(23);
        height: rem(23);
        margin-top: rem(9);
        margin-right: rem(9);
        flex-shrink: 0;
        &:before,
        &:after {
            position: absolute;
            content: "";
            background-color: black;
            width: rem(23);
            height: rem(5);
            top: rem(9);
            left: 0;
            transition: transform .2s ease;
        }
        &:after {
            transform: rotate(90deg);
        }
    }
    &-body {
        padding-top: rem(20);
    }
    &--in-editor {
        margin-top: rem(50);
    }
    @include media-breakpoint-down(md) {
        margin-bottom: rem(20);
        &-button {
            font-size: rem(22);
        }
        &-item {
            padding-bottom: rem(15);
        }
        &-toggle {
            width: rem(15);
            height: rem(15);
            margin-top: rem(4);
            margin-right: rem(4);
            &:before,
            &:after {
                width: rem(15);
                height: rem(3);
            }
        }
    }
}