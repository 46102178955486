.search-results {
    &__inner {
        display: flex;
        flex-flow: column nowrap;
        padding-top: rem(82);
    }
    &__title {
        font-family: $playfair_display;
        font-size: rem(40);
        line-height: 1.3;
        font-weight: 900;
        margin-bottom: rem(22);
    }
    &__scroll-container {
        width: 100%;
        //overflow-x: scroll;
        //-webkit-overflow-scrolling: touch;

    }
    &__categories-container {
        position: relative;
        &:after,
        &:before {
            position: absolute;
            top: 0;
            right: 0;
            width: rem(230);
            height: 100%;
            background: rgb(235,236,236);
            background: linear-gradient(45deg, rgba(235,236,236,0) 0%, rgba(235,236,236,1) 100%);
            content: "";
            pointer-events: none;
            z-index: 1;
            display: flex;
        }
        &:before {
            right: auto;
            left: 0;
            background: rgb(235,236,236);
            background: linear-gradient(45deg, rgba(235,236,236,1) 0%, rgba(235,236,236,0) 100%);
            z-index: 2;
        }
        &--begin {
            &:after {
                display: flex;
            }
            &:before {
                display: none;
            }
        }
        &--end {
            &:after {
                display: none;
            }
            &:before {
                display: flex;
            }
        }
        //&:before {
        //    display: none;
        //    position: absolute;
        //    right: 0;
        //    top: 0;
        //    width: rem(11);
        //    height: rem(42);
        //    content: "";
        //    z-index: 2;
        //    background: url("/public/svg/chevron-left-black.svg") center center / rem(11) no-repeat;
        //    transform: rotate(180deg);
        //}
    }
    &__categories {
        padding-left: 0;
        flex-flow: row nowrap;
        width: 100%;
        list-style-type: none;
        li {
            width: auto !important;
        }
    }
    &__navigation {
        top: rem(3);
    }
    &__nav-button--next,
    &__nav-button--prev {
        background: transparent !important;
        &.swiper-button-disabled {
            display: none;
        }
    }
    &__nav-button--prev {
        left: 0 !important;
    }
    &__nav-button--next {
        right: 0 !important;
    }
    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: rem(24);
        padding-bottom: rem(20);
        border-bottom: rem(1) solid #707070;
    }
    &__total {
        font-size: rem(18);
        line-height: rem(21);
    }
    &__sort {
        position: relative;
        width: auto;
        height: rem(42);
        border-radius: rem(8);
        background: transparent url("/public/svg/chevron-down-black.svg") calc(100% - rem(11)) rem(16) / rem(13) no-repeat;
        border-color: #8D8D8D;
        padding-left: rem(18);
        padding-right: rem(36);
        font-size: rem(15);
        line-height: 1;
        font-weight: 500;
    }
    &__results {
        display: flex;
        flex-flow: column nowrap;
        padding-left: 0;
    }
    &__result {
        display: flex;
        justify-content: space-between;
        padding: rem(16) 0;
        border-bottom: rem(1) solid #707070;
    }
    &__result-left {
        display: flex;
        flex-flow: column nowrap;
        width: calc(100% - rem(200));
        padding-right: rem(20);
    }
    &__result-right {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
        width: rem(200);
        .button {
            justify-content: flex-start;
        }
    }
    &__result-title {
        font-size: rem(21);
        line-height: rem(26);
        margin-bottom: rem(6);
        font-weight: 700;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    &__result-content {
        font-size: rem(18);
        line-height: rem(31);
        font-weight: 300;
        margin-bottom: 0;
        span {
            font-weight: 700;
            padding: 0 rem(14);
            border-right: rem(1) solid black;
            &:first-of-type {
                padding-left: 0;
            }
            &:last-of-type {
                margin-right: rem(14);
            }
        }
        a {
            text-decoration: underline;
        }
    }
    &__result-excerpt {
        font-weight: 300 !important;
        border-right: 0 !important;
    }
    &__result-image-container {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: rem(15);
        overflow: hidden;
    }
    &__result-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @include media-breakpoint-down(md) {
        overflow: hidden;
        &__inner {
            padding-top: rem(24);
        }
        &__title {
            font-size: rem(24);
            line-height: rem(28);
        }
        &__categories-container {
            padding: rem(10) 0;
            border-top: rem(1) solid rgba(#707070, .25);
            border-bottom: rem(1) solid rgba(#707070, .25);
            .tag {
                margin-bottom: 0;
            }
            &:before,
            &:after {
                width: rem(80);
            }
        }
        &__categories {
            margin-bottom: 0;
        }
        &__navigation {
            top: rem(8);
        }
        &__nav-button--prev {
            left: rem(-4) !important;
        }
        &__nav-button--next {
            right: rem(-4) !important;
        }
        &__top {
            margin-top: rem(10);
            padding-bottom: rem(10);
            border-bottom: rem(1) solid rgba(#707070, .25);
        }
        &__total {
            font-size: rem(12);
            line-height: rem(20);
        }
        &__sort {
            height: rem(32);
            padding-right: rem(28);
            padding-left: rem(9);
            font-size: rem(12);
            border-radius: rem(5);
            background: transparent url("/public/svg/chevron-down-black.svg") calc(100% - rem(9)) rem(13) / rem(8) no-repeat;
        }
        &__result-left {
            width: calc(100% - rem(170));
        }
        &__result-right {
            width: rem(170);
        }
        &__result-title {
            font-size: rem(18);
            line-height: rem(22);
            text-decoration: underline;
        }
        &__result-content {
            font-size: rem(14);
            line-height: rem(20);
            span {
                padding: 0 rem(7);
                &:first-of-type {
                    padding-left: 0;
                }
                &:last-of-type {
                    margin-right: rem(7);
                }
            }
        }
        &__result-excerpt {
            display: flex;
            padding: 0 !important
        }
        &__result-cat {
            border-right: 0 !important;
        }
    }
    @include media-breakpoint-down(sm) {
        &__categories-container {
            width: calc(100% + rem(40));
            transform: translateX(rem(-20));
            padding: rem(10) 0;
        }
        &__categories {
            li {
                &:first-of-type {
                    padding-left: rem(20);
                }
            }
        }
        &__top {
            width: calc(100% + rem(40));
            transform: translateX(rem(-20));
            padding: 0 rem(20) rem(10);
        }
        &__result-title {
            font-size: rem(16);
            line-height: rem(20);
        }
        &__result-left {
            width: calc(100% - rem(110));
            padding-right: rem(10);
        }
        &__result-right {
            width: rem(110);
        }
        &__result-image-container {
            height: rem(110);
        }
    }
}