.hero-slider {
    $self: &;
    position: relative;
    &__slide {
        position: relative;
        width: 100%;
        height: calc(100vh - rem(80));
        display: flex;
        justify-content: flex-end;
        align-items: center ;
    }
    &__bg-image-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        &:after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(black, .28);
            content: "";
        }
    }
    &__bg-image {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &__slide-content {
        position: relative;
    }
    &__slide-title {
        font-family: $playfair_display;
        font-weight: 900;
        color: white;
        font-size: rem(54);
        line-height: rem(60);
        margin-bottom: 0;
        transform: translateY(rem(-8));
        a {
            position: relative;
            color: white;
            text-decoration: none;
            &:before {
                outline-color: #fff;
            }
            &:hover {
                text-decoration: underline;
                text-decoration-thickness: rem(2);
            }
        }
    }
    &--topstory {
        #{$self}__slide-title {
            text-align: center;
        }
    }
    @include media-breakpoint-down(xxl) {
        &__slide-title {
            font-size: rem(54);
            line-height: rem(68);
        }
    }
    @include media-breakpoint-down(xl) {
        &__slide-title {
            font-size: rem(44);
            line-height: rem(56);
        }
    }
    @include media-breakpoint-down(lg) {
        &__slide-content {
            padding: 0 rem(40);
            opacity: 0;
            visibility: hidden;
            transition: all .3s ease;
            opacity: 1;
            visibility: visible;
            &--show {
                opacity: 1;
                visibility: visible;
                transition: all .3s .3s ease;
            }
        }
        &__slide {
            height: calc(100vh - rem(46));
            min-height: rem(440);
        }
        &__slide-title {
            font-size: rem(32);
            line-height: rem(38);
            text-align: center;
            a {
                text-decoration: underline;
                text-decoration-thickness: rem(1);
            }
        }
        &__pagination {
            bottom: auto;
            top: calc(50% + rem(62));
        }
        &--topstory {
            #{$self}__slide-content {
                opacity: 1;
                visibility: visible;
            }
            #{$self}__slide {
                min-height: rem(300);
            }
            .hero-image__description-container {
                bottom: rem(52);
            }
        }
    }
    @include media-breakpoint-down(sm) {
        &__slide-title {
            max-width: rem(350);
            margin: 0 auto;
        }
    }
}