.pagination {
    display: inline-flex;
    padding-left: 0;
    list-style-type: none;
    &__nav {
        display: flex;
        justify-content: center;
    }
    &__outer {
        margin-top: rem(80)
    }
    @include media-breakpoint-down(md) {
        &__outer {
            margin-top: rem(30);
        }
    }
}

.page-item {
    padding: 0 rem(6);
    font-size: rem(17);
    line-height: rem(30);
    font-weight: 400;
    &.active {
        font-weight: 700;
        text-decoration: underline;
    }
    &--nav {
        position: relative;
        font-weight: 500;
        &:before {
            position: absolute;
            top: rem(9);
            width: rem(8);
            height: rem(13);
            content: "";
            background: url("/public/svg/chevron-left-black.svg") center center / rem(7) no-repeat;
        }
        &--prev {
            padding-left: rem(18);
            padding-right: rem(16);
            &:before {
                left: 0;
            }
        }
        &--next {
            padding-right: rem(18);
            padding-left: rem(16);
            &:before {
                right: 0;
                transform: rotate(180deg);
            }
        }
    }
    @include media-breakpoint-down(md) {
        font-size:  rem(14);
        line-height: rem(20);
        &--nav {
            &:before {
                top: rem(4);
                background-size: rem(5);
            }
        }
    }
}
.page-link {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}