.split-view {
    $self: &;
    &__inner {
        padding-top: rem(23);
        overflow: hidden;
        > .container {
            max-width: rem(1220);
        }
    }
    &__scroll-block {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: rem(38) 0;
        border-top: rem(1) solid #707070;
        border-bottom: rem(1) solid #707070;
        transition: opacity .15s ease, visibility .15s ease;
        &--sticky {
            left: calc((100% - rem(1180)) / 2);
            position: fixed;
            max-width: rem(300);
        }
        &--hide {
            position: absolute;
            top: auto !important;
            bottom: rem(220);
            left: 0;
            min-width: rem(300);
            //opacity: 0;
            //visibility: hidden;
        }
    }
    &__side {
        position: relative;
        width: 100%;
        max-width: rem(380);
        padding-right: 6.5%;
    }
    &__side-inner {
        position: relative;
        height: 100%;
    }
    &__side-mobile-select {
        display: none;
    }
    &__side-mobile-trigger {
        display: none;
    }
    &__list {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;
        &--sub {
            padding-left: rem(20);
            &:before {
                position: absolute;
                top: rem(8);
                left: 0;
                width: rem(3);
                height: calc(100% - rem(16));
                background: rgba($se_blue_dark, .15);
                content: "";
                border-radius: rem(2);
            }
            &--no-border {
                &:before {
                    display: none;
                }
            }
        }
    }
    &__list-item {
        font-size: rem(17);
        line-height: rem(31);
        font-weight: 400;
        a {
            font-size: rem(17);
            line-height: rem(31);
            font-weight: 400;
            text-decoration: none;
            &:hover {
                font-weight: 700;
            }
        }
        &--active {
            font-weight: 700;
             > a {
                 font-weight: 700;
             }
        }
    }
    &__content-inner {
        position: relative;
        padding: rem(25) 0 rem(230);
        min-height: rem(500);
        &:before {
            position: absolute;
            width: 100%;
            width: calc(100% + (100vw - rem(1180)) / 2);
            height: 100%;
            left: 0;
            top: 0;
            content: "";
            background-color: $se_grey;
            border-radius: rem(75) 0 rem(75) 0;
        }
        &--with-grey-bg {
            &:before {
                display: block !important;
                position: absolute;
                width: 100%;
                width: calc(100% + (100vw - rem(1180)) / 2);
                height: 100%;
                left: 0;
                top: 0;
                content: "";
                background-color: $se_grey;
                border-radius: rem(75) 0 rem(75) 0;
            }
        }
    }
    &__content-editor {
        position: relative;
        padding-left: rem(100);
    }
    &__content-top {
        margin-bottom: rem(75);
        max-width: rem(706);
    }
    &--narrow-side {
        #{$self}__side {
            max-width: rem(214);
            padding-top: rem(60);
        }
        #{$self}__inner {
            padding-top: rem(50);
        }
        //#{$self}__scroll-block {
        //    border-top: 0;
        //    border-bottom: 0;
        //}
        #{$self}__content-inner {
            padding-top: rem(70);
        }
    }
    &--no-background {
        #{$self}__content-inner {
            padding-top: 0;
            &:before {
                display: none;
            }
        }
        #{$self}__content-top {
            margin-bottom: rem(40);
        }
        #{$self}__content-editor {
            padding-left: 0;
        }
        //#{$self}__scroll-block {
        //    padding: 0 0 rem(38) 0;
        //}
        #{$self}__side {
            padding-top: rem(24);
            padding-right: calc(var(--bs-gutter-x) * 0.5);
            max-width: 25%;
            &--has-mobile-view {
                padding-top: 0;
            }
        }
    }
    @media (max-width: 1220px) {
        &__content-inner {
            &:before {
                width: calc(100% + rem(20));
            }
            &--with-grey-bg {
                &:before {
                    width: calc(100% + rem(20));
                }
            }
        }
        &__scroll-block {
            padding: rem(28) 0;
            &--sticky {
                left: rem(20);
            }
            &--hide {
                left: 0;
            }
        }
        &__side {
            padding-right: 0;
            max-width: rem(280);
        }
        &__list-item {
            font-size: rem(15);
            line-height: rem(26);
            a {
                font-size: rem(15);
                line-height: rem(26);
            }
        }
        &--narrow-side {
            #{$self}__scroll-block {
                &--sticky {
                    max-width: rem(245);
                }
                &--hide {
                    min-width: rem(245);
                }
            }
        }
    }
    @include media-breakpoint-down(lg) {
        &__side {
            display: none;
            &--has-mobile-view {
                display: flex;
                #{$self}__side-inner {
                    min-height: 0 !important;
                    width: 100%;
                }
                #{$self}__scroll-block {
                    position: relative;
                    top: auto !important;
                    left: auto !important;
                    bottom: auto !important;
                    padding: 0;
                    border-top: 0;
                    border-bottom: 0;
                }
                #{$self}__nav {
                    position: relative;
                    border: rem(1) solid #707070;
                    border-radius: rem(5);
                    padding: rem(30) rem(15) rem(0);
                    max-height: rem(32);
                    &:before {
                        position: absolute;
                        top: rem(6);
                        left: rem(15);
                        font-size: rem(12);
                        color: black;
                        width: calc(100% - rem(30));
                        height: rem(26);
                        content: attr(data-mobile-label);
                    }
                    > ul.split-view__list {
                        display: none;
                    }
                    &--open {
                        padding: rem(30) rem(15) rem(8);
                        max-height: 100%;
                        &:before {
                            opacity: .2;
                        }
                        #{$self}__side-mobile-trigger {
                            &:before {
                                transform: rotate(90deg);
                            }
                        }
                        > ul.split-view__list {
                            display: flex;
                        }
                    }
                }
                #{$self}__side-mobile-trigger {
                    display: flex;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: rem(30);
                    z-index: 2;
                    &:before {
                        position: absolute;
                        right: rem(15);
                        top: rem(11);
                        width: rem(6);
                        height: rem(10);
                        background: url("/public/svg/chevron-left-black.svg") center center / 100% no-repeat;
                        content: "";
                        transform: rotate(-90deg);
                    }
                }
                #{$self}__list-item {
                    font-size: rem(12);
                    a {
                        font-size: rem(12);
                    }
                }
            }
        }
        &__side-mobile-select {
            display: block;
        }
        &__inner {
            background-color: #EBECEC;
            padding-top: 0;
        }
        &__content-inner {
            padding-top: 0;
        }
        &__content-editor {
            padding-left: 0;
        }
        &__content-top {
            max-width: 100%;
            margin-bottom: rem(20);
        }
        &--narrow-side {
            #{$self}__side {
                max-width: 100%;
            }
            #{$self}__inner {
                padding-top: 0;
            }
            #{$self}__content-inner {
                padding-top: 0;
            }
        }
        &--no-background {
            #{$self}__content-top {
                margin-bottom: rem(20);
            }
            #{$self}__side {
                &--has-mobile-view {
                    padding-top: rem(24);
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        &__inner {
            border-radius: 0 0 rem(30) 0;
        }
        &__content-inner {
            padding-bottom: rem(20);
            &:before {
                display: none;
            }
            &--with-grey-bg {
                &:before {
                    display: none;
                }
            }
        }
    }
}

.split-view + .section--more-info {
    margin-top: rem(-180);
    padding-top: 0;
    @include media-breakpoint-down(md) {
        margin-top: rem(0);
        padding-top: rem(40);
    }
}