.facts {
    $self: &;
    display: flex;
    padding-top: rem(50);
    padding-bottom: rem(60);
    &__inner {
        display: flex;
        flex-flow: row wrap;
    }
    &__col {
        display: flex;
        flex-flow: column nowrap;
        padding: rem(0) rem(35) rem(30);
        justify-content: center;
        align-items: center;
        border-right: rem(1) solid #707070;
        flex-grow: 1;
        &:first-of-type {
            border-left: rem(1) solid #707070;
        }
    }
    &__value {
        font-family: $playfair_display;
        font-size: rem(167);
        line-height: 1;
        color: white;
        margin-bottom: rem(36);
        font-weight: 900;
    }
    &__label {
        font-family: $playfair_display;
        font-size: rem(29);
        line-height: 1;
        font-weight: 400;
        color: black;
    }
    &__list {
        ul {
            li {
                font-size: rem(19);
                line-height: rem(30);
                font-weight: 500;
                &:before {
                    font-size: rem(35);
                }
            }
        }
    }
    @include media-breakpoint-down(xl) {
        &__col {
            padding: rem(0) rem(25) rem(30);
        }
        &__value {
            font-size: rem(107);
        }
        &__label {
            font-size: rem(24);
        }
    }
    @include media-breakpoint-down(lg) {
        padding-top: rem(30);
        padding-bottom: rem(40);
        &__col {
            padding: rem(0) rem(15) rem(30);
        }
        &__value {
            font-size: rem(67);
            margin-bottom: rem(26);
        }
        &__label {
            font-size: rem(20);
        }
        &__list {
            ul {
                li {
                    font-size: rem(17);
                    line-height: rem(24);
                    font-weight: 500;
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        padding-bottom: rem(30);
        &__col {
            width: 50%;
            flex: 0 0 50%;
            margin-bottom: rem(28);
            padding: rem(0) rem(35) rem(10);
            &:nth-of-type(3) {
                border-left: rem(1) solid #707070;
            }
            #{$self}__value,
            #{$self}__label {
                transform: translateY(rem(-10));
            }
        }
        &__value {
            font-size: rem(87);
        }
        &__list {
            ul {
                li {
                    font-size: rem(14);
                    line-height: rem(24);
                    margin-bottom: rem(16);
                }
            }
        }
    }
}