.articles {
    $self: &;
    padding: rem(60) 0 0;
    max-width: 1220px;
    &__row {
        padding-bottom: rem(15);
        margin-left: rem(-10);
        margin-right: rem(-10);
        > div[class^="col-"] {
            padding-left: rem(10);
            padding-right: rem(10);
        }
        &--start {
            align-items: flex-start;
        }
        &--stretch {
            align-items: stretch;
        }
    }
    &__list-col {
        align-self: flex-start;
    }
    &__col {
        &--start {
            align-self: flex-start;
        }
    }
    &--file {
        padding: rem(100) 0 0 0;
        #{$self}__row {
            > div[class^="col-"] {
                margin-bottom: rem(20);
            }
        }
    }
    &--page-topical {
        .article--list {
            margin-bottom: rem(64);
        }
    }
    @include media-breakpoint-down(xxl) {
        padding: rem(70) 0 0;
    }
    @include media-breakpoint-down(xl) {
        padding: rem(40) 0 0;
    }
    @include media-breakpoint-down(lg) {
        &__col {
            &--mobile-end {
                align-self: flex-end;
            }
        }
        &__list-col {
            display: flex;
            justify-content: center;
            &--mobile-2 {
                order: 2 !important;
            }
            &--mobile-3 {
                order: 3 !important;
                align-self: flex-end;
            }
            &--mobile-4 {
                order: 4 !important;
            }
            &--mobile-5 {
                order: 5 !important;
            }
        }
        &--topical {
            #{$self}__row {
                > div[class^="col-"] {
                    &:nth-of-type(3) {
                        .article__image-container {
                            height: rem(154);
                        }
                        .article__text-container {
                            min-height: rem(154);
                        }
                    }
                    &:nth-of-type(4) {
                        .article__image-container {
                            height: rem(188);
                        }
                        .article__text-container {
                            min-height: rem(188);
                        }
                    }
                    &:nth-of-type(6) {
                        order: 7;
                    }
                    &:nth-of-type(7) {
                        order: 6;
                        align-self: flex-end;
                    }
                    &:nth-of-type(8) {
                        order: 8;
                    }
                    &:nth-of-type(9) {
                        order: 9;
                    }
                    &:nth-of-type(10) {
                        order: 10;
                        .article__image-container {
                            height: rem(200);
                        }
                        .article__text-container {
                            min-height: rem(200);
                        }
                    }
                    &:nth-of-type(11) {
                        order: 12;
                    }
                    &:nth-of-type(12) {
                        order: 11;
                        .article__image-container {
                            height: rem(200);
                        }
                        .article__text-container {
                            min-height: rem(200);
                        }
                    }
                }
            }
        }
        &--file {
            padding: rem(100) 0 0 0;
            #{$self}__row {
                > div[class^=col-] {
                    margin-bottom: rem(10);
                    &#{$self}__list-col {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        padding: rem(30) 0 0 0;
        padding-left: rem(20) !important;
        padding-right: rem(20) !important;
        &__row {
            --bs-gutter-x: 0.625rem;
            > div[class^="col-"] {
                &:first-of-type {
                    align-self: flex-end;
                }
            }
        }
        &__list-col {
            &--larger {
                padding: 0 rem(60);
                margin: rem(30) 0 rem(50) !important;
            }
        }
        &--topical {
            padding: rem(40) 0 0 0;
        }
    }
    @include media-breakpoint-down(sm) {
        &__row {
            margin-left: rem(-8);
            margin-right: rem(-8);
            > div[class^=col-] {
                padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
                padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
            }
        }
        &__list-col {
            &--mobile-4,
            &--mobile-5 {
                display: none;
            }
        }
    }
}

.article {
    $self: &;
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: rem(30);
    text-decoration: none;
    color: black;
    &:focus-visible {
        @include focus(rem(20));
    }
    &:hover {
        #{$self}__title {
            text-decoration: underline;
        }
    }
    &__image-container {
        position: relative;
        display: flex;
        overflow: hidden;
        border-radius: rem(20);
        height: rem(320);
        order: 1;
        &--small {
            height: rem(264);
        }
        &--medium {
            height: rem(292);
        }
        &--large {
            height: rem(367);
        }
    }
    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &__play-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(.75);
        &--large {
            width: rem(120);
            height: rem(120);
        }
    }
    &__text-container {
        position: relative;
        display: flex;
        align-items: center;
        overflow: hidden;
        border-radius: rem(20);
        background-color: $pr_lime;
        min-height: rem(293);
        &--large {
            min-height: rem(371);
        }
        &--orange {
            background-color: $pr_orange;
            #{$self}__text {
                color: white;
            }
        }
        &--green {
            background-color: $pr_green;
            #{$self}__text {
                color: white;
            }
        }
        &--with-max-height {
            max-height: rem(290);
        }
        &--force-height-auto {
            height: auto !important;
        }
    }
    &__text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: $playfair_display;
        font-size: rem(35);
        line-height: rem(37);
        font-weight: 900;
        color: black;
        text-align: center;
        width: 100%;
        padding: 0 rem(20)
    }
    &__content {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        padding: rem(20);
        order: 2;
    }
    &__meta {
        display: flex;
        flex-flow: row wrap;
        margin-bottom: rem(9);
        padding-left: 0;
        justify-content: center;
        list-style-type: none;
        li {
            font-size: rem(12);
            line-height: rem(15);
            color: black;
            padding: 0 rem(8);
            border-left: rem(1) solid black;
            &:first-child {
                border-left: 0;
            }
            a {
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    &__title {
        font-family: $playfair_display;
        font-size: rem(21);
        line-height: rem(25);
        color: black;
        text-align: center;
        font-weight: 900;
        order: 1;
        &--list {
            position: relative;
            font-size: rem(30);
            line-height: rem(32);
            color: white;
            margin-bottom: rem(12);
            padding: 0 rem(4);
        }
        a {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    &__list-items {
        position: relative;
        z-index: 2;
        padding-left: 0;
        margin-bottom: 0;
        order: 2;
        list-style-type: none;
        > li {
            border-top: rem(1) solid rgba(white, .5);
        }
        &--large {
            #{$self}__list-item {
                padding: rem(20) rem(5);
            }
            #{$self}__list-item-title {
                font-size: rem(21);
                line-height: rem(20);
            }
            #{$self}__list-item-meta {
                font-size: rem(15);
                line-height: rem(20);
            }
        }
    }
    &__list-item {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        padding: rem(10) rem(5);
        text-decoration: none;
        &:hover,
        &:focus-visible {
            #{$self}__list-item-title,
            #{$self}__list-item-meta {
                color: white;
                text-decoration: underline;
            }
        }
        &:focus-visible {
            @include focus(rem(15), rem(10));
            &:before {
                outline-color: white !important;
            }
        }
        &--follow {
            padding-bottom: 0;
            #{$self}__list-item-title {
                color: white;
                text-decoration: none !important;
            }
        }
    }
    &__list-item-title {
        font-family: $interstate;
        font-size: rem(18);
        line-height: rem(19);
        color: black;
        font-weight: 700;
        text-align: center;
        margin-bottom: 0;
    }
    &__list-item-meta {
        font-family: $interstate;
        font-size: rem(14);
        line-height: rem(19);
        color: black;
        text-align: center;
    }
    &__socials-list {
        display: flex;
        flex-flow: row nowrap;
        padding-left: 0;
        margin-bottom: 0;
        margin-top: rem(10);
        list-style-type: none;
    }
    &__socials-link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(31);
        height: rem(31);
        margin: 0 rem(5);
        border-radius: 50%;
        &:focus-visible {
            @include focus(50%);
        }
        &:hover {
            opacity: .8;
        }
        &--instagram {
            background: url("/public/svg/footer-ig.svg") center center / 100% no-repeat;
        }
        &--twitter {
            background: url("/public/svg/footer-tw.svg") center center / 100% no-repeat;
        }
        &--facebook {
            background: url("/public/svg/footer-fb.svg") center center / 100% no-repeat;
        }
    }
    &__intro-title {
        font-family: $playfair_display;
        font-size: rem(49);
        line-height: rem(54);
        font-weight: 900;
        color: white;
        text-align: center;
    }
    &__intro {
        font-size: rem(17);
        line-height: rem(25);
        margin-bottom: 0;
        color: white;
        text-align: center;
        margin-top: rem(16);
        max-width: rem(450);
    }
    &--list {
        position: relative;
        overflow: hidden;
        padding: rem(20);
        background-color: $se_green_light;
        border-radius: rem(45) 0 rem(45) 0;
        justify-content: center;
        &:before {
            position: absolute;
            width: rem(360);
            height: rem(1059);
            left: rem(-52);
            bottom: rem(-178);
            content: "";
            pointer-events: none;
            background: url("/public/svg/article-list-bg-green.svg") left bottom / 100% no-repeat;
        }
        &:hover {
            #{$self}__title {
                text-decoration: none;
            }
        }
        &--blue {
            background-color: #3BA6CC;
            &:before {
                bottom: rem(-264);
                pointer-events: none;
                background: url("/public/svg/article-list-bg-blue.svg") left bottom / 100% no-repeat;
            }
        }
        &--blue-light {
            background-color: #9FD2E4;
            &:before {
                bottom: rem(-264);
                pointer-events: none;
                background: url("/public/svg/article-list-bg-light-blue.svg") left bottom / 100% no-repeat;
            }
            //#{$self}__title--list {
            //    color: black !important;
            //}
        }
        &--orange {
            background-color: $pr_orange;
            &:before {
                display: none;
            }
        }
        &--lower-bg {
            &:before {
                bottom: rem(-378);
            }
        }
        &--file {
            height: 100%;
            #{$self}__title--list {
                margin-bottom: rem(24);
            }
            #{$self}__list-item {
                padding: rem(10);
            }
        }
        &--alt-list-item {
            justify-content: flex-start;
            padding-top: rem(30);
            #{$self}__list-item {
                min-height: rem(70);
                padding: rem(10) rem(20);
                justify-content: flex-start;
            }
        }
    }
    &--in-news-slider,
    &--white-content-big {
        margin-bottom: 0;
        height: 100%;
        #{$self}__image-container,
        #{$self}__text-container {
            height: rem(290);
            min-height: rem(290);
            border-radius: rem(15) rem(15) 0 0;
        }
        #{$self}__content {
            background-color: white;
            border-radius: 0 0 rem(15) rem(15);
            flex-grow: 1;
        }
        #{$self}__title {
            font-size: rem(21);
            line-height: rem(25);
        }
        &:focus-visible {
            #{$self}__title {
                text-decoration: underline;
            }
        }
    }
    &--in-news-slider {
        &:focus-visible {
            @include focus(rem(15));
            &:before {
                outline-color: white;
            }
        }
    }
    &--in-news-articles {
        #{$self}__image-container,
        #{$self}__text-container {
            height: rem(275);
            min-height: rem(275);
        }
    }
    &--white-content-big {
        &:focus-visible {
            @include focus(rem(15));
        }
        #{$self}__image-container {
            &--large {
                height: rem(362);
            }
        }
        #{$self}__text {
            color: white;
        }
        #{$self}__content {
            padding: rem(23) rem(30) rem(40);
        }
        #{$self}__meta {
            margin-bottom: rem(16);
        }
        #{$self}__title {
            font-size: rem(21);
            line-height: rem(25);
        }
    }
    &--intro-block {
        align-items: center;
        justify-content: center;
        padding: rem(52) rem(40);
        background-color: $se_blue;
        border-radius: rem(75) 0 rem(75) 0;
        margin-bottom: 0;
        &--lime {
            background-color: $pr_lime;
            #{$self}__intro-title,
            #{$self}__intro {
                color: black;
            }
        }
        &--orange {
            background-color: $pr_orange;
            #{$self}__intro-title,
            #{$self}__intro {
                color: white;
            }
        }
    }
    &--green,
    &--blue,
    &--lime {
        margin-bottom: 0;
        #{$self}__image-container {
            height: rem(460);
        }
        #{$self}__content {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: rem(33) rem(30);
            min-height: rem(178);
            background-color: rgba($gr1_green, .5);
            //&:before {
            //    position: absolute;
            //    left: 0;
            //    bottom: 0;
            //    width: 100%;
            //    height: 100%;
            //    background-color: rgba(black, .5);
            //    content: "";
            //    mix-blend-mode: multiply;
            //}
        }
        #{$self}__title {
            position: relative;
            color: white;
            text-decoration-thickness: rem(1) !important;
            word-break: break-word;
        }
        &:focus-visible {
            #{$self}__title {
                text-decoration: underline;
            }
        }
        &--gradient {
            #{$self}__content {
                justify-content: center;
                min-height: rem(130);
                background: rgb(16,123,64);
                background: linear-gradient(45deg, rgba(16,123,64,1) 0%, rgba(68,160,159,1) 100%);
            }
        }
    }
    &--blue {
        #{$self}__content {
            justify-content: center;
            background-color: $se_blue;
            min-height: 0;
        }
    }
    &--lime {
        #{$self}__content {
            justify-content: center;
            background-color: $pr_lime;
            min-height: 0;
        }
        #{$self}__title {
            color: black;
        }
    }
    @include media-breakpoint-down(xxl) {
        &--in-related-articles {
            #{$self}__image-container {
                height: rem(300);
            }
        }
        &--green,
        &--blue,
        &--lime {
            #{$self}__image-container {
                height: rem(460);
            }
        }
    }
    @include media-breakpoint-down(xl) {
        &__image-container {
            &--small {
                height: rem(263);
            }
        }
        &__text-container {
            min-height: rem(300);
        }
        &__meta {
            margin-bottom: rem(5);
            li {
                font-size: rem(14);
                line-height: rem(18);
            }
        }
        &__text {
            font-size: rem(30);
            line-height: rem(32);
        }
        &__list-item-meta {
            font-size: rem(16);
            line-height: rem(22);
        }
        &__intro-title {
            font-size: rem(44);
            line-height: rem(50);
        }
        &__title {
            &--list {
                font-size: rem(26);
                line-height: rem(30);
            }
        }
        &--in-news-slider,
        &--white-content-big {
            #{$self}__meta {
                font-size: rem(12);
                line-height: rem(20);
            }
        }
        &--white-content-big {
            #{$self}__content {
                padding: rem(23) rem(10) rem(30);
            }
        }
        &--in-related-articles {
            #{$self}__image-container {
                height: rem(236);
            }
        }
        &--green,
        &--blue,
        &--lime {
            #{$self}__image-container {
                height: rem(400);
            }
            #{$self}__content {
                min-height: rem(152);
                padding: rem(20);
            }
        }
    }
    @include media-breakpoint-down(lg) {
        margin-bottom: rem(16);
        &__intro {
            max-width: rem(550);
        }
        &--list {
            margin-bottom: rem(10) !important;
            width: 100%;
            height: auto !important;
            //&.col-12 {
            //    max-width: 50%;
            //}
        }
        &__list-items {
            &--large {
                #{$self}__list-item {
                    padding: rem(10) rem(5);
                }
                #{$self}__list-item-title {
                    font-size: rem(14);
                    line-height: rem(18);
                }
                #{$self}__list-item-meta {
                    font-size: rem(11);
                    line-height: rem(13);
                }
            }
        }
        &--intro-block {
            padding: rem(48) rem(35);
            height: auto !important;
        }
        &--list {
            &:before {
                left: rem(-12);
                bottom: rem(-680);
                width: rem(648);
                height: rem(1906);
            }
        }
        &--in-news-slider,
        &--white-content-big {
            #{$self}__image-container {
                height: rem(240);
                min-height: 0;
            }
            #{$self}__text-container {
                height: rem(240) !important;
                min-height: rem(240);
            }
        }
        &--in-related-slider {
            #{$self}__image-container {
                height: rem(236);
            }
        }
        &--green,
        &--blue,
        &--lime {
            margin-bottom: 0;
        }
    }
    @include media-breakpoint-down(md) {
        &__content {
            padding: rem(12) rem(12);
        }
        &__title {
            font-size: rem(17);
            line-height: rem(19);
            &--list {
                font-size: rem(18);
                line-height: 1;
                margin-bottom: rem(10);
            }
        }
        &__text {
            font-size: rem(22);
            line-height: rem(24);
            padding: 0 rem(4)
        }
        &__meta {
            justify-content: center;
            li {
                font-size: rem(11);
                line-height: rem(13);
                padding: 0 rem(4);
            }
        }
        &__list-item {
            padding: rem(4) rem(5);
        }
        &__list-item-title {
            font-size: rem(14);
            line-height: 1;
        }
        &__list-item-meta {
            font-size: rem(9);
            line-height: rem(14);
        }
        &__play-icon {
            //width: rem(30);
            //height: rem(30);
            &--large {
                width: rem(50);
                height: rem(50);
            }
        }
        &--intro-block {
            max-width: calc(100% - rem(100));
            margin-left: rem(50);
            border-radius: rem(45) 0 rem(45) 0;
            padding: rem(36) rem(20);
        }
        &__intro-title {
            font-size: rem(30);
            line-height: rem(32);
        }
        &__intro {
            font-size: rem(14);
            line-height: rem(22);
        }
        &--list {
            padding: rem(16);
            border-radius: rem(26) 0 rem(26) 0;
            &:before {
                width: calc(100% + rem(40));
                left: rem(-20);
            }
        }
        &--in-news-slider,
        &--white-content-big {
            &:focus-visible {
                &:before {
                    border-radius: rem(10);
                }
            }
            #{$self}__image-container {
                height: rem(202);
                border-radius: rem(10) rem(10) 0 0;
            }
            #{$self}__content {
                border-radius: 0 0 rem(10) rem(10);
            }
            #{$self}__text-container {
                height: rem(202) !important;
                min-height: rem(202);
                border-radius: rem(10) rem(10) 0 0;
            }
            #{$self}__title {
                font-size: rem(17);
                line-height: rem(19);
            }
        }
        &--white-content-big {
            #{$self}__image-container {
                height: rem(154) !important;
                &--large {
                    height: rem(210) !important;
                }
            }
            #{$self}__content {
                padding: rem(23) rem(10) rem(10);
                border-radius: 0 0 rem(10) rem(10);
            }
            &--bigger {
                #{$self}__image-container {
                    height: rem(202) !important;
                }
            }
        }
        &--green,
        &--blue,
        &--lime {
            #{$self}__image-container {
                height: rem(248);
            }
            #{$self}__content {
                min-height: rem(127);
                padding: rem(12);
            }
            &--gradient {
                #{$self}__content {
                    min-height: rem(80);
                }
            }
        }
        &--blue,
        &--lime {
            #{$self}__content {
                min-height: rem(80);
            }
        }
    }
    @include media-breakpoint-down(sm) {
        margin-bottom: rem(10);
        &:focus-visible {
            @include focus(rem(10));
        }
        &__image-container {
            height: rem(175);
            border-radius: rem(10);
            &--small {
                height: rem(154);
            }
            &--large {
                height: rem(203);
            }
        }
        &__text-container {
            min-height: rem(160);
            border-radius: rem(10);
        }
        &__content {
            padding: rem(12) rem(4);
        }
        &__list-item {
            &:focus-visible {
                @include focus(rem(10), rem(10));
            }
        }
        &--list {
            max-width: rem(236);
            &:before {
                bottom: rem(-100);
            }
            &--file {
                #{$self}__list-item {
                    padding: rem(10) 0;
                }
            }
        }
        &--in-news-slider,
        &--white-content-big {
            #{$self}__meta {
                li {
                    font-size: rem(9);
                    line-height: rem(11);
                }
            }
        }
        &--in-news-slider {
            &:focus-visible {
                &:before {
                    outline-color: white;
                }
            }
        }
        &--in-related-articles {
            &:focus-visible {
                &:before {
                    border-radius: rem(10);
                }
            }
        }
        &--green,
        &--blue,
        &--lime {
            margin-bottom: 0;
        }
    }
}