.breadcrumbs {
    $self: &;
    display: flex;
    flex-flow: row nowrap;
    padding-left: 0;
    margin-top: rem(38);
    &__list-item {
        position: relative;
        padding: 0 rem(14);
        list-style-type: none;
        &:after {
            position: absolute;
            top: rem(6);
            right: 0;
            width: rem(2);
            height: rem(14);
            content: "";
            background-color: rgba(black, .7);
        }
        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
    &__item {
        font-size: rem(14);
        line-height: rem(26);
        color: black;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
        &--current {
            pointer-events: none;
        }
    }
    &--mobile {
        display: none;
    }
    @include media-breakpoint-down(lg) {
        display: none;
        &--mobile {
            display: flex;
            margin-top: rem(16);
            margin-bottom: 0;
            #{$self}__list-item {
                position: relative;
                padding-left: rem(10);
                &:before {
                    position: absolute;
                    left: 0;
                    top: rem(8);
                    width: rem(6);
                    height: rem(10);
                    background: url("/public/svg/chevron-left-black.svg") center center / 100% no-repeat;
                    content: "";
                }
            }
            #{$self}__item {
                text-decoration: underline;
                font-size: rem(11);
            }
        }
    }
}