.a-col {
    $self: &;
    display: flex;
    flex-flow: column nowrap;
    padding: rem(26) 0 rem(37);
    &__title {
        font-size: rem(28);
        line-height: rem(44);
        margin-bottom: rem(28);
    }
    &__link-list {
        display: flex;
        flex-flow: column nowrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;
    }
    &__li {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        border-bottom: rem(1) solid rgba(#0F1D37, .3);
        &:last-child {
            border-bottom-color: transparent;
        }
        &:hover {
            #{$self}__list-item {
                font-weight: 600;
            }
            #{$self}__list-item-chevron {
                background-color: black;
                svg {
                    path {
                        fill: white;
                    }
                }
            }
        }
    }
    &__list-item {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        font-size: rem(17);
        line-height: rem(24);
        padding: rem(7) rem(10) rem(7) 0;
        color: $txt_dark_grey;
        font-weight: 500;
        text-decoration: none;
        width: 100% ;
        &:focus-visible {
            font-weight: 600;
            &:before {
                top: 0;
            }
            @include focus(rem(40), rem(30));
            + #{$self}__list-item-chevron {
                background-color: black;
                svg {
                    path {
                        fill: white;
                    }
                }
            }
        }
        &--date {
            width: 50%;
        }
    }
    &__list-item-chevron {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 0;
        bottom: rem(8);
        width: rem(19);
        height: rem(19);
        border-radius: 50%;
        transform: translateX(rem(6));
        svg {
            transform: translateX(rem(1)) scale(.85);
        }
    }
    &--with-bg {
        background-color: #BCDBC9;
        padding: rem(26) rem(20) rem(37) rem(28);
        border-radius: rem(20);
    }
    @include media-breakpoint-down(xxl) {
        padding: rem(20) 0 rem(28);
        &__title {
            font-size: rem(30);
            line-height: rem(28);
            margin-bottom: rem(24);
        }
        &__list-item {
            font-size: rem(18);
            line-height: rem(26);
            &--date {
                width: 60%;
            }
        }
        &__list-item-chevron {
            bottom: rem(12);
        }
        &--with-bg {
            padding: rem(20) rem(30) rem(28);
        }
        &--in-more-info {
            #{$self}__title {
                font-size: rem(26);
                line-height: rem(28);
            }
        }
    }
    @include media-breakpoint-down(xl) {
        &__title {
            font-size: rem(22);
            line-height: rem(28);
            margin-bottom: rem(16);
        }
        &__list-item {
            font-size: rem(16);
            line-height: rem(24);
            padding: rem(5) rem(10) rem(5) 0;
            &--date {
                width: 60%;
            }
        }
        &__list-item-chevron {
            bottom: rem(6);
        }
        &--in-more-info {
            #{$self}__title {
                font-size: rem(20);
                line-height: rem(22);
            }
        }
    }
    @include media-breakpoint-down(lg) {
        &__list-item {
            &--date {
                width: 50%;
            }
        }
    }
    @include media-breakpoint-down(md){
        padding: rem(20) 0 rem(6);
        &__list-item {
            font-size: rem(14);
            line-height: rem(24);
        }
        &--with-bg {
            margin-top: rem(20);
            padding: rem(10) rem(16) rem(10);
        }
    }
    @include media-breakpoint-down(sm) {
        &__list-item {
            flex-flow: column nowrap;
            &--date {
                width: 100%;
                font-size: rem(12);
                line-height: rem(18);
                opacity: .7;
            }
        }
        &__list-item-chevron {
            svg {
                width: rem(8);
            }
        }
    }
}