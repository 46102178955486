.dp {
    $self: &;
    display: flex;
    flex-flow: column nowrap;
    &__grid {
        display: grid;
        row-gap: rem(7);
        column-gap: rem(7);
        grid-template-columns: repeat(7,1fr);
        font-size: rem(17);
        font-weight: 300;
        color: black;
        text-decoration: none;
        margin-bottom: rem(38);
    }
    &__cell {
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(46);
        height: rem(46);
        border-radius: rem(10);
        text-decoration: none !important;
        &--state-available {
            background-color: #D1DA4D;
            &:hover {
                background-color: $pr_orange;
                color: white;
                font-weight: 900;
            }
        }
        &--state-selected {
            position: relative;
            background-color: $pr_orange;
            color: white !important;
            font-size: rem(20);
            font-weight: 900;
            border-radius: rem(8);
            &:before {
                position: absolute;
                left: rem(-9);
                top: rem(-9);
                width: rem(64);
                height: rem(64);
                border: rem(3) solid $pr_orange;
                border-radius: rem(14);
                content: "";
            }
        }
        &--state-disabled {
            pointer-events: none;
            background-color: #EBECEC;
            color: #969696;
            text-decoration: line-through !important;
        }
        &--day {
            height: rem(30);
            &--disabled {
                color: #C8C8C8;
            }
        }
        &--time {
            width: rem(106);
            margin-right: rem(7);
            margin-bottom: rem(8);
            &#{$self}__cell--state-selected {
                font-size: rem(17);
                &:before {
                    width: rem(124);
                }
            }
        }
    }
    &__flex {
        display: flex;
        flex-flow: row wrap;
    }
    &__nav {
        display: flex;
        justify-content: space-between;
        margin-bottom: rem(30);
    }
    &__nav-chev {
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(26);
        height: rem(26);
    }
    &__nav-month {
        font-size: rem(21);
        line-height: rem(26);
        &--first {
            margin-right: auto;
            margin-left: 11%;
        }
        &--last {
            margin-left: auto;
            margin-right: 11%;
        }
    }
    &__select {
        padding-top: 0;
        margin-bottom: rem(38);
    }
    &__legend {
        display: flex;
        flex-flow: row wrap;
        margin-bottom: rem(18);
        justify-content: flex-end;
    }
    &__legend-item {
        display: flex;
        align-items: center;
        font-size: rem(17);
        margin-left: rem(30);
        margin-bottom: rem(20);
        #{$self}__cell {
            pointer-events: none;
            margin-left: rem(15);
        }
    }
    &__link {
        display: block;
        font-weight: 900;
        font-size: rem(17);
        text-decoration: none;
        margin-bottom: rem(38);
        &:hover {
            text-decoration: underline;
        }
        &--in-cell {
            padding: 0 rem(10);
            margin-bottom: 0;
            white-space: nowrap;
            align-self: center;
        }
    }
    @include media-breakpoint-down(md) {
        &__grid {
            column-gap: rem(6);
        }
        &__cell {
            width: rem(40);
            height: rem(40);
            font-size: rem(14);
            border-radius: rem(7);
            &--state-selected {
                &:before {
                    top: rem(-8);
                    left: rem(-8);
                    width: rem(56);
                    height: rem(56);
                }
            }
            &--time {
                width: rem(88);
                &#{$self}__cell--state-selected {
                    font-size: rem(14);
                    &:before {
                        width: rem(104);
                    }
                }
            }
        }
        &__legend {
            flex-flow: column nowrap;
            align-items: flex-end;
            margin-bottom: rem(4);
        }
        &__legend-item {
            font-size: rem(14);
            margin-left: rem(33);
            #{$self}__cell {
                margin-left: rem(15);
            }
        }
        &__nav {
            margin-bottom: rem(24);
        }
        &__nav-chev {
            svg {
                width: rem(6);
            }
        }
        &__nav-month {
            font-size: rem(14);
        }
    }
}