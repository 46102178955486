@mixin focus($radius: rem(10), $hor: rem(0), $pos: relative, $ver: rem(0)) {
    position: $pos;
    outline: 0;
    border: 0;
    &:before {
        position: absolute;
        left: calc(-#{$hor} / 2);
        top: calc(-#{$ver} / 2);
        width: calc(100% + #{$hor});
        height: calc(100% + #{$ver});
        outline: rem(3) solid $se_focus;
        -moz-outline-radius: $radius;
        border-radius: $radius;
        content: "";
    }
    @include media-breakpoint-down(xl) {
        &:before {
            outline-width: rem(3);
        }
    }
}