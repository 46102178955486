.more-info {
    $self: &;
    .row {
        &:first-of-type {
            position: relative;
            z-index: 2;
        }
    }
    &:last-of-type {
        padding-bottom: 0;
    }
    &__container {
        display: flex;
        flex-flow: column nowrap;
        text-align: center;
        align-items: center;
        padding: rem(90) rem(40) rem(75);
        border-radius: rem(75) 0 rem(75) 0;
        background: rgb(104,185,213);
        background: linear-gradient(190deg, rgba(104,185,213,1) 0%, rgba(42,57,87,1) 85%, rgba(42,57,87,1) 100%);
        &--has-link-block-above {
            position: relative;
            margin-top: rem(-240);
            z-index: 3;
        }
        &--search {
            padding: rem(90) rem(75) rem(120);
        }
    }
    &__title {
        font-family: $playfair_display;
        font-weight: 500;
        color: white;
        font-size: rem(46);
        line-height: rem(61);
        margin-bottom: rem(30);
        &--slider {
            color: black;
            font-weight: 900;
            font-size: rem(55);
            line-height: rem(78);
            margin-bottom: 0;
        }
    }
    p {
        font-size: rem(24);
        line-height: rem(40);
        font-weight: 300;
        margin-bottom: rem(40);
        color: white;
    }
    &__content {
        p, ul li, ol li, h1, h2, h3, h4, h5, h6 {
            font-size: rem(21);
            line-height: rem(31);
            font-weight: 500;
            margin-bottom: rem(35);
            color: white;
        }
        a {
            color: white;
        }
        h2, h3, h4, h5, h6 {
            font-weight: 700;
        }
        h2, h3, h4 {
            font-size: rem(30);
        }
        ul, ol {
            padding-left: 0;
            li {
                margin-bottom: 0;
            }
        }
    }
    &__links {
        display: flex;
        flex-flow: column nowrap;
        margin-bottom: rem(22);
        padding-left: 0;
        list-style-type: none;
    }
    &__link {
        font-size: rem(22);
        line-height: rem(31);
        text-decoration: none;
        font-weight: 300;
        color: white !important;
        &:hover {
            text-decoration: underline;
        }
        &:focus-visible {
            @include focus(rem(10), rem(10));
            &:before {
                outline-color: white;
            }
        }
    }
    &__call {
        font-size: rem(37);
        line-height: 1;
        font-weight: 700;
        margin-bottom: rem(30);
        color: white !important;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
        &:focus-visible {
            @include focus(rem(10), rem(10));
            &:before {
                outline-color: white;
            }
        }
    }
    &__contact-list {
        display: flex;
        flex-flow: column nowrap;
        padding-left: 0;
        list-style-type: none;
        li {
            font-size: rem(17);
            line-height: rem(25);
            color: white;
            a {
                text-decoration: none;
                color: white;
                &:hover {
                    text-decoration: underline;
                }
                &:focus-visible {
                    @include focus(rem(10), rem(10));
                    &:before {
                        outline-color: white;
                    }
                }
            }
        }
    }
    &__link-block {
        padding: rem(37) rem(80) rem(26);
        background-color: #C4DFD0;
        border-radius: 0 rem(80) 0 rem(80);
        &--grey {
            background-color: $se_grey;
        }
        &--has-info-above {
            padding-top: rem(228);
            margin-top: rem(-190)
        }
        &--has-info-below {
            padding-bottom: rem(268);
        }
    }
    &__title-block-container {
        position: relative;
    }
    &__title-block {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column nowrap;
        padding: rem(60) rem(30);
        margin-top: rem(52);
        border-radius: rem(45) 0 rem(45) 0;
        z-index: 2;
        opacity: .85;
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $pr_green;
            mix-blend-mode: multiply;
            border-radius: rem(45) 0 rem(45) 0;
            content: "";
        }
        #{$self}__title {
            // Title smaller because wrong word wrapping
            position: relative;
            font-family: $interstate;
            font-size: rem(37);
            line-height: 1;
            font-weight: 700;
            margin-bottom: 0;
            text-align: center;
        }
    }
    &__content-block-inner {
        //position: absolute;
        display: flex;
        flex-flow: column nowrap;
        //bottom: rem(135);
        //right: rem(-130);
        width: 100%;
        //transform: translateY(100%);
        margin-top: rem(-100);
        padding: rem(138) rem(50) rem(8);
        background-color: rgba($pr_lime, .7);
        border-radius: 0 rem(45) 0 rem(45);
        z-index: 1;
        p,a {
            font-size: rem(18);
            line-height: rem(28);
            margin-bottom: rem(30);
            text-align: center;
            color: black;
        }
        a {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    &__form {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        max-width: rem(400);
        width: 100%;
        margin-top: rem(24);
    }
    &__form-input-container {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        width: 100%;
    }
    &__form-label {
        font-size: rem(18) !important;
        line-height: rem(12);
        color: white;
        margin-right: rem(10);
        margin-bottom: 0;
        white-space: nowrap;
        transform: translateY(0) translateX(0) !important;
    }
    &__form-input {
        background-color: white;
        height: rem(54);
        border-radius: rem(28);
        font-size: rem(18);
        line-height: rem(12);
        font-weight: 500;
        color: #646464;
        padding: 0 rem(60) 0 rem(20);
        border: rem(1) solid #707070;
    }
    &__form-submit {
        position: absolute;
        top: rem(8);
        right: rem(8);
        width: rem(38);
        height: rem(38);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: rgba(black, .15);
        border: 0;
        svg {
            transform: rotate(180deg) translateX(rem(-2)) scale(.75);
        }
        &:hover {
            background-color: rgba(black, .25);
        }
    }
    &__article-slider {
        position: relative;
        padding-top: rem(50);
    }

    &--has-padding {
        padding: rem(112) 0 0 0;
    }
    &--three-blocks {
        overflow: hidden;
        padding: rem(60) 0 rem(112);
        #{$self}__content {
            p {
                font-size: rem(22);
                line-height: rem(30);
            }
        }
        &--orange {
            #{$self}__title-block {
                opacity: 1;
                &:before {
                    background-color: $pr_orange;
                }
            }
            #{$self}__content-block-inner {
                background-color: rgba($se_orange, .5);
            }
        }
    }
    &--negative-margin {
        padding-top: 0;
        margin-top: rem(-198);
    }
    @include media-breakpoint-down(xxl) {
        &__container {
            padding: rem(85) rem(70) rem(70);
            &--search {
                padding: rem(85) rem(70) rem(110);
            }
        }
        &__link-block {
            padding: rem(37) rem(65) rem(24);
            &--has-info-above {
                padding-top: rem(228);
            }
            &--has-info-below {
                padding-bottom: rem(228);
            }
        }
        &--has-padding {
            padding: rem(100) 0 0 0;
        }
    }
    @include media-breakpoint-down(xl) {
        &__container {
            padding: rem(65) rem(50) rem(50);
            &--search {
                padding: rem(65) rem(50) rem(90);
            }
            &--has-link-block-above {
                margin-top: rem(-180);
            }
        }
        &__content {
            p {
                //font-size: rem(24);
            }
        }
        &__title {
            font-size: rem(40);
            line-height: rem(60);
            margin-bottom: rem(30);
            &--slider {
                margin-bottom: 0;
            }
        }
        &__links {
            margin-bottom: rem(20);
        }
        &__link {
            font-size: rem(22);
            line-height: rem(30);
        }
        &__call {
            font-size: rem(38);
            margin-bottom: rem(30);
        }
        &__contact-list {
            li {
                font-size: rem(18);
                line-height: rem(26);
            }
        }
        &__link-block {
            padding: rem(30) rem(45) rem(24);
            &--has-info-above {
                padding-top: rem(285);
            }
            &--has-info-below {
                padding-bottom: rem(190);
            }
        }
        &__title-block {
            padding: rem(60) rem(30);
            #{$self}__title {
                font-size: rem(32);
            }
        }
        &__content-block-inner {
            padding: rem(165) rem(30) rem(10);
            p,a {
                font-size: rem(20);
                line-height: rem(32);
                margin-bottom: rem(20);
            }
        }
        &--has-padding {
            padding: rem(80) 0 0 0;
        }
    }
    @include media-breakpoint-down(lg) {
        &__container {
            &--has-link-block-above {
                width: calc(100% - rem(80));
                margin-left: rem(40)
            }
        }
        &__link-block {
            &--has-info-above {
                padding-top: rem(30);
                margin-top: rem(60);
            }
        }
        &__content-block-inner {
            margin-top: rem(-75);
            padding: rem(105) rem(30) rem(10);
        }
        &__article-slider {
            padding-top: 0;
        }
        &--has-padding {
            padding: rem(60) 0 0 0;
        }
        &--three-blocks:not(.more-info--stories) {
            > .container {
                > .row {
                    > div[class^="col-"] {
                        &:first-of-type {
                            order: 2;
                        }
                        &:last-of-type {
                            order: 1;
                        }
                    }
                }
            }
        }
        &--stories {
            padding-bottom: rem(10) !important;
            #{$self}__title-block {
                margin-top: rem(20);
            }
            #{$self}__article-slider {
                padding-top: rem(30);
            }
        }
    }
    @include media-breakpoint-down(md) {
        &__container {
            padding: rem(40) rem(20) rem(30);
            border-radius: rem(50) 0 rem(50);
            &--search {
                padding: rem(40) rem(20) rem(60);
            }
            &--has-link-block-above {
                #{$self}__content {
                    p {
                        margin-bottom: rem(20);
                    }
                }
            }
        }
        &__title {
            font-size: rem(30);
            line-height: rem(34);
            margin-bottom: rem(24);
            &--slider {
                margin-bottom: 0;
            }
        }
        &__link {
            font-size: rem(14);
            line-height: rem(20);
            text-decoration: none;
        }
        &__call {
            font-size: rem(20);
            margin-bottom: rem(20);
        }
        &__contact-list {
            li {
                font-size: rem(14);
                line-height: rem(20);
            }
        }
        &__link-block {
            padding: rem(30) rem(30) rem(45);
            border-radius: 0 rem(50) 0 rem(50);
            &--has-info-below {
                padding-bottom: rem(190);
            }
        }
        &__content {
            p {
                font-size: rem(14);
                line-height: rem(20);
            }
            h2, h3, h4 {
                font-size: rem(20);
            }
            p, ul li, ol li, h1, h2, h3, h4, h5, h6 {
                margin-bottom: rem(20);
            }
        }
        &__title-block {
            margin-top: rem(50);
            padding: rem(40) rem(30);
            &:before {
                border-radius: rem(40) 0 rem(40) 0;
            }
        }
        &__content-block-inner {
            border-radius: 0 rem(40) 0 rem(40);
            padding: rem(95) rem(20) rem(10);
            p {
                font-weight: 500;
            }
            p,a {
                font-size: rem(14);
                line-height: rem(20);
                margin-bottom: rem(20);
            }
            a {
                font-weight: 700;
            }
        }
        &__form-input-container {
            flex-flow: column nowrap;
            align-items: flex-start;
        }
        &__form-label {
            margin-bottom: rem(10);
        }
        &__form-submit {
            top: auto;
            bottom: rem(8);
        }
        &--three-blocks {
            padding: rem(40) 0 rem(60);
            #{$self}__content {
                p {
                    font-size: rem(14);
                    line-height: rem(20);
                }
            }
        }
        &--negative-margin {
            padding-top: 0;
            margin-top: rem(-295);
        }
    }
}