.council {
    &__main {
        padding-top: rem(20);
    }
    &__row {
        margin-bottom: rem(52);
        &:last-of-type {
            margin-bottom: rem(66);
        }
    }
    &__image-container {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 67%;
        overflow: hidden;
        border-radius: rem(60) 0 rem(60);
    }
    &__image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &__content {
        display: flex;
        flex-flow: column nowrap;
        &--align-right {
            text-align: right;
        }
    }
    &__title {
        font-family: $playfair_display;
        font-size: rem(35);
        line-height: rem(42);
        font-weight: 600;
        margin-bottom: rem(36);
    }
    &__text {
        font-size: rem(22);
        line-height: rem(30);
        font-weight: 400;
    }
    @include media-breakpoint-down(xl) {
        &__title {
            font-size: rem(24);
            line-height: rem(28);
            margin-bottom: rem(20);
        }
        &__text {
            font-size: rem(20);
            line-height: rem(28);
        }
    }
    @include media-breakpoint-down(lg) {
        &__row {
            margin-bottom: rem(30);
            &:last-of-type {
                margin-bottom: rem(50);
            }
        }
    }
    @include media-breakpoint-down(md) {
        &__row {
            &:last-of-type {
                margin-bottom: rem(20);
                > div[class^="col-"] {
                    &:first-of-type {
                        order: 2;
                    }
                    &:last-of-type {
                        order: 1;
                    }
                }
            }
        }
        &__image-container {
            margin-bottom: rem(30);
        }
        &__title {
            text-align: left !important;
        }
        &__text {
            text-align: left !important;
            font-size: rem(13);
            line-height: rem(20);
        }
    }
}