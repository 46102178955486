// colors
$pr_orange:             #C95100;
$pr_lime:               #C9D32C;
$pr_green:              #0E7A3D;

$se_orange:             #FACBAA;
$se_orange_light:       #FDEFE5;
$se_grey:               #EBECEC;
$se_green_light:        #64AA78;
$se_green_lighter:      #E5F1EB;
$se_blue_light:         #D8E5EF;
$se_blue:               #2A3957;
$se_blue_dark:          #0F1D37;
$se_focus:              #646464;

$gr1_green:             #0E7A3D;
$gr1_blue:              #1E4146;

$gr2_light_blue:        #60B5D3;
$gr2_blue:              #2A3957;

$txt_dark_grey:         #323232;

// Fonts
$interstate: 'interstate', sans-serif;
$interstate_compressed: 'interstate-compressed', sans-serif;
$interstate_condensed: 'interstate-condensed', sans-serif;
$playfair_display: 'Playfair Display';

$grid-gutter-width: 2.5rem;

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1535px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1220px,
    xxl: 1220px
);

$theme-colors: (
        "primary":    $pr_green,
        "secondary":  $pr_orange
);

$modal-backdrop-bg:                 #1F1F1F;
$modal-backdrop-opacity:            .85;