.products {
    display: flex;
    flex-flow: column nowrap;
    > .container {
        max-width: rem(1220) !important;
    }
    &__section {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        padding: rem(50) 0 rem(55) 0;
        &:after {
            position: absolute;
            left: rem(20);
            bottom: 0;
            width: calc(100% - rem(40));
            height: rem(1);
            background-color: #707070;
            content: "";
        }
        &:last-of-type {
            &:after {
                display: none;
            }
        }
    }
    &__section-title {
        font-size: rem(31);
        line-height: 1.3;
        margin-bottom: rem(38);
        padding: 0 rem(20);
        span {
            font-weight: 700;
        }
    }
    &__row {
        display: flex;
        flex-flow: row wrap;
        width: calc(100% + rem(20));
        padding: 0 rem(10);
        margin-right: rem(-40);
    }
    &__more {
        font-size: rem(17);
        line-height: rem(21);
        a {
            font-weight: 700;
            &:hover {
                text-decoration: none;
            }
        }
    }
    @media (max-width: 1220px) {
        &__section {
            padding: rem(30) 0 rem(35) 0;
        }
        &__section-title {
            font-size: rem(26);
            margin-bottom: rem(28);
        }
    }
    @include media-breakpoint-down(lg) {
        &__row {
            width: calc(100% + rem(40));
            margin-right: rem(-20);
        }
    }
    @include media-breakpoint-down(md) {
        &__section {
            padding: rem(30) 0;
        }
        &__section-title {
            font-size: rem(14);
            line-height: rem(18);
            margin-bottom: rem(24);
            padding: 0;
            text-align: center;
        }
        &__more {
            font-size: rem(14);
            line-height: rem(20);
            text-align: center;
        }
    }
}

.product {
    padding-left: rem(10) !important;
    padding-right: rem(10) !important;
    margin-bottom: rem(30);
    &__inner {
        display: flex;
        flex-flow: column nowrap;
        padding: rem(46) rem(40) rem(40);
        background: white;
        border-radius: rem(45) 0;
        height: 100%;
    }
    &__title {
        font-family: $playfair_display;
        font-size: rem(27);
        line-height: rem(33);
        margin-bottom: rem(24);
        font-weight: 900;
        hyphens: auto;
    }
    &__excerpt {
        font-size: rem(15);
        line-height: rem(23);
        font-weight: 400;
        margin-bottom: rem(19);
    }
    &__buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: auto;
        a:not(.button) {
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    &__content {
        padding-top: rem(90);
        padding-bottom: rem(70);
    }
    @media (max-width: 1220px) {
        &__inner {
            padding: rem(36) rem(30) rem(30);
            border-radius: rem(35) 0;
        }
    }
    @include media-breakpoint-down(md) {
        margin-bottom: rem(20);
        &__inner {
            padding: rem(20);
            border-radius: rem(20) 0;
        }
        &__title {
            font-size: rem(16);
            line-height: rem(20);
            margin-bottom: rem(7);
        }
        &__excerpt {
            font-size: rem(11);
            line-height: rem(16);
            margin-bottom: rem(17);
        }
        &__buttons {
            justify-content: flex-start;
            a:not(.button) {
                margin-right: rem(20);
                font-size: rem(11);
                line-height: rem(20);
            }
        }
        &__content {
            padding-top: rem(50);
            padding-bottom: rem(30);
        }
    }
}