.article-images {
    $self: &;
    overflow: hidden;
    &__col {
        display: flex;
        flex-flow: column nowrap;
        margin-bottom: rem(75);
        position: relative;
        &:nth-child(odd) {
            align-items: flex-end;
            #{$self}__figure {
                align-items: flex-end;
            }
            #{$self}__image {
                border-radius: 0 rem(20) rem(20) 0;
            }
            .editor__description,
            figcaption {
                padding-left: rem(20);
            }
            &:last-of-type {
                align-items: flex-start;
                #{$self}__figure {
                    align-items: flex-start;
                }
                #{$self}__image {
                    border-radius: rem(20);
                }
            }
        }
        &:nth-child(even) {
            #{$self}__image {
                border-radius: rem(20) 0 0 rem(20);
            }
        }
    }
    &__figure,
    figure {
        display: flex;
        flex-flow: column nowrap;
        width: 100%;
    }
    figcaption {
        font-size: rem(12) !important;
        line-height: rem(19) !important;
        color: black;
        margin-top: rem(30);
        margin-bottom: 0 !important;
        max-width: rem(713);
    }
    &__image-container {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 69%;
    }
    &__image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &--larger {
        #{$self}__image-container {
            padding-bottom: 93%;
        }
    }
    @include media-breakpoint-down(lg) {
        &__col {
            .editor__description {
                max-width: 100%;
            }
        }
    }
    @include media-breakpoint-down(md) {
        &__col {
            margin-bottom: rem(50);
            &:nth-child(odd) {
                padding-right: rem(40);
            }
            &:nth-child(even) {
                padding-left: rem(40);
            }
            &:last-of-type {
                &:nth-child(odd) {
                    #{$self}__image {
                        border-radius: 0 rem(20) rem(20) 0;
                    }
                }
            }
        }
    }
}