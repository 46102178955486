.hero-image {
    $self: &;
    position: relative;
    width: 100%;
    height: rem(530);
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    &__image-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &__image {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
        &--align-bottom {
            object-position: bottom;
        }
    }
    &__description-container,
    figcaption {
        padding: rem(9) rem(20);
        position: absolute;
        right: rem(88);
        bottom: rem(40);
        background-color: white;
        border-radius: rem(10);
        width: auto;
        max-width: rem(462);
        &--white {
            padding: 0;
            background-color: transparent;
            width: auto;
            display: inline-flex;
        }
    }
    &__description {
        font-size: rem(12);
        line-height: rem(17);
        margin-bottom: 0;
        &--white {
            padding: 0 !important;
            background-color: transparent !important;
            width: auto !important;
            display: inline-flex;
            color: white;
        }
        &--photographer {
            font-size: rem(14);
            line-height: rem(22);
            text-shadow: 0px 0px 3px #000000;
        }
    }
    &__photographer {
        font-size: rem(14);
        line-height: rem(22);
        color: white;
    }
    &__gradient {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        &:before {
            position: absolute;
            width: 100%;
            height: rem(720);
            left: 0;
            top: rem(-360);
            content: "";
            z-index: 1;
            background: rgb(96,181,211);
            background: linear-gradient(238deg, rgba(96,181,211,1) 0%, rgba(93,179,206,1) 36%, rgba(14,122,61,1) 90%, rgba(14,122,61,1) 100%);
        }
    }
    &__content {
        display: flex;
        flex-flow: column nowrap;
        padding: rem(32) rem(48) rem(38) rem(48);
        border-radius: rem(45) 0 rem(45) 0;
        background-color: white;
        margin-bottom: rem(50);
        &--product {
            box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.3);
            margin-top: rem(-160);
            margin-bottom: 0;
        }
        &--shadow {
            box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.3);
        }
    }
    &__absolute-content {
        position: absolute;
        top: rem(70);
        left: 0;
    }
    &__content-title {
        font-family: $playfair_display;
        font-size: rem(40);
        line-height: rem(42);
        font-weight: 900;
        color: black;
        margin-bottom: 0;
    }
    &__content-text {
        font-size: rem(18);
        line-height: rem(28);
        font-weight: 300;
        margin-top: rem(30);
        margin-bottom: 0;
        p {
            margin-bottom: 0;
        }
    }
    &__tags {
        margin-top: rem(35);
    }
    &__artwork-logo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        &:after {
            position: absolute;
            width: rem(1458);
            height: rem(1613);
            left: calc(50% - rem(45));
            transform: translateX(-50%);
            top: rem(-630);
            content: "";
            background: url("/public/svg/leaf-header.svg") center center / 100% no-repeat;
            mix-blend-mode: multiply;
        }
    }
    &__artwork-v3 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    &__artwork-v3-top {
        position: absolute;
        width: 100%;
        height: 0;
        padding-bottom: 75%;
        bottom: 62%;
        transform: translateY(50%);
        &:before {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            content: "";
            z-index: 2;
            background: url("/public/svg/header-artwork-v3-top.svg") center center / 100% no-repeat;
        }
    }
    &__gradient-behind-logo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: rgb(14,122,61);
        background: linear-gradient(80deg, rgba(14,122,61,1) 0%, rgba(84,173,189,1) 100%);
    }
    &__artwork-inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        &:before {
            position: absolute;
            width: rem(786);
            height: rem(877);
            top: rem(-622);
            right: 49%;
            content: "";
            z-index: 2;
            background: url("/public/svg/article-artwork-left.svg") center center / 100% no-repeat;
        }
        &:after {
            position: absolute;
            width: rem(786);
            height: rem(877);
            left: 56%;
            top: rem(67);
            content: "";
            z-index: 2;
            background: url("/public/svg/article-artwork-right.svg") center center / 100% no-repeat;
        }
        &--in-products {
            display: none;
        }
    }
    &__table {
        display: flex;
        flex-flow: column nowrap;
        margin-top: rem(25);
        &--large {
            #{$self}__table-tr {
                margin-bottom: rem(26);
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
            #{$self}__table-td-right {
                padding-top: 0;
                p {
                    font-size: rem(18);
                    line-height: rem(26);
                    font-weight: 300;
                    margin-bottom: 0;
                }
                a {
                    font-size: rem(18);
                    line-height: rem(26);
                    font-weight: 300;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    &__table-tr {
        display: flex;
        flex-flow: row nowrap;
        &--inner {
            max-width: rem(240);
            justify-content: space-between;
            margin-bottom: 0 !important;
            p {
                &:last-of-type {
                    min-width: rem(130);
                    text-align: left;
                }
            }
        }
    }
    &__table-td-left {
        display: flex;
        width: rem(150);
        font-size: rem(18);
        line-height: rem(26);
        font-weight: 600;
    }
    &__table-td-right {
        display: inline;
        width: 100%;
        padding-top: rem(3);
        font-size: rem(14);
        line-height: rem(24);
        margin-bottom: 0;
        strong {
            font-weight: 700;
        }
        a {
            font-weight: 700;
            text-decoration: underline;
            &:hover {
                u {
                    text-decoration: none;
                }
            }
        }
    }
    &__table-anchor {
        font-size: rem(28) !important;
        line-height: rem(38) !important;
        font-weight: 700 !important;
    }
    &__more {
        font-size: rem(18);
        line-height: rem(26);
        margin-top: rem(18);
        font-weight: 700;
        &:hover {
            text-decoration: none;
        }
    }
    &__bottom {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
    }
    &__buttons {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-end;
        margin-top: rem(50);
        a {
            margin-top: rem(17);
            margin-right: rem(17);
            &:first-of-type {
                margin-top: 0;
            }
        }
    }
    &__logos {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
        align-items: flex-end;
        &--horizontal {
            flex-flow: row nowrap;
            margin-left: auto;
            margin-top: rem(20);
            #{$self}__logo {
                margin-left: rem(40);
            }
        }
    }
    &__logo {
        width: auto;
        margin-top: rem(15);
        max-height: rem(77);
        max-width: rem(180);
        img {
            max-height: rem(77);
            max-width: rem(180);
        }
        &:first-of-type {
            margin-top: 0;
        }
    }
    &__gradient-2 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% - rem(80));
        height: rem(530);
        overflow: hidden;
        background: rgb(14,122,61);
        background: linear-gradient(45deg, rgba(14,122,61,1) 0%, rgba(94,179,207,1) 80%, rgba(96,181,211,1) 100%);
        &:before {
            position: absolute;
            bottom: rem(186);
            right: 19%;
            width: rem(805);
            height: rem(928);
            content: "";
            background: url("/public/svg/product-artwork-center.svg") center center / 100% no-repeat;
        }
        &:after {
            position: absolute;
            bottom: rem(-690);
            right: -17%;
            width: rem(805);
            height: rem(928);
            content: "";
            background: url("/public/svg/product-artwork-center.svg") center center / 100% no-repeat;
        }
    }
    &--artwork {
        justify-content: flex-start;
        align-items: flex-start;
        height: rem(230);
        > .container {
            z-index: 2;
        }
    }
    &--narrower {
        height: rem(230);
    }
    &--product-detail,
    &--contact-special {
        flex-flow: column nowrap;
        height: auto;
        padding-top: rem(90);
        #{$self}__content {
            margin-bottom: 0;
            width: 100%;
            max-width: rem(680);
        }
    }
    &--contact-special {
        height: rem(610);
        #{$self}__image-container {
            height: calc(100% - rem(80));
        }
    }
    &--narrow {
        height: rem(430);
        padding: 0;
    }
    &--content-offset {
        height: rem(580);
        #{$self}__image-container {
            height: rem(530);
        }
        #{$self}__content {
            margin-bottom: 0;
        }
        &#{$self}--narrow {
            height: rem(480);
            #{$self}__image-container {
                height: rem(430);
            }
        }
    }
    @include media-breakpoint-down(xxl) {
        padding: rem(20) rem(40) rem(20) rem(20);
        &__gradient-2 {
            &:before {
                right: 30%;
            }
        }
        &--narrow {
            padding: 0;
        }
        &--artwork {
            height: rem(230);
        }
        &--product-detail,
        &--contact-special {
            padding: rem(90) 0 0 0;
        }
    }
    @include media-breakpoint-down(xl) {
        height: rem(500);
        &__content {
            padding: rem(26) rem(30) rem(30) rem(30);
        }
        &__content-title {
            font-size: rem(31);
            line-height: rem(33);
        }
        &__content-text {
            font-size: rem(15);
            line-height: rem(24);
            margin-top: rem(15);
        }
        &--narrow {
            height: rem(400);
            padding: 0;
        }
        &--artwork {
            height: rem(230);
        }
        &--product-detail,
        &--contact-special {
            height: auto;
            padding-top: rem(90);
        }
        &--narrower {
            height: rem(230);
        }
        &--content-offset {
            height: rem(550);
            #{$self}__image-container {
                height: rem(500);
            }
            &#{$self}--narrow {
                height: rem(450);
                #{$self}__image-container {
                    height: rem(400);
                }
            }
        }
    }
    @include media-breakpoint-down(lg) {
        height: rem(404);
        padding: rem(20);
        &__description-container,
        figcaption {
            padding: rem(9) rem(16);
            right: rem(20);
            bottom: rem(20);
            &--white {
                right: rem(10);
                bottom: rem(10);
            }
        }
        &__description {
            font-size: rem(11);
            line-height: rem(14);
        }
        &__content {
            padding: rem(20);
            border-radius: rem(20) 0 rem(20) 0;
            &--product {
                margin-top: 0;
                margin-bottom: 0;
                border-radius: rem(20) 0 rem(20) 0;
            }
            &--no-photographer {
                margin-bottom: rem(20);
            }
        }
        &__content-title {
            font-size: rem(24);
            line-height: rem(26);
        }
        &__content-text {
            font-size: rem(14);
            line-height: rem(24);
            margin-top: rem(15);
        }
        &__tags {
            margin-top: rem(20);
        }
        &__artwork-inner {
            &--in-products {
                display: flex;
            }
        }
        &__gradient-2 {
            height: 100%;
            background: rgb(40,140,108);
            background: linear-gradient(10deg, rgba(40,140,108,1) 0%, rgba(71,162,166,1) 100%);
            &:before {
                right: auto;
                left: 0;
                transform: translateX(-60%);
            }
            &:after {
                right: 0;
                transform: translateX(60%);
            }
        }
        &__artwork-logo {
            &:after {
                width: rem(1000);
            }
        }
        &--artwork {
            height: rem(200);
            &--product {
                display: none;
            }
            &--with-cta {
                padding: 0;
            }
        }
        &--product-detail,
        &--contact-special {
            height: auto;
            padding: 0 0 rem(20) 0;
            .container {
                order: 2;
            }
            #{$self}__content {
                margin-top: rem(20);
                border-radius: rem(20) 0 rem(20) 0;
            }
            #{$self}__image-container {
                height: 100%;
            }
        }
        &--narrower {
            height: rem(230);
        }
        //&--content-offset {
        //    margin-bottom: 0;
        //    #{$self}__content {
        //        margin-bottom: rem(50);
        //        transform: translateY(0);
        //        &--no-photographer {
        //            margin-bottom: rem(20);
        //        }
        //    }
        //}
        &--content-offset {
            height: rem(404);
            #{$self}__image-container {
                height: 100%;
            }
            #{$self}__content {
                margin-bottom: rem(50);
                transform: translateY(0);
                &--no-photographer {
                    margin-bottom: rem(20);
                }
            }
            &#{$self}--narrow {
                height: rem(400);
                #{$self}__image-container {
                    height: 100%;
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        padding: 0;
        &__description-container,
        figcaption {
            max-width: calc(100% - rem(40));
        }
        &__content {
            margin-bottom: rem(60);
            &--product {
                margin-top: 0;
                margin-bottom: 0;
                border-radius: rem(20) 0 rem(20) 0;
            }
            &--no-photographer {
                margin-bottom: rem(20);
            }
        }
        &__content-title {
            font-size: rem(24);
            line-height: rem(26);
        }
        &__content-text {
            margin-top: rem(12);
            font-size: rem(11);
            line-height: rem(14);
        }
        &__table {
            margin-top: rem(15);
            margin-bottom: rem(10);
            &--large {
                #{$self}__table-td-right {
                    font-size: rem(11);
                    line-height: rem(16);
                    font-weight: 300;
                    p {
                        font-size: rem(11);
                        line-height: rem(16);
                        font-weight: 300;
                    }
                    strong {
                        font-weight: 700;
                    }
                    a {
                        font-size: rem(11);
                        line-height: rem(16);
                    }
                }
            }
        }
        &__table-tr {
            &--inner {
                max-width: rem(150);
                p {
                    &:last-of-type {
                        min-width: rem(85);
                    }
                }
            }
        }
        &__table-anchor {
            font-size: rem(14) !important;
            line-height: rem(20) !important;
        }
        &__table-td-left {
            font-size: rem(11);
            line-height: rem(16);
            width: rem(80);
        }
        &__table-td-right {
            padding-top: 0;
            font-size: rem(11);
            line-height: rem(16);
            font-weight: 300;
            strong {
                font-weight: 700;
            }
        }
        &__bottom {
            flex-flow: column nowrap;
        }
        &__buttons {
            order: 2;
            margin-top: rem(16);
            //justify-content: space-between;
            a {
                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
        &__more {
            font-size: rem(11);
            margin-top: 0;
        }
        &__logos {
            order: 1;
            flex-flow: row wrap;
            align-items: center;
            margin-top: rem(10);
            &--horizontal {
                align-items: flex-end;
                #{$self}__logo {
                    margin-left: rem(10);
                    margin-top: rem(10);
                }
            }
        }
        &__logo {
            max-width: rem(110);
            max-height: rem(45);
            margin-left: rem(13);
            margin-top: 0;
            img {
                max-width: 100%;
                max-height: rem(45);
            }
        }
        &--artwork {
            &--with-cta {
                display: flex;
                flex-flow: column nowrap;
                height: auto;
                #{$self}__artwork-inner {
                    position: relative;
                    height: rem(200);
                    left: auto;
                    top: auto;
                    display: flex;
                    order: 2;
                }
            }
        }
        &--product-detail,
        &--contact-special {
            padding-bottom: rem(20);
        }
        &--artwork-v3 {
            height: auto;
            padding: rem(20) 0;
            #{$self}__content {
                margin-bottom: 0;
            }
        }
        &--narrow {
            height: rem(200);
        }
        &--content-offset {
            height: rem(400);
        }
    }
    @include media-breakpoint-down(sm) {
        &--artwork {
            height: rem(86);
            &:before {
                transform: scale(.6);
                right: -4%;
                top: rem(-702);
            }
            &:after {
                transform: scale(.6);
                left: -11%;
                top: rem(-166);
            }
            #{$self}__artwork-inner {
                &:before {
                    transform: scale(0.3);
                    right: -27%;
                    top: rem(-510);
                }
                &:after {
                    transform: scale(0.3);
                    left: -16%;
                    top: rem(-287);
                }
            }
            &--with-cta {
                display: flex;
                flex-flow: column nowrap;
                height: auto;
                #{$self}__artwork-inner {
                    position: relative;
                    height: rem(86);
                    left: auto;
                    top: auto;
                    display: flex;
                    order: 2;
                }
            }
        }
        &__artwork-logo {
            &:after {
                width: rem(532);
                left: 49%;
                top: rem(-644);
            }
        }
    }
    @media (max-width: 767px) and (orientation: landscape) {
        height: auto;
        min-height: rem(180);
        max-height: rem(404);
        &--contact-special,
        &--product-detail {
            max-height: 100%;
        }
        &--artwork {
            &--with-cta {
                max-height: rem(200);
            }
        }
    }
}