@charset "UTF-8";
@import url("https://use.typekit.net/vri4qwn.css");
@import 'vendor/swiper-bundle-min.css';
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0E7A3D;
  --bs-secondary: #C95100;
  --bs-primary-rgb: 14, 122, 61;
  --bs-secondary-rgb: 201, 81, 0;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #0d6efd;
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h6, h5, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3 {
    font-size: 1.75rem;
  }
}

h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4 {
    font-size: 1.5rem;
  }
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}
a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 2.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1220px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1220px;
  }
}
.row {
  --bs-gutter-x: 2.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #bacbe6;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.375rem;
}
.form-control-color::-webkit-color-swatch {
  border-radius: 0.375rem;
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.form-floating):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback),
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.375rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.375rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #1F1F1F;
  --bs-backdrop-opacity: 0.85;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(var(--bs-modal-header-padding-y) * -0.5) calc(var(--bs-modal-header-padding-x) * -0.5) calc(var(--bs-modal-header-padding-y) * -0.5) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
.modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
.modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
.modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
.modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
.modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: 0.375rem;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(14, 122, 61, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(201, 81, 0, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #0E7A3D !important;
}
.link-primary:hover, .link-primary:focus {
  color: #0b6231 !important;
}

.link-secondary {
  color: #C95100 !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: #a14100 !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
/* playfair-display-regular - latin */
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(""), url("/public/fonts/playfair-display-v25-latin-regular.woff2") format("woff2"), url("/public/fonts/playfair-display-v25-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* playfair-display-500 - latin */
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local(""), url("/public/fonts/playfair-display-v25-latin-500.woff2") format("woff2"), url("/public/fonts/playfair-display-v25-latin-500.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* playfair-display-600 - latin */
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(""), url("/public/fonts/playfair-display-v25-latin-600.woff2") format("woff2"), url("/public/fonts/playfair-display-v25-latin-600.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* playfair-display-700 - latin */
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(""), url("/public/fonts/playfair-display-v25-latin-700.woff2") format("woff2"), url("/public/fonts/playfair-display-v25-latin-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* playfair-display-800italic - latin */
@font-face {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 800;
  src: local(""), url("/public/fonts/playfair-display-v28-latin-800italic.woff2") format("woff2"), url("/public/fonts/playfair-display-v28-latin-800italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* playfair-display-900 - latin */
@font-face {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local(""), url("/public/fonts/playfair-display-v25-latin-900.woff2") format("woff2"), url("/public/fonts/playfair-display-v25-latin-900.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
body {
  font-family: "interstate", sans-serif;
  color: #000000;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
  height: auto;
}

.main {
  width: 100%;
}

.placeholder {
  position: relative;
  display: flex;
  width: 100%;
  height: 56.25rem;
  background-color: #EBECEC;
}

.skiplink {
  position: absolute !important;
  top: 1.25rem;
  left: 1.25rem;
  z-index: 0;
  opacity: 0;
}
.skiplink:focus-visible {
  z-index: 1000;
  opacity: 1;
}
@media (max-width: 575.98px) {
  .skiplink {
    top: 0.1875rem;
    left: 0.625rem;
  }
}

.scroll-to-point {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: -5rem;
  left: 0;
  width: 0.0625rem;
  height: 0.0625rem;
}
@media (max-width: 991.98px) {
  .scroll-to-point {
    top: -2.875rem;
  }
}

a:focus-visible, button:focus-visible {
  position: relative;
  outline: 0;
  border: 0;
}
a:focus-visible:before, button:focus-visible:before {
  position: absolute;
  left: calc(-0rem / 2);
  top: calc(-0rem / 2);
  width: calc(100% + 0rem);
  height: calc(100% + 0rem);
  outline: 0.1875rem solid #646464;
  -moz-outline-radius: 0.625rem;
  border-radius: 0.625rem;
  content: "";
}
@media (max-width: 1199.98px) {
  a:focus-visible:before, button:focus-visible:before {
    outline-width: 0.1875rem;
  }
}

a:not(.button) {
  color: black;
}

p a:not(.button):hover {
  text-decoration: none;
}
p a:not(.button):focus-visible {
  outline: 0.1875rem solid #646464;
  border-radius: 0.3125rem;
}
p a:not(.button):focus-visible:before {
  display: none;
}
p a:not(.button)[target=_blank]:after, p a:not(.button).external:after {
  display: inline-flex;
  width: 1rem;
  height: 1rem;
  background: url("/public/svg/link-new-tab.svg") center center/100% no-repeat;
  content: "";
  margin-left: 0.375rem;
  transform: translateY(0.125rem);
}
p b, p strong {
  font-weight: 700;
}

hr {
  background-color: #707070;
  opacity: 1;
  margin: 2.375rem 0;
}
@media (max-width: 767.98px) {
  hr {
    margin: 1rem 0;
  }
}

dt, dd {
  margin-bottom: 0;
}

.table {
  border-color: #707070;
}
.table th, .table td {
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: 300;
  padding: 1.25rem 1.75rem;
}
.table th:first-of-type, .table td:first-of-type {
  padding-left: 0;
  border-left: 0;
}
.table th:last-of-type, .table td:last-of-type {
  border-right: 0;
}
.table th strong, .table th b, .table td strong, .table td b {
  font-weight: 700;
}
.table th {
  font-weight: 700;
}
.table th span {
  font-weight: 300;
}
.table-row-bordered td, .table-row-bordered th {
  border-bottom: 0;
}
.table-row-bordered tr {
  border-bottom: 0.0625rem solid #707070;
}
.table-row-bordered tr:last-of-type {
  border-bottom: 0;
}
.table.productinfo {
  margin-bottom: 0;
}
.table.productinfo caption {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.table.productinfo th, .table.productinfo td {
  padding: 0 1.25rem 0.625rem 0;
}
.table.productinfo th {
  padding-left: 0;
}
.table.productinfo td {
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 500;
  padding-top: 0.1875rem;
}
.table.productinfo td p, .table.productinfo td a, .table.productinfo td ul li, .table.productinfo td ol li {
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 500;
}
.table.productinfo td h1, .table.productinfo td h2, .table.productinfo td h3, .table.productinfo td h4, .table.productinfo td h5, .table.productinfo td h6 {
  font-weight: 700;
}
.table.productinfo td p {
  margin-bottom: 0;
}
.table.productinfo td ul {
  padding-left: 0.875rem;
  margin-bottom: 0.625rem;
}
.table.productinfo td ol {
  padding-left: 1.25rem;
  margin-bottom: 0.625rem;
}
@media (max-width: 767.98px) {
  .table th, .table td {
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 0.625rem 1.25rem;
  }
  .table-responsive {
    width: calc(100% + 2.5rem);
    transform: translateX(-1.25rem);
    padding: 0 0 0 1.25rem;
    position: relative;
  }
  .table-responsive:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 5.25rem;
    height: 5.25rem;
    content: "";
    background: url("/public/svg/table-scroll.svg") center center/100% no-repeat;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    pointer-events: none;
  }
  .table-responsive--scrolled:after {
    opacity: 0;
    visibility: hidden;
  }
  .table-responsive table {
    min-width: 48.125rem;
    margin-bottom: 0;
  }
  .table.productinfo th, .table.productinfo td {
    padding: 0 0.625rem 0.625rem 0;
  }
  .table.productinfo th {
    font-size: 0.6875rem;
    line-height: 1rem;
    font-weight: 600;
  }
  .table.productinfo td {
    font-size: 0.6875rem;
    line-height: 1rem;
    font-weight: 300;
    padding-top: 0.0625rem;
  }
  .table.productinfo td p, .table.productinfo td a, .table.productinfo td ul li, .table.productinfo td ol li {
    font-size: 0.6875rem;
    line-height: 1rem;
    font-weight: 300;
  }
  .table.productinfo td h1, .table.productinfo td h2, .table.productinfo td h3, .table.productinfo td h4, .table.productinfo td h5, .table.productinfo td h6 {
    font-weight: 600;
  }
  .table.productinfo td p {
    margin-bottom: 0;
  }
  .table.productinfo td ol, .table.productinfo td ul {
    margin-bottom: 0;
  }
}
@media (max-width: 575.98px) {
  .table-responsive table {
    min-width: 36.25rem;
  }
}
.editor h1, .editor h2, .editor h3, .editor h4, .editor h5, .editor h6 {
  font-family: "interstate", sans-serif;
  font-weight: 700;
  line-height: 1;
  padding-top: 1.5rem;
}
.editor h1 a, .editor h2 a, .editor h3 a, .editor h4 a, .editor h5 a, .editor h6 a {
  display: flex;
  margin-top: -1.5rem;
}
.editor h1 a:hover, .editor h2 a:hover, .editor h3 a:hover, .editor h4 a:hover, .editor h5 a:hover, .editor h6 a:hover {
  text-decoration: none;
}
.editor h2 {
  font-size: 1.9375rem;
  margin-bottom: 2.1875rem;
}
.editor h3 {
  font-size: 1.5625rem;
  margin-bottom: 1.625rem;
}
.editor h4 {
  font-size: 1.3125rem;
  margin-bottom: 1.4375rem;
}
.editor h5 {
  font-size: 1.25rem;
  margin-bottom: 1.25rem;
}
.editor h6 {
  font-size: 1.1875rem;
  margin-bottom: 1.25rem;
}
.editor p, .editor ul li, .editor ol li {
  font-size: 1.125rem;
  line-height: 1.875rem;
  font-weight: 300;
  margin-bottom: 1.875rem;
}
.editor p.editor__intro, .editor ul li.editor__intro, .editor ol li.editor__intro {
  font-family: "Playfair Display";
  font-size: 1.5rem;
  line-height: 2.1875rem;
  color: black;
  margin-bottom: 3.1875rem;
  font-weight: 400;
  margin-top: 4.625rem;
}
.editor p.editor__intro--interstate, .editor ul li.editor__intro--interstate, .editor ol li.editor__intro--interstate {
  font-family: "interstate", sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.9375rem;
}
.editor p.editor__intro--no-margin-top, .editor ul li.editor__intro--no-margin-top, .editor ol li.editor__intro--no-margin-top {
  margin-top: 0;
}
.editor ul, .editor ol {
  padding-left: 1.25rem;
}
.editor ul li, .editor ol li {
  margin-bottom: 0;
}
.editor ul.editor__calendar-items, .editor ol.editor__calendar-items {
  padding-left: 0;
}
.editor img {
  border-radius: 0.9375rem;
  margin-bottom: 2.8125rem;
}
.editor form,
.editor .form {
  margin-bottom: 1.875rem;
}
.editor__default-title {
  font-family: "Playfair Display" !important;
  font-size: 2.5rem;
  line-height: 1.25 !important;
  font-weight: 900 !important;
  padding-top: 4rem !important;
  margin-bottom: 4.375rem !important;
}
.editor__default-title--small {
  margin-bottom: 1.25rem !important;
}
.editor__bold-link {
  font-weight: 900;
  font-size: 1.1875rem;
}
.editor__bold-link:hover {
  text-decoration: none;
}
.editor__wide-image {
  position: relative;
  display: flex;
  padding-top: 3rem;
  padding-bottom: 4.4375rem;
}
.editor__wide-image img {
  border-radius: 0.9375rem;
  margin-bottom: 0rem;
}
.editor__wide-image figcaption,
.editor__wide-image .editor__caption {
  font-size: 0.75rem !important;
  line-height: 1.1875rem !important;
  color: black;
  margin-top: 1.875rem;
  margin-bottom: 0 !important;
  max-width: 44.5625rem;
}
.editor__wide-image--video .editor__image-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}
.editor__wide-image--video .editor__image-container--hide {
  opacity: 0;
  visibility: hidden;
}
.editor__wide-image--video .editor__image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.editor__wide-image--video--full-width {
  padding-top: 3.75rem;
  padding-bottom: 5.9375rem;
}
.editor__wide-image--video--full-width .editor__video-container {
  border-radius: 0;
  padding-bottom: 48%;
}
.editor__video-container {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  border-radius: 0.9375rem;
  overflow: hidden;
}
.editor__video-container .editor__play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.5);
}
.editor__video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.editor__video-player iframe {
  width: 100%;
  height: 100%;
}
.editor__description {
  font-size: 0.75rem !important;
  line-height: 1.1875rem !important;
  color: black;
  margin-top: 1.875rem;
  margin-bottom: 0 !important;
  max-width: 44.5625rem;
}
.editor__white-content-block {
  border-radius: 4.6875rem 0 4.6875rem 0;
  padding: 3.75rem;
  padding-right: 10.5%;
  padding-left: 10.5%;
  background-color: white;
  width: 100%;
  margin: 5rem 0;
}
.editor__white-content-block h1, .editor__white-content-block h2, .editor__white-content-block h3, .editor__white-content-block h4, .editor__white-content-block h5, .editor__white-content-block h6 {
  padding-top: 0;
}
.editor__white-content-block--orange {
  background-color: #FACBAA;
}
.editor__lime-content-block {
  border-radius: 4.6875rem 0 4.6875rem 0;
  padding: 3.75rem 6.25rem;
  background-color: #C9D32C;
  width: 100%;
  margin: 2.5rem 0 5rem;
}
.editor__lime-content-block h1, .editor__lime-content-block h2, .editor__lime-content-block h3, .editor__lime-content-block h4, .editor__lime-content-block h5, .editor__lime-content-block h6 {
  padding-top: 0;
}
.editor__items {
  display: flex;
  flex-flow: column nowrap;
  padding-left: 0 !important;
  margin-bottom: 0 !important;
  list-style-type: none;
}
.editor__items li {
  position: relative;
  padding-bottom: 1.9375rem;
}
.editor__items li:after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "—";
}
.editor__items li:before {
  display: none;
}
.editor__items li:last-child {
  padding-bottom: 0;
}
.editor__items li:last-child:after {
  display: none;
}
.editor__items h1, .editor__items h2, .editor__items h3, .editor__items h4, .editor__items h5, .editor__items h6, .editor__items p, .editor__items a {
  padding-top: 0;
  margin-bottom: 0;
  line-height: 1.9375rem;
}
.editor__calendar-items {
  display: flex;
  flex-flow: column nowrap;
  padding-left: 0;
  margin-bottom: 0 !important;
  list-style-type: none;
}
.editor__calendar-items li {
  position: relative;
  border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.5);
}
.editor__calendar-items li:first-child {
  border-top: 0.0625rem solid rgba(0, 0, 0, 0.5);
}
.editor__calendar-items li:before {
  display: none;
}
.editor__calendar-item {
  text-decoration: none;
  display: flex;
  flex-flow: column nowrap;
  padding: 1.25rem 1.25rem 1.25rem 0;
}
.editor__calendar-item h4 {
  order: 2;
  font-size: 1.5rem;
  line-height: 1.875rem;
  margin-bottom: 0;
}
.editor__calendar-item p {
  font-weight: 700;
  order: 1;
  font-size: 1.0625rem;
  line-height: 1.875rem;
}
.editor__calendar-item:hover .editor__calendar-item-chevron {
  background-color: black;
}
.editor__calendar-item:hover .editor__calendar-item-chevron svg path {
  fill: white;
}
.editor__calendar-item-chevron {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  bottom: 1.375rem;
  width: 1.625rem;
  height: 1.625rem;
  border-radius: 50%;
  transform: translateX(0.375rem);
}
.editor__calendar-item-chevron svg {
  transform: translateX(0.0625rem) scale(1.2);
}
.editor__featured {
  position: relative;
  display: flex;
  padding: 3.125rem 3.75rem 1.4375rem;
  background-color: #BCDBC9;
  border-radius: 1.25rem;
  overflow: hidden;
}
.editor__featured:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% - 3.75rem);
  height: 59.375rem;
  transform: translate(-50%, -50%);
  content: "";
  opacity: 0.3;
  background: url("/public/svg/leaf-white.svg") center center/100% no-repeat;
}
.editor__featured > div {
  position: relative;
}
.editor__featured .editor__featured-title {
  font-size: 1.75rem;
  line-height: 2.25rem;
  padding-top: 0;
  font-weight: 500;
}
.editor__featured .a-col {
  padding: 0;
}
.editor__link-list {
  display: flex;
  flex-flow: column nowrap;
  padding-left: 0 !important;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 0.0625rem solid #707070;
  margin-bottom: 0 !important;
  list-style-type: none;
}
.editor__link-list li {
  font-size: 1.3125rem;
  line-height: 1.9375rem;
  font-weight: 700;
}
.editor__link-list li:before {
  display: none;
}
.editor__link-list li a {
  font-size: 1.3125rem;
  line-height: 1.9375rem;
  font-weight: 700;
  text-decoration: none;
}
.editor__link-list li a:hover {
  text-decoration: underline;
}
.editor__link-list li span {
  font-size: 0.9375rem;
  font-weight: 300;
  padding-left: 0.3125rem;
}
.editor__link-list li span:before {
  content: "/";
  padding-right: 0.3125rem;
}
.editor__link-list:first-of-type {
  padding-top: 0;
}
.editor__link-list:last-of-type {
  border-bottom: 0;
}
.editor__vertical-list {
  display: flex;
  flex-flow: column nowrap;
  padding-left: 0 !important;
}
.editor__vertical-list li {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1.625rem !important;
}
.editor__vertical-list li:before {
  display: none;
}
.editor__vertical-list p {
  font-size: 0.875rem;
  line-height: 1.625rem;
  font-weight: 300;
  margin-bottom: 0;
}
.editor__vertical-list p:first-of-type {
  font-weight: 700;
}
.editor__vertical-list p a {
  text-decoration: none;
}
.editor__vertical-list p a:hover {
  text-decoration: underline;
}
.editor__vertical-list a {
  font-size: 1.3125rem;
  line-height: 1.625rem;
  font-weight: 700;
  text-decoration: none;
}
.editor__vertical-list a:hover {
  text-decoration: underline;
}
.editor__mobile-navigation {
  display: none;
}
.editor__small-note {
  display: none;
}
.editor__alert {
  font-weight: 700 !important;
  color: #C95100;
}
.editor__narrow-title {
  max-width: calc(100% - 3.125rem);
}
.editor--featured {
  margin-top: 4.375rem;
  margin-bottom: 6rem;
}
.editor--small-paragraph p, .editor--small-paragraph ul li, .editor--small-paragraph ol li {
  font-size: 0.9375rem;
  line-height: 1.625rem;
}
.editor--small-paragraph table th, .editor--small-paragraph table td {
  font-size: 0.875rem;
}
.editor--alt-headings h1, .editor--alt-headings h2, .editor--alt-headings h3, .editor--alt-headings h4, .editor--alt-headings h5, .editor--alt-headings h6 {
  line-height: 1.3;
  padding-top: 0;
  margin-bottom: 0;
}
.editor--alt-headings h1 a, .editor--alt-headings h2 a, .editor--alt-headings h3 a, .editor--alt-headings h4 a, .editor--alt-headings h5 a, .editor--alt-headings h6 a {
  margin-top: 0;
}
.editor--playfair-headings h1, .editor--playfair-headings h2, .editor--playfair-headings h3, .editor--playfair-headings h4, .editor--playfair-headings h5, .editor--playfair-headings h6 {
  font-family: "Playfair Display";
  font-weight: 900;
}
.editor--sidebar p, .editor--sidebar ul li, .editor--sidebar ol li {
  font-size: 1rem;
  line-height: 1.4375rem;
  font-weight: 500;
}
.editor--orange h1, .editor--orange h2, .editor--orange h3, .editor--orange h4, .editor--orange h5, .editor--orange h6 {
  padding-top: 0;
  margin-bottom: 0.625rem;
  color: #C95100;
}
.editor p + .accordion {
  padding-top: 1.25rem;
}
@media (max-width: 1765px) {
  .editor__wide-image {
    padding-top: 3.75rem;
  }
}
@media (max-width: 1199.98px) {
  .editor__description {
    max-width: 35.625rem;
  }
  .editor__wide-image figcaption,
.editor__wide-image .editor__caption {
    max-width: 35.625rem;
  }
  .editor__featured {
    padding: 1.25rem 1.875rem 0.1875rem;
  }
  .editor__featured .editor__featured-title {
    font-size: 1.375rem;
    line-height: 1.875rem;
  }
  .editor__lime-content-block {
    padding: 3.75rem 5rem;
  }
  .editor__calendar-item {
    padding: 1rem 1rem 1rem 0;
  }
  .editor__calendar-item h4 {
    font-size: 1.25rem;
    line-height: 1.625rem;
  }
  .editor__calendar-item p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .editor p.editor__intro, .editor ul li.editor__intro, .editor ol li.editor__intro {
    margin-top: 3.125rem;
  }
  .editor p.editor__intro--no-margin-top, .editor ul li.editor__intro--no-margin-top, .editor ol li.editor__intro--no-margin-top {
    margin-top: 0;
  }
  .editor--featured {
    margin-top: 1.875rem;
    margin-bottom: 3.5rem;
  }
}
@media (max-width: 991.98px) {
  .editor p.editor__intro, .editor ul li.editor__intro, .editor ol li.editor__intro {
    margin-top: 2.5rem;
  }
  .editor p {
    margin-bottom: 1.5rem;
  }
  .editor p.editor__intro {
    margin-bottom: 3.75rem;
    margin-top: 2.5rem;
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
  .editor p.editor__intro--no-margin-top {
    margin-top: 0;
  }
  .editor form,
.editor .form {
    margin-bottom: 1.5rem;
  }
  .editor__wide-image {
    padding-bottom: 5rem;
  }
  .editor__wide-image figcaption,
.editor__wide-image .editor__caption {
    max-width: 25rem;
  }
  .editor__description {
    max-width: 25rem;
  }
  .editor__video-container .editor__play-icon {
    transform: translate(-50%, -50%);
  }
  .editor__white-content-block {
    padding: 2.5rem;
    margin: 2.5rem 0;
  }
  .editor__lime-content-block {
    padding: 3.75rem;
    margin: 1.875rem 0 3.75rem;
  }
  .editor__mobile-navigation {
    display: flex;
    margin: 2.5rem 0 1.25rem;
    width: calc(100% + 1.875rem);
    transform: translateX(-0.9375rem);
  }
  .editor__mobile-navigation ul {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-left: 0 !important;
    width: 100%;
    margin-bottom: 0;
    list-style-type: none;
  }
  .editor__mobile-navigation ul li {
    font-size: 1.125rem !important;
    line-height: 1.625rem !important;
    font-weight: 700;
  }
  .editor__mobile-navigation ul li:before {
    display: none;
  }
  .editor__mobile-navigation__arrow {
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    background: url("/public/svg/chevron-left-black.svg") center center/0.5625rem no-repeat;
  }
  .editor__mobile-navigation__arrow--next {
    transform: rotate(180deg);
  }
}
@media (max-width: 767.98px) {
  .editor h2, .editor h3, .editor h4, .editor h5, .editor h6 {
    line-height: 1.3;
  }
  .editor h2 {
    font-size: 1.125rem;
    margin-bottom: 1.5625rem;
  }
  .editor h3 {
    font-size: 1rem;
    margin-bottom: 1.5625rem;
  }
  .editor h4 {
    font-size: 0.9375rem;
    margin-bottom: 1.5625rem;
  }
  .editor h5 {
    font-size: 0.875rem;
    margin-bottom: 1.5625rem;
  }
  .editor h6 {
    font-size: 0.875rem;
    margin-bottom: 1.5625rem;
  }
  .editor p, .editor ul li, .editor ol li {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
  .editor p.editor__intro, .editor ul li.editor__intro, .editor ol li.editor__intro {
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin-bottom: 2.5rem;
    margin-top: 1.875rem;
  }
  .editor p.editor__intro--no-margin-top, .editor ul li.editor__intro--no-margin-top, .editor ol li.editor__intro--no-margin-top {
    margin-top: 0;
  }
  .editor ul, .editor ol {
    padding-left: 0.625rem;
  }
  .editor ul li:before, .editor ol li:before {
    left: -0.625rem;
  }
  .editor__wide-image {
    padding-top: 1.25rem;
    padding-bottom: 1.5rem;
  }
  .editor__wide-image figcaption,
.editor__wide-image .editor__caption {
    font-size: 0.6875rem !important;
    line-height: 1rem !important;
    margin-top: 1.25rem;
  }
  .editor__wide-image--video--full-width {
    padding-top: 1.25rem;
    padding-bottom: 1.5625rem;
  }
  .editor__wide-image--video--full-width .editor__video-container {
    padding-bottom: 81%;
  }
  .editor__description {
    font-size: 0.6875rem !important;
    line-height: 1rem !important;
    margin-top: 1.25rem;
  }
  .editor__video-container .editor__play-icon {
    transform: translate(-50%, -50%) scale(0.7);
  }
  .editor__items {
    padding-left: 0 !important;
  }
  .editor__items li {
    padding-bottom: 1.375rem;
  }
  .editor__items h1, .editor__items h2, .editor__items h3, .editor__items h4, .editor__items h5, .editor__items h6, .editor__items p, .editor__items a {
    margin-bottom: 0;
  }
  .editor__white-content-block {
    border-radius: 3.125rem 0 3.125rem 0;
    padding: 2.5rem 1.875rem;
    margin: 1.25rem 0;
  }
  .editor__white-content-block--mobile-full-width {
    width: calc(100% + 2.5rem);
    transform: translateX(-1.25rem);
  }
  .editor__lime-content-block {
    border-radius: 2.8125rem 0 2.8125rem 0;
    padding: 2.8125rem 1.875rem;
  }
  .editor__lime-content-block .col-12:not(:first-of-type) .editor__calendar-items li:first-child {
    border-top-color: transparent;
  }
  .editor__calendar-items {
    padding-left: 0 !important;
  }
  .editor__calendar-item {
    padding: 0.625rem 0.625rem 0.625rem 0;
  }
  .editor__calendar-item h4 {
    font-size: 1.125rem;
    line-height: 1.625rem;
    margin-bottom: 0;
  }
  .editor__calendar-item p {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
  .editor__calendar-item-chevron {
    bottom: 0.8125rem;
    width: 1.1875rem;
    height: 1.1875rem;
  }
  .editor__calendar-item-chevron svg {
    transform: translateX(0.0625rem) scale(0.85);
  }
  .editor__featured {
    padding: 1.875rem 1.875rem 1.25rem 1.875rem;
  }
  .editor__featured:before {
    width: 100%;
    background-size: 150%;
  }
  .editor__default-title {
    padding-top: 1.5rem !important;
    margin-bottom: 2.25rem !important;
    font-size: 1.5rem;
  }
  .editor__default-title--small {
    margin-bottom: 0.5rem !important;
  }
  .editor__link-list {
    padding-left: 0 !important;
  }
  .editor__link-list li {
    font-size: 1.125rem;
    line-height: 1.4375rem;
    margin-bottom: 1.25rem !important;
  }
  .editor__link-list li:last-child {
    margin-bottom: 0 !important;
  }
  .editor__link-list li a {
    display: flex;
    flex-flow: column nowrap;
    font-size: 1.125rem;
    line-height: 1.4375rem;
    text-decoration: underline;
  }
  .editor__link-list li span {
    padding-left: 0;
  }
  .editor__link-list li span:before {
    display: none;
  }
  .editor__vertical-list {
    padding-left: 0 !important;
    margin-bottom: 0 !important;
  }
  .editor__vertical-list a {
    text-decoration: underline;
  }
  .editor__mobile-navigation {
    margin: 1rem 0;
  }
  .editor__mobile-navigation--bottom {
    margin-bottom: 2.5rem;
  }
  .editor__small-note {
    display: flex;
    font-size: 0.625rem !important;
    line-height: 1rem;
    font-style: italic;
    color: #767676;
    font-weight: 300;
    margin: 0.625rem 0;
  }
  .editor--featured {
    margin-top: 1.25rem;
    margin-bottom: 2.5rem;
  }
  .editor--alt-headings h1, .editor--alt-headings h2, .editor--alt-headings h3, .editor--alt-headings h4, .editor--alt-headings h5, .editor--alt-headings h6 {
    margin-bottom: 0.5rem;
  }
  .editor--playfair-headings h1 {
    font-size: 1.5rem;
  }
  .editor--orange h1, .editor--orange h2, .editor--orange h3, .editor--orange h4, .editor--orange h5, .editor--orange h6 {
    padding-top: 0;
    margin-bottom: 0.375rem;
  }
}

.editor__default-title + .editor__intro--interstate {
  margin-top: -1.875rem !important;
}
@media (max-width: 767.98px) {
  .editor__default-title + .editor__intro--interstate {
    margin-top: -0.625rem !important;
  }
}

.bg__pr-orange {
  background-color: #C95100 !important;
}
.bg__pr-lime {
  background-color: #C9D32C !important;
}
.bg__pr-green {
  background-color: #0E7A3D !important;
}
.bg__se-orange {
  background-color: #FACBAA !important;
}
.bg__se-orange-light {
  background-color: #FDEFE5 !important;
}
.bg__se-grey {
  background-color: #EBECEC !important;
}
.bg__se-green-light {
  background-color: #64AA78 !important;
}
.bg__se-green-lighter {
  background-color: #E5F1EB !important;
}
.bg__se-blue-light {
  background-color: #D8E5EF !important;
}
.bg__se-blue {
  background-color: #2A3957 !important;
}
.bg__se-blue-dark {
  background-color: #0F1D37 !important;
}
.bg__se-focus {
  background-color: #646464 !important;
}

.text__white {
  color: white !important;
}
.text__black {
  color: black !important;
}

input[type=submit] {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2.625rem;
  padding: 0 3.125rem 0 1.5rem;
  font-family: "interstate", sans-serif;
  font-weight: 700;
  font-size: 0.9375rem;
  line-height: 1;
  border: 0;
  border-radius: 1.3125rem;
  color: white;
  text-decoration: none !important;
  background-color: #C95100;
  transition: background-color 0.2s ease, color 0.2s ease, border-color 0.2s ease;
  white-space: nowrap;
  cursor: pointer;
  cursor: pointer;
}
input[type=submit]:after {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.625rem;
  height: 1.625rem;
  background: url("/public/svg/button-chevron-white.svg") top left/100% no-repeat;
  content: "";
}
input[type=submit]:hover, input[type=submit]:focus-visible {
  background-color: #0E7A3D;
  color: white;
}
input[type=submit]:hover:before, input[type=submit]:focus-visible:before {
  display: none;
}
input[type=submit]:disabled {
  pointer-events: none;
  opacity: 0.5;
}
@media (max-width: 575.98px) {
  input[type=submit] {
    height: 1.875rem;
    padding: 0 1.875rem 0 0.9375rem;
    font-size: 0.6875rem;
    border-radius: 0.9375rem;
  }
  input[type=submit]:after {
    top: 0.3125rem;
    right: 0.3125rem;
    width: 1.25rem;
    height: 1.25rem;
  }
}

.button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2.625rem;
  padding: 0 3.125rem 0 1.5rem;
  font-family: "interstate", sans-serif;
  font-weight: 700;
  font-size: 0.9375rem;
  line-height: 1;
  border: 0;
  border-radius: 1.3125rem;
  color: white;
  text-decoration: none !important;
  background-color: #C95100;
  transition: background-color 0.2s ease, color 0.2s ease, border-color 0.2s ease;
  white-space: nowrap;
  cursor: pointer;
}
.button:after {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.625rem;
  height: 1.625rem;
  background: url("/public/svg/button-chevron-white.svg") top left/100% no-repeat;
  content: "";
}
.button:hover, .button:focus-visible {
  background-color: #0E7A3D;
  color: white;
}
.button:hover:before, .button:focus-visible:before {
  display: none;
}
.button:disabled {
  pointer-events: none;
  opacity: 0.5;
}
@media (max-width: 575.98px) {
  .button {
    height: 1.875rem;
    padding: 0 1.875rem 0 0.9375rem;
    font-size: 0.6875rem;
    border-radius: 0.9375rem;
  }
  .button:after {
    top: 0.3125rem;
    right: 0.3125rem;
    width: 1.25rem;
    height: 1.25rem;
  }
}
.button--no-arrow {
  padding: 0 1.5rem;
}
.button--no-arrow:after {
  display: none;
}
.button--arrow-left {
  padding: 0 1.5rem 0 3.125rem;
}
.button--arrow-left:after {
  right: 0;
  left: 0.5rem;
  transform: rotate(180deg);
}
.button--ghost {
  background-color: transparent;
  border: 0.0625rem solid #C95100;
  color: #C95100;
}
.button--ghost:after {
  background-image: url("/public/svg/button-chevron-orange.svg");
  transition: background-image 0.2s ease;
  top: 0.4375rem;
}
.button--ghost:hover {
  background-color: #0E7A3D;
  border-color: #0E7A3D;
}
.button--ghost:hover:after {
  background-image: url("/public/svg/button-chevron-white.svg");
}
.button--info {
  background-color: #646464;
  color: white;
}
.button--info:before {
  display: block;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.625rem;
  height: 1.625rem;
  background-color: white;
  border-radius: 50%;
  content: "" !important;
  transition: background-color 0.2s ease, border 0.2s ease;
  border: 0.0625rem solid white;
}
.button--info:after {
  font-family: "Playfair Display";
  font-size: 1.375rem;
  font-style: italic;
  font-weight: 800;
  background-image: none;
  color: #C95100;
  content: "i";
  right: -0.0625rem;
  top: 0.5625rem;
  transition: color 0.2s ease;
}
.button--info:hover {
  background-color: #646464;
  color: white;
}
.button--info:hover:before {
  display: block;
  content: "";
  background-color: #0E7A3D;
}
.button--info:hover:after {
  color: white;
}
.button--info--ghost {
  border: 0.0625rem solid #646464;
  background-color: transparent;
  color: #646464;
}
.button--info--ghost:before {
  border-color: #646464;
}
.button--info--ghost:hover {
  background-color: white;
  color: #646464;
}
.button--info--ghost:hover:before {
  border-color: #0E7A3D;
}
@media (max-width: 575.98px) {
  .button--info:before {
    width: 1.25rem;
    height: 1.25rem;
    top: 0.3125rem;
    right: 0.3125rem;
  }
  .button--info:after {
    font-size: 16px;
    top: 0.375rem;
    right: -0.125rem;
  }
  .button--info--ghost:before {
    top: 0.25rem;
  }
  .button--ghost:after {
    top: 0.25rem;
    right: 0.25rem;
  }
  .button--no-arrow {
    padding: 0 0.9375rem;
  }
  .button--arrow-left {
    padding: 0 0.9375rem 0 1.875rem;
  }
  .button--arrow-left:after {
    right: 0;
    top: 0.3125rem;
    left: 0.3125rem;
  }
  .button--arrow-left.button--ghost:after {
    top: 0.25rem !important;
    left: 0.25rem !important;
  }
}

.close-button {
  height: 2.1875rem;
  padding: 0 3.4375rem 0 0;
  border: 0;
  display: flex;
  align-items: center;
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 2.1875rem;
  color: #323232;
  opacity: 0;
  transition: opacity 0.3s ease;
  background: transparent url("/public/svg/top-nav-close.svg") top right/2.1875rem no-repeat;
}
.close-button:focus-visible {
  position: absolute;
  outline: 0;
  border: 0;
}
.close-button:focus-visible:before {
  position: absolute;
  left: calc(-0rem / 2);
  top: calc(-0rem / 2);
  width: calc(100% + 0rem);
  height: calc(100% + 0rem);
  outline: 0.1875rem solid #646464;
  -moz-outline-radius: 0.625rem;
  border-radius: 0.625rem;
  content: "";
}
@media (max-width: 1199.98px) {
  .close-button:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
.close-button:hover span {
  text-decoration: underline;
}
@media (max-width: 1199.98px) {
  .close-button {
    height: 1.4375rem;
    padding: 0 2.5rem 0 0;
    background: transparent url("/public/svg/top-nav-close.svg") top right/1.4375rem no-repeat;
  }
}

.form__field,
.form fieldset,
form__field,
form fieldset {
  position: relative;
  padding-top: 3.125rem;
}
.form__field legend,
.form fieldset legend,
form__field legend,
form fieldset legend {
  font-size: 1.3125rem;
  line-height: 1.625rem;
  font-weight: 700;
  margin-bottom: 0.625rem;
}
.form__field > label,
.form fieldset > label,
form__field > label,
form fieldset > label {
  position: absolute;
  bottom: 1rem;
  left: 0.875rem;
  font-size: 1.1875rem;
  margin-bottom: 0;
  transition: all 0.3s ease;
}
.form__field--textarea .form__info-button,
.form fieldset--textarea .form__info-button,
form__field--textarea .form__info-button,
form fieldset--textarea .form__info-button {
  bottom: auto;
  top: 3.25rem;
}
.form__field-error,
form__field-error {
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  font-size: 1.0625rem;
  line-height: 1.5rem;
  color: #C95100;
  font-weight: 700;
}
.form__info-button,
form__info-button {
  position: absolute;
  bottom: 0.375rem;
  right: 0.375rem;
  width: 3.125rem;
  height: 3.125rem;
  appearance: none;
  border: 0;
  padding: 0;
  background: url("/public/svg/icons/info-orange.svg") center center/0.5rem no-repeat;
}
.form-control:focus-visible,
form-control:focus-visible {
  outline: 0.1875rem solid #646464;
  box-shadow: 0 0 0 0;
  border-color: #646464;
}
.form-control:focus,
form-control:focus {
  outline: 0;
}
@media (max-width: 575.98px) {
  .form-control:focus-visible,
form-control:focus-visible {
    outline-width: 0.1875rem;
  }
}
.form-check,
form-check {
  display: flex;
  margin-bottom: 0;
  min-height: 0;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 2rem;
}
.form-check-inline,
form-check-inline {
  display: inline-flex;
}
.form-check-input,
form-check-input {
  flex-shrink: 0;
  width: 2.125rem;
  height: 2.125rem;
  border-radius: 0.3125rem !important;
  border: 0.125rem solid #C95100;
  background-color: white;
  margin-top: 0;
  outline: 0rem solid white !important;
  box-shadow: 0px 0px 0px 7px #FFFFFF !important;
  appearance: none;
}
.form-check-input:focus, .form-check-input:focus-visible,
form-check-input:focus,
form-check-input:focus-visible {
  box-shadow: 0px 0px 0px 7px rgba(201, 81, 0, 0.25) !important;
  border-color: #C95100;
}
.form-check-input:checked,
form-check-input:checked {
  background-color: #C95100;
  border-color: #C95100;
  background-image: none !important;
}
.form-check-input[type=radio]:focus, .form-check-input[type=radio]:focus-visible,
form-check-input[type=radio]:focus,
form-check-input[type=radio]:focus-visible {
  box-shadow: 0px 0px 0px 7px rgba(201, 81, 0, 0.25) !important;
}
.form-check-label,
form-check-label {
  padding-left: 1.625rem;
  padding-top: 0.3125rem;
}
@media (max-width: 575.98px) {
  .form input[type=file],
form input[type=file] {
    font-size: 0.875rem;
  }
}
.form input[type=file]::file-selector-button,
form input[type=file]::file-selector-button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2.625rem;
  padding: 0 1.5rem 0 1.5rem;
  font-family: "interstate", sans-serif;
  font-weight: 700;
  font-size: 0.9375rem;
  line-height: 1;
  border: 0;
  border-radius: 1.3125rem;
  color: white;
  text-decoration: none !important;
  background-color: #C95100;
  transition: background-color 0.2s ease, color 0.2s ease, border-color 0.2s ease;
  white-space: nowrap;
  cursor: pointer;
  margin-right: 0.625rem;
}
.form input[type=file]::file-selector-button:hover, .form input[type=file]::file-selector-button:focus-visible,
form input[type=file]::file-selector-button:hover,
form input[type=file]::file-selector-button:focus-visible {
  background-color: #0E7A3D;
  color: white;
}
.form input[type=file]::file-selector-button:hover:before, .form input[type=file]::file-selector-button:focus-visible:before,
form input[type=file]::file-selector-button:hover:before,
form input[type=file]::file-selector-button:focus-visible:before {
  display: none;
}
@media (max-width: 575.98px) {
  .form input[type=file]::file-selector-button,
form input[type=file]::file-selector-button {
    height: 1.875rem;
    padding: 0 0.9375rem 0 0.9375rem;
    font-size: 0.6875rem;
    border-radius: 0.9375rem;
  }
  .form input[type=file]::file-selector-button:after,
form input[type=file]::file-selector-button:after {
    top: 0.3125rem;
    right: 0.3125rem;
    width: 1.25rem;
    height: 1.25rem;
  }
}
.form input[type=file]::-ms-browse,
form input[type=file]::-ms-browse {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2.625rem;
  padding: 0 1.5rem 0 1.5rem;
  font-family: "interstate", sans-serif;
  font-weight: 700;
  font-size: 0.9375rem;
  line-height: 1;
  border: 0;
  border-radius: 1.3125rem;
  color: white;
  text-decoration: none !important;
  background-color: #C95100;
  transition: background-color 0.2s ease, color 0.2s ease, border-color 0.2s ease;
  white-space: nowrap;
  cursor: pointer;
  margin-right: 0.625rem;
}
.form input[type=file]::-ms-browse:hover, .form input[type=file]::-ms-browse:focus-visible,
form input[type=file]::-ms-browse:hover,
form input[type=file]::-ms-browse:focus-visible {
  background-color: #0E7A3D;
  color: white;
}
.form input[type=file]::-ms-browse:hover:before, .form input[type=file]::-ms-browse:focus-visible:before,
form input[type=file]::-ms-browse:hover:before,
form input[type=file]::-ms-browse:focus-visible:before {
  display: none;
}
@media (max-width: 575.98px) {
  .form input[type=file]::-ms-browse,
form input[type=file]::-ms-browse {
    height: 1.875rem;
    padding: 0 0.9375rem 0 0.9375rem;
    font-size: 0.6875rem;
    border-radius: 0.9375rem;
  }
  .form input[type=file]::-ms-browse:after,
form input[type=file]::-ms-browse:after {
    top: 0.3125rem;
    right: 0.3125rem;
    width: 1.25rem;
    height: 1.25rem;
  }
}
.form__field,
form__field {
  padding-top: 2.875rem;
}
.form__field > input[type=text],
.form__field > input[type=textarea],
.form__field > input[type=email],
.form__field > input[type=password],
.form__field > input[type=search],
.form__field > input[type=url],
.form__field > input[type=number],
.form__field > input[type=tel],
.form__field select,
.form__field textarea,
form__field > input[type=text],
form__field > input[type=textarea],
form__field > input[type=email],
form__field > input[type=password],
form__field > input[type=search],
form__field > input[type=url],
form__field > input[type=number],
form__field > input[type=tel],
form__field select,
form__field textarea {
  height: 3.875rem;
  font-size: 1.4375rem;
  line-height: 1.75rem;
  color: black;
  font-weight: 500;
  padding: 0 3.75rem 0 1.6875rem;
  border-radius: 0.625rem;
  border: 0.15625rem solid #868686;
  width: 100%;
  background-color: white;
}
.form__field > input[type=text]:focus-visible,
.form__field > input[type=textarea]:focus-visible,
.form__field > input[type=email]:focus-visible,
.form__field > input[type=password]:focus-visible,
.form__field > input[type=search]:focus-visible,
.form__field > input[type=url]:focus-visible,
.form__field > input[type=number]:focus-visible,
.form__field > input[type=tel]:focus-visible,
.form__field select:focus-visible,
.form__field textarea:focus-visible,
form__field > input[type=text]:focus-visible,
form__field > input[type=textarea]:focus-visible,
form__field > input[type=email]:focus-visible,
form__field > input[type=password]:focus-visible,
form__field > input[type=search]:focus-visible,
form__field > input[type=url]:focus-visible,
form__field > input[type=number]:focus-visible,
form__field > input[type=tel]:focus-visible,
form__field select:focus-visible,
form__field textarea:focus-visible {
  outline: 0.0625rem solid #868686;
}
.form__field > input[type=text]:focus,
.form__field > input[type=textarea]:focus,
.form__field > input[type=email]:focus,
.form__field > input[type=password]:focus,
.form__field > input[type=search]:focus,
.form__field > input[type=url]:focus,
.form__field > input[type=number]:focus,
.form__field > input[type=tel]:focus,
.form__field select:focus,
.form__field textarea:focus,
form__field > input[type=text]:focus,
form__field > input[type=textarea]:focus,
form__field > input[type=email]:focus,
form__field > input[type=password]:focus,
form__field > input[type=search]:focus,
form__field > input[type=url]:focus,
form__field > input[type=number]:focus,
form__field > input[type=tel]:focus,
form__field select:focus,
form__field textarea:focus {
  outline: 0;
}
.form__field > input[type=text]:disabled, .form__field > input[type=text][readonly],
.form__field > input[type=textarea]:disabled,
.form__field > input[type=textarea][readonly],
.form__field > input[type=email]:disabled,
.form__field > input[type=email][readonly],
.form__field > input[type=password]:disabled,
.form__field > input[type=password][readonly],
.form__field > input[type=search]:disabled,
.form__field > input[type=search][readonly],
.form__field > input[type=url]:disabled,
.form__field > input[type=url][readonly],
.form__field > input[type=number]:disabled,
.form__field > input[type=number][readonly],
.form__field > input[type=tel]:disabled,
.form__field > input[type=tel][readonly],
.form__field select:disabled,
.form__field select[readonly],
.form__field textarea:disabled,
.form__field textarea[readonly],
form__field > input[type=text]:disabled,
form__field > input[type=text][readonly],
form__field > input[type=textarea]:disabled,
form__field > input[type=textarea][readonly],
form__field > input[type=email]:disabled,
form__field > input[type=email][readonly],
form__field > input[type=password]:disabled,
form__field > input[type=password][readonly],
form__field > input[type=search]:disabled,
form__field > input[type=search][readonly],
form__field > input[type=url]:disabled,
form__field > input[type=url][readonly],
form__field > input[type=number]:disabled,
form__field > input[type=number][readonly],
form__field > input[type=tel]:disabled,
form__field > input[type=tel][readonly],
form__field select:disabled,
form__field select[readonly],
form__field textarea:disabled,
form__field textarea[readonly] {
  background-color: transparent;
}
.form__field > input[type=text][aria-invalid=true],
.form__field > input[type=textarea][aria-invalid=true],
.form__field > input[type=email][aria-invalid=true],
.form__field > input[type=password][aria-invalid=true],
.form__field > input[type=search][aria-invalid=true],
.form__field > input[type=url][aria-invalid=true],
.form__field > input[type=number][aria-invalid=true],
.form__field > input[type=tel][aria-invalid=true],
.form__field select[aria-invalid=true],
.form__field textarea[aria-invalid=true],
form__field > input[type=text][aria-invalid=true],
form__field > input[type=textarea][aria-invalid=true],
form__field > input[type=email][aria-invalid=true],
form__field > input[type=password][aria-invalid=true],
form__field > input[type=search][aria-invalid=true],
form__field > input[type=url][aria-invalid=true],
form__field > input[type=number][aria-invalid=true],
form__field > input[type=tel][aria-invalid=true],
form__field select[aria-invalid=true],
form__field textarea[aria-invalid=true] {
  border: 0.375rem solid #C95100;
}
.form__field select,
form__field select {
  background-color: white;
  font-size: 1.0625rem;
  width: 100%;
  color: #767676;
  background: white url("/public/svg/chevron-down-black.svg") calc(100% - 1.0625rem) 1.5625rem/1rem no-repeat;
}
.form__field select[multiple],
form__field select[multiple] {
  height: auto;
  padding: 0.625rem 0;
}
.form__field select[multiple] option,
form__field select[multiple] option {
  padding: 0 0.625rem;
  min-height: 2rem;
  display: flex;
  align-items: center;
  font-size: 1.0625rem;
  line-height: 1.25rem;
  font-weight: 500;
}
.form__field select.form__select-large,
form__field select.form__select-large {
  font-size: 1.4375rem;
  color: black;
}
.form__field textarea,
form__field textarea {
  resize: none;
  height: 13.75rem;
  padding: 1.1875rem 3.75rem 1.1875rem 1.6875rem;
}
.form__field textarea + label,
form__field textarea + label {
  bottom: auto;
  top: 4.0625rem;
}
.form__field > input[type=range] + label,
form__field > input[type=range] + label {
  transform: translateX(-1.6875rem) translateY(-1.625rem);
  color: black;
}
.form__field > input[type=range]::-webkit-slider-thumb,
form__field > input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  background: #C95100;
  /* Green background */
}
.form__field > input[type=range]::-moz-range-thumb,
form__field > input[type=range]::-moz-range-thumb {
  background: #C95100;
  /* Green background */
}
.form__field > label,
form__field > label {
  font-size: 1.0625rem;
  left: 1.6875rem;
  bottom: 1.1875rem;
  white-space: nowrap;
  color: #767676;
}
.form__field--filled > label,
form__field--filled > label {
  transform: translateX(-1.6875rem) translateY(-2.875rem);
  font-size: 1.0625rem;
}
.form__field--range,
form__field--range {
  margin-top: 1.25rem;
}
.form__field--submit,
form__field--submit {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form__field--submit a,
form__field--submit a {
  text-decoration: none;
}
.form__field--submit a:hover,
form__field--submit a:hover {
  text-decoration: underline;
}
.form__field--submit a, .form__field--submit button,
form__field--submit a,
form__field--submit button {
  margin-bottom: 1rem;
}
.form__field--submit a + .button,
form__field--submit a + .button {
  margin-left: 1.6875rem;
}
.form--in-editor,
form--in-editor {
  position: relative;
  margin-bottom: 1.625rem;
}
.form--decision-tree fieldset,
form--decision-tree fieldset {
  padding: 1.25rem 0;
}
@media (max-width: 1199.98px) {
  .form__info-button,
form__info-button {
    width: 2.5rem;
    height: 2.5rem;
  }
  .form__field-error,
form__field-error {
    bottom: -1.1875rem;
    left: 0;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .form__field,
form__field {
    padding-top: 2.375rem;
  }
  .form__field > input[type=text],
.form__field > input[type=textarea],
.form__field > input[type=email],
.form__field > input[type=password],
.form__field > input[type=search],
.form__field > input[type=url],
.form__field > input[type=number],
.form__field > input[type=tel],
.form__field select,
.form__field textarea,
form__field > input[type=text],
form__field > input[type=textarea],
form__field > input[type=email],
form__field > input[type=password],
form__field > input[type=search],
form__field > input[type=url],
form__field > input[type=number],
form__field > input[type=tel],
form__field select,
form__field textarea {
    font-size: 1.125rem;
    line-height: 1.5rem;
    height: 3.25rem;
    padding: 0 3.75rem 0 1.0625rem;
  }
  .form__field > input[type=text][aria-invalid=true],
.form__field > input[type=textarea][aria-invalid=true],
.form__field > input[type=email][aria-invalid=true],
.form__field > input[type=password][aria-invalid=true],
.form__field > input[type=search][aria-invalid=true],
.form__field > input[type=url][aria-invalid=true],
.form__field > input[type=number][aria-invalid=true],
.form__field > input[type=tel][aria-invalid=true],
.form__field select[aria-invalid=true],
.form__field textarea[aria-invalid=true],
form__field > input[type=text][aria-invalid=true],
form__field > input[type=textarea][aria-invalid=true],
form__field > input[type=email][aria-invalid=true],
form__field > input[type=password][aria-invalid=true],
form__field > input[type=search][aria-invalid=true],
form__field > input[type=url][aria-invalid=true],
form__field > input[type=number][aria-invalid=true],
form__field > input[type=tel][aria-invalid=true],
form__field select[aria-invalid=true],
form__field textarea[aria-invalid=true] {
    border: 0.25rem solid #C95100;
  }
  .form__field select,
form__field select {
    font-size: 0.875rem;
    background-position: calc(100% - 1.0625rem) 1.3125rem;
  }
  .form__field textarea,
form__field textarea {
    height: 13.75rem;
    padding: 0.625rem 1.875rem 0.625rem 1.0625rem;
  }
  .form__field textarea + label,
form__field textarea + label {
    bottom: auto;
    top: 3.3125rem;
  }
  .form__field > input[type=range] + label,
form__field > input[type=range] + label {
    transform: translateX(-1.0625rem) translateY(-1.625rem);
  }
  .form__field > label,
form__field > label {
    bottom: 0.9375rem;
    left: 1.0625rem;
    font-size: 0.875rem;
  }
  .form__field--textarea .form__info-button,
form__field--textarea .form__info-button {
    bottom: auto;
    top: 2.75rem;
  }
  .form__field--filled > label,
form__field--filled > label {
    transform: translateX(-1.0625rem) translateY(-2.375rem);
    font-size: 0.875rem;
  }
}
@media (max-width: 991.98px) {
  .form fieldset,
form fieldset {
    padding-top: 1.875rem;
  }
  .form fieldset legend,
form fieldset legend {
    font-size: 1.0625rem;
    line-height: 1.375rem;
  }
  .form-check,
form-check {
    padding-left: 1.625rem;
  }
  .form-check-input,
form-check-input {
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 0.125rem !important;
    border: 0.125rem solid #C95100;
    box-shadow: 0px 0px 0px 4px #FFFFFF !important;
    appearance: none;
  }
  .form-check-input:focus,
form-check-input:focus {
    border-color: #C95100;
  }
  .form-check-input:checked,
form-check-input:checked {
    background-color: #C95100;
    border-color: #C95100;
    background-image: none !important;
  }
  .form-check-label,
form-check-label {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    padding-top: 0;
    padding-left: 0.875rem;
  }
  .form__info-button,
form__info-button {
    bottom: 0;
    right: 0;
    background-size: 0.375rem;
  }
  .form__field-error,
form__field-error {
    bottom: -1.0625rem;
    left: 0;
    font-size: 0.625rem;
  }
  .form__field,
form__field {
    padding-top: 1.6875rem;
  }
  .form__field > input[type=text],
.form__field > input[type=textarea],
.form__field > input[type=email],
.form__field > input[type=password],
.form__field > input[type=search],
.form__field > input[type=url],
.form__field > input[type=number],
.form__field > input[type=tel],
.form__field select,
.form__field textarea,
form__field > input[type=text],
form__field > input[type=textarea],
form__field > input[type=email],
form__field > input[type=password],
form__field > input[type=search],
form__field > input[type=url],
form__field > input[type=number],
form__field > input[type=tel],
form__field select,
form__field textarea {
    height: 2.5rem;
    padding: 0 1.875rem 0 0.9375rem;
    border-width: 0.0625rem;
    border-radius: 0.3125rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .form__field select,
form__field select {
    font-size: 0.625rem;
    background-size: 0.625rem;
    background-position: calc(100% - 0.75rem) 1rem;
  }
  .form__field select[multiple],
form__field select[multiple] {
    height: auto;
    padding: 0.625rem 0 0.625rem 0.9375rem;
  }
  .form__field select[multiple] option,
form__field select[multiple] option {
    padding: 0 0.625rem;
    min-height: 1.25rem;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 500;
  }
  .form__field select.form__select-large,
form__field select.form__select-large {
    font-size: 0.875rem;
  }
  .form__field textarea,
form__field textarea {
    height: 11.25rem;
    padding: 0.625rem 1.875rem 0.625rem 1.0625rem;
  }
  .form__field textarea + label,
form__field textarea + label {
    bottom: auto;
    top: 2.25rem;
  }
  .form__field > input[type=range] + label,
form__field > input[type=range] + label {
    transform: translateX(-0.875rem) translateY(-1.625rem);
  }
  .form__field > label,
form__field > label {
    bottom: 0.8125rem;
    left: 0.9375rem;
    font-size: 0.625rem;
  }
  .form__field--filled > label,
form__field--filled > label {
    transform: translateX(-0.9375rem) translateY(-1.75rem);
    font-size: 0.625rem;
  }
  .form__field--textarea .form__info-button,
form__field--textarea .form__info-button {
    bottom: auto;
    top: 1.5rem;
  }
  .form__field--submit a,
form__field--submit a {
    font-size: 0.6875rem;
  }
  .form__field--submit a, .form__field--submit button,
form__field--submit a,
form__field--submit button {
    margin-bottom: 1rem;
  }
  .form__field--submit a + .button,
form__field--submit a + .button {
    margin-left: 1rem;
  }
  .form--decision-tree fieldset,
form--decision-tree fieldset {
    padding: 0.625rem 0;
  }
}

hr + .form__field--submit {
  padding-top: 0rem;
}

.tiles {
  padding-top: 2.5rem;
  padding-bottom: 1.25rem;
}
@media (max-width: 767.98px) {
  .tiles {
    padding-top: 0;
  }
}

.tile {
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  border-radius: 2.8125rem 0 2.8125rem 0;
  padding-bottom: 1rem;
  height: 100%;
}
.tile__top {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 1.25rem 2.5rem;
  min-height: 6.5rem;
  background: #198251;
  background: linear-gradient(30deg, #198251 0%, #60b5d3 100%);
}
.tile__title {
  font-family: "Playfair Display";
  font-size: 1.75rem;
  line-height: 2.125rem;
  font-weight: 900;
  color: white;
  margin-bottom: 0;
}
.tile__bottom {
  display: flex;
  flex-flow: column nowrap;
  padding: 1.75rem 2.5rem 2.5rem 2.5rem;
  background-color: #fff;
  flex-grow: 1;
  border-radius: 0 0 2.8125rem 0;
}
.tile p {
  font-size: 1rem;
  line-height: 1.4375rem;
  margin-bottom: 1.625rem;
  font-weight: 300;
}
.tile a {
  font-size: 1rem;
  line-height: 1.4375rem;
  font-weight: 700;
  text-decoration: none;
}
.tile a:hover {
  text-decoration: underline;
}
@media (max-width: 767.98px) {
  .tile {
    border-radius: 1.5625rem 0 1.5625rem 0;
    height: auto;
  }
  .tile__top {
    padding: 1.1875rem 1.5625rem;
    min-height: 3.75rem;
  }
  .tile__title {
    font-size: 1.0625rem;
    line-height: 1.6875rem;
  }
  .tile__bottom {
    padding: 1.25rem 1.5625rem;
    flex-grow: 0;
    border-radius: 0 0 1.5625rem 0;
  }
  .tile p {
    margin-bottom: 1.1875rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .tile a {
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-decoration: underline;
  }
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}
.header__inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
  padding: 0 1.25rem 0 1.25rem;
  background-color: white;
}
.header__inner:after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  content: "";
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.16);
}
.header__logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  width: 11.25rem;
}
.header__logo:focus-visible {
  position: absolute;
  outline: 0;
  border: 0;
}
.header__logo:focus-visible:before {
  position: absolute;
  left: calc(-1.875rem / 2);
  top: calc(-2.125rem / 2);
  width: calc(100% + 1.875rem);
  height: calc(100% + 2.125rem);
  outline: 0.1875rem solid #646464;
  -moz-outline-radius: 0.625rem;
  border-radius: 0.625rem;
  content: "";
}
@media (max-width: 1199.98px) {
  .header__logo:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
.header__logo-img {
  width: 100%;
}
.header__button-container {
  display: flex;
}
.header__menu-button {
  position: relative;
  display: flex;
  align-items: center;
  background: transparent;
  border: 0;
  padding: 0;
}
.header__menu-button[aria-expanded=true] .header__menu-button-icon span:first-of-type {
  transform: rotate(-45deg);
  top: 1.375rem;
  left: 0.375rem;
}
.header__menu-button[aria-expanded=true] .header__menu-button-icon span:nth-child(2) {
  opacity: 0;
  max-width: 2.375rem;
}
.header__menu-button[aria-expanded=true] .header__menu-button-icon span:last-of-type {
  transform: rotate(45deg);
  top: -0.375rem;
  left: 0.375rem;
}
.header__menu-button[aria-expanded=true] .header__menu-button-text {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}
.header__menu-button[aria-expanded=true] .header__menu-button-text--close {
  width: auto;
  height: auto;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s 0.2s ease, visibility 0.3s 0.2s ease;
}
.header__menu-button--search-mobile {
  display: none;
}
.header__menu-button:focus-visible {
  position: relative;
  outline: 0;
  border: 0;
}
.header__menu-button:focus-visible:before {
  position: absolute;
  left: calc(-1.875rem / 2);
  top: calc(-2.375rem / 2);
  width: calc(100% + 1.875rem);
  height: calc(100% + 2.375rem);
  outline: 0.1875rem solid #646464;
  -moz-outline-radius: 0.625rem;
  border-radius: 0.625rem;
  content: "";
}
@media (max-width: 1199.98px) {
  .header__menu-button:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
.header__menu-button:hover .header__menu-button-text {
  text-decoration: underline;
}
.header__menu-button-icon {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 2.375rem;
  height: 1.3125rem;
}
.header__menu-button-icon span {
  width: 100%;
  max-width: 2.375rem;
  height: 0.1875rem;
  background-color: #0E7A3D;
  transition: all 0.25s ease;
  transform-origin: left center;
}
.header__menu-button-icon span:first-of-type {
  position: absolute;
  top: 0;
  left: 0;
}
.header__menu-button-icon span:last-of-type {
  position: absolute;
  top: 1.1875rem;
  left: 0;
}
.header__menu-button-text {
  font-family: "interstate", sans-serif;
  font-size: 1.25rem;
  line-height: 2.25rem;
  margin-left: 0.75rem;
  font-weight: 500;
  color: #0E7A3D;
  transition: opacity 0.3s 0.2s ease, visibility 0.3s 0.2s ease;
}
.header__menu-button-text--close {
  display: flex;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  height: 0;
  width: 0;
  overflow: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  top: 0;
  right: auto;
  left: 2.375rem;
  transform: translateX(0);
}
.header__top-nav {
  display: flex;
  flex-flow: row nowrap;
  gap: 1.875rem;
}
.header__top-nav-link {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  text-decoration: none;
}
.header__top-nav-link:focus-visible {
  position: relative;
  outline: 0;
  border: 0;
}
.header__top-nav-link:focus-visible:before {
  position: absolute;
  left: calc(-1.625rem / 2);
  top: calc(-1.625rem / 2);
  width: calc(100% + 1.625rem);
  height: calc(100% + 1.625rem);
  outline: 0.1875rem solid #646464;
  -moz-outline-radius: 0.625rem;
  border-radius: 0.625rem;
  content: "";
}
@media (max-width: 1199.98px) {
  .header__top-nav-link:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
.header__top-nav-link:hover .header__top-nav-link-text {
  text-decoration: underline;
  text-decoration-thickness: 0.125rem;
}
.header__top-nav-link-icon {
  height: 1.9375rem;
  width: auto;
}
.header__top-nav-link-text {
  font-family: "interstate", sans-serif;
  font-size: 0.6875rem;
  line-height: 0.875rem;
  font-weight: 700;
  color: #0E7A3D;
  text-align: center;
  margin-top: 0.25rem;
}
.header > .container-fluid, .header > .container-sm, .header > .container-md, .header > .container-lg, .header > .container-xl, .header > .container-xxl {
  position: relative;
  z-index: 2;
}
@media (max-width: 991.98px) {
  .header__inner {
    height: 2.875rem;
    padding: 0 1rem 0 0.875rem;
  }
  .header__logo {
    position: absolute;
    width: 7.375rem;
    left: 50%;
    right: 1.25rem;
    transform: translateX(-50%) translateY(-50%);
  }
  .header__logo:focus-visible {
    position: absolute;
    outline: 0;
    border: 0;
  }
  .header__logo:focus-visible:before {
    position: absolute;
    left: calc(-1.875rem / 2);
    top: calc(-0.9375rem / 2);
    width: calc(100% + 1.875rem);
    height: calc(100% + 0.9375rem);
    outline: 0.1875rem solid #646464;
    -moz-outline-radius: 0.625rem;
    border-radius: 0.625rem;
    content: "";
  }
}
@media (max-width: 991.98px) and (max-width: 1199.98px) {
  .header__logo:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
@media (max-width: 991.98px) {
  .header__menu-button {
    position: relative;
    flex-flow: column nowrap;
    height: 2.875rem;
    width: 2.5rem;
    padding-top: 0.5625rem;
  }
  .header__menu-button--search-mobile {
    display: flex;
    margin-left: 1.375rem;
    padding-top: 0.5rem;
    transition: opacity 0.2s ease;
  }
  .header__menu-button--search-mobile--open {
    opacity: 0;
  }
  .header__menu-button--search-mobile .header__menu-button-icon {
    width: 1.0625rem;
    height: 1.0625rem;
    transform: translateY(0) !important;
  }
  .header__menu-button--search-mobile .header__menu-button-text {
    line-height: 1.125rem;
    opacity: 1 !important;
  }
  .header__menu-button:focus-visible {
    position: relative;
    outline: 0;
    border: 0;
  }
  .header__menu-button:focus-visible:before {
    position: absolute;
    left: calc(-0.625rem / 2);
    top: calc(-0rem / 2);
    width: calc(100% + 0.625rem);
    height: calc(100% + 0rem);
    outline: 0.1875rem solid #646464;
    -moz-outline-radius: 0.625rem;
    border-radius: 0.625rem;
    content: "";
  }
}
@media (max-width: 991.98px) and (max-width: 1199.98px) {
  .header__menu-button:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
@media (max-width: 991.98px) {
  .header__menu-button[aria-expanded=true] .header__menu-button-text {
    opacity: 0;
  }
  .header__menu-button[aria-expanded=true] .header__menu-button-text--close {
    visibility: visible;
    opacity: 1 !important;
  }
  .header__menu-button[aria-expanded=true] .header__menu-button-icon {
    transform: translateY(0.375rem);
  }
  .header__menu-button[aria-expanded=true] .header__menu-button-icon span:first-of-type {
    left: 0.125rem;
    top: 1rem;
    transform: rotate(-45deg);
  }
  .header__menu-button[aria-expanded=true] .header__menu-button-icon span:nth-of-type(2) {
    opacity: 0;
  }
  .header__menu-button[aria-expanded=true] .header__menu-button-icon span:last-of-type {
    left: 0.125rem;
    top: -0.125rem;
    transform: rotate(45deg);
  }
}
@media (max-width: 991.98px) {
  .header__menu-button-icon {
    width: 1.5625rem;
    height: 0.875rem;
    transition: transform 0.2s ease;
  }
  .header__menu-button-icon span {
    height: 0.125rem;
  }
  .header__menu-button-icon span:last-of-type {
    top: 0.75rem;
  }
}
@media (max-width: 991.98px) {
  .header__menu-button-text {
    font-size: 0.75rem;
    line-height: 1.25rem;
    margin-left: 0;
    transition: opacity 0.2s ease;
    opacity: 1;
  }
  .header__menu-button-text--close {
    font-size: 0.9375rem !important;
    line-height: 1.6875rem;
    visibility: hidden;
    opacity: 0 !important;
    top: 0.5625rem;
  }
}
@media (max-width: 991.98px) {
  .header__top-nav {
    transform: translateY(0.1rem);
  }
}
@media (max-width: 991.98px) {
  .header__top-nav-link--mobile-hidden {
    display: none;
  }
  .header__top-nav-link .header__top-nav-link-icon {
    width: 1.0625rem;
    height: 1.0625rem;
    transform: translateY(0) !important;
  }
  .header__top-nav-link .header__top-nav-link-text {
    font-size: 0.75rem;
    line-height: 1.125rem;
    margin-top: 0;
    font-weight: 500;
    opacity: 1 !important;
  }
  .header__top-nav-link:focus-visible {
    position: relative;
    outline: 0;
    border: 0;
  }
  .header__top-nav-link:focus-visible:before {
    position: absolute;
    left: calc(-0.625rem / 2);
    top: calc(-0.375rem / 2);
    width: calc(100% + 0.625rem);
    height: calc(100% + 0.375rem);
    outline: 0.1875rem solid #646464;
    -moz-outline-radius: 0.625rem;
    border-radius: 0.625rem;
    content: "";
  }
}
@media (max-width: 991.98px) and (max-width: 1199.98px) {
  .header__top-nav-link:focus-visible:before {
    outline-width: 0.1875rem;
  }
}

.search-modal__dialog {
  margin-top: 8.125rem;
  max-width: 36.875rem;
  margin-right: calc((100vw - 76.25rem) / 2);
}
.search-modal__content {
  position: relative;
  border-radius: 3.125rem 0 3.125rem 0;
  background-color: white;
  padding: 0.625rem 1.875rem 6.25rem;
  border: 0;
}
.search-modal__body {
  padding: 0.0625rem;
}
.search-modal__top-row {
  margin-bottom: 2.75rem;
}
.search-modal__form {
  position: relative;
}
.search-modal__form input[type=search] {
  font-size: 1.1875rem;
  line-height: 1.625rem;
  color: black;
  font-weight: 500;
  padding: 1rem 8.75rem 1rem 0.875rem;
  border: 0.0625rem solid #868686;
  border-radius: 0.5rem;
  height: 3.75rem;
}
.search-modal__form-label {
  font-size: 1.1875rem;
  color: #767676;
  font-weight: 500;
}
.search-modal__form-submit {
  position: absolute !important;
  right: 0.5625rem;
  bottom: 0.5625rem;
}
.search-modal__all {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 0.75rem;
}
.search-modal__close-button {
  position: absolute;
  right: 2.5rem;
  bottom: 3rem;
  opacity: 1;
}
.search-modal__inner-col {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1.5rem;
}
.search-modal__col-title {
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.5rem;
  margin-bottom: 1.5rem;
}
.search-modal__list {
  display: flex;
  flex-flow: column nowrap;
  padding-left: 0;
  margin-bottom: 0;
}
.search-modal__li {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  border-bottom: 0.0625rem solid rgba(15, 29, 55, 0.3);
}
.search-modal__li:last-child {
  border-bottom-color: transparent;
}
.search-modal__li:hover .search-modal__list-item {
  color: #0E7A3D;
  font-weight: 600;
}
.search-modal__li:hover .search-modal__list-item-chevron {
  background-color: #0E7A3D;
}
.search-modal__li:hover .search-modal__list-item-chevron svg path {
  fill: white;
}
.search-modal__list-item {
  font-size: 1rem;
  line-height: 1.625rem;
  padding: 0.375rem 0;
  color: #323232;
  font-weight: 500;
  text-decoration: none;
  width: 100%;
}
.search-modal__list-item:focus-visible {
  position: relative;
  outline: 0;
  border: 0;
}
.search-modal__list-item:focus-visible:before {
  top: 0;
}
.search-modal__list-item:focus-visible:before {
  position: absolute;
  left: calc(-1.875rem / 2);
  top: calc(-0rem / 2);
  width: calc(100% + 1.875rem);
  height: calc(100% + 0rem);
  outline: 0.1875rem solid #646464;
  -moz-outline-radius: 2.5rem;
  border-radius: 2.5rem;
  content: "";
}
@media (max-width: 1199.98px) {
  .search-modal__list-item:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
.search-modal__list-item-chevron {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  right: 0;
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 50%;
  transform: translateX(0.375rem) translateY(-50%);
}
.search-modal__list-item-chevron svg {
  transform: translateX(0.0625rem);
}
@media (max-width: 1550px) {
  .search-modal__dialog {
    margin-right: 1.25rem;
  }
}
@media (max-width: 1399.98px) {
  .search-modal__dialog {
    margin: 4.375rem 1.25rem 0 auto;
  }
  .search-modal__content {
    padding: 1.875rem 1.25rem 6.25rem;
  }
  .search-modal__close-button {
    right: 2.5rem;
  }
  .search-modal__list-item {
    font-size: 1.125rem;
    line-height: 1.625rem;
    padding: 0.25rem 0;
  }
}
@media (max-width: 1199.98px) {
  .search-modal__content {
    padding: 1.875rem 1.25rem 3.75rem;
  }
  .search-modal__top-row {
    margin-bottom: 1.25rem;
  }
  .search-modal__col-title {
    font-size: 1.375rem;
    line-height: 1.75rem;
    margin-bottom: 1rem;
  }
  .search-modal__form-label {
    font-size: 1.0625rem !important;
    left: 1.6875rem !important;
    bottom: 1.1875rem !important;
  }
  .search-modal .form__field--filled > label {
    transform: translateX(-1.6875rem) translateY(-2.875rem);
    font-size: 1.0625rem;
  }
}
@media (max-width: 991.98px) {
  .search-modal__content {
    padding: 1.875rem 1.25rem 3.75rem;
  }
  .search-modal__form {
    margin-bottom: 1.625rem;
  }
  .search-modal__top-row {
    margin-bottom: 0;
  }
  .search-modal__inner-col {
    margin-bottom: 1.5rem;
  }
}
@media (max-width: 767.98px) {
  .search-modal__dialog {
    margin: 4.125rem 1.25rem 0 1.25rem;
  }
  .search-modal__top-row div[class^=col-] {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .search-modal__content {
    border-radius: 1.25rem 0 1.25rem;
    padding: 1.875rem 0 4.375rem;
  }
  .search-modal__body {
    padding: 0;
  }
  .search-modal__inner-col {
    margin-bottom: 1rem;
  }
  .search-modal__col-title {
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-bottom: 0.375rem;
  }
  .search-modal__list-item {
    font-size: 0.9375rem;
    line-height: 1.25rem;
    padding: 0.3125rem 0;
  }
  .search-modal__close-button {
    font-size: 0.875rem;
    right: 1.25rem;
    bottom: 2.25rem;
    padding-right: 1.875rem;
    background: transparent url("/public/svg/close-small.svg") top right/1.4375rem no-repeat;
  }
  .search-modal__form input[type=search] {
    padding: 0.5rem 0.9375rem;
    font-size: 1rem;
    line-height: 1.375rem;
    border-radius: 0.3125rem;
    height: 2.5rem;
  }
  .search-modal__form-label {
    font-size: 0.6875rem !important;
    line-height: 1.125rem !important;
    margin-bottom: 0 !important;
    bottom: 0.75rem !important;
    left: 0.9375rem !important;
  }
  .search-modal .form__field--filled > label {
    transform: translateX(-0.9375rem) translateY(-1.75rem);
  }
  .search-modal__form-submit {
    bottom: 0.3125rem;
    right: 0.3125rem;
    height: 1.875rem;
    padding: 0 2rem 0 0.75rem;
    font-size: 0.6875rem;
    border-radius: 1.25rem;
  }
  .search-modal__form-submit:after {
    top: 0.3125rem;
    right: 0.3125rem;
    width: 1.25rem;
    height: 1.25rem;
  }
}

.footer {
  position: relative;
  display: flex;
  padding: 2.5rem 0 3.75rem;
  margin-top: 6.25rem;
  overflow: hidden;
  border-radius: 6.25rem 0 0 0;
  color: white;
}
.footer:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: #0e7a3d;
  background: linear-gradient(185deg, #0e7a3d 0%, #1a4e44 76%, #1e4146 100%);
}
.footer > .container {
  position: relative;
}
.footer a {
  color: white;
}
.footer a:before {
  outline-color: white !important;
}
.footer__top-row {
  padding-bottom: 2rem;
}
.footer__socials {
  display: inline-flex;
  align-items: center;
}
.footer__socials-text {
  font-size: 1.0625rem;
  line-height: 1.5rem;
  color: white;
}
.footer__socials-list {
  display: flex;
  flex-flow: row nowrap;
  padding-left: 0;
  margin-bottom: 0;
  margin-left: 0.6875rem;
  list-style-type: none;
}
.footer__socials-list-item .footer__socials-link:before {
  outline-color: #E5F1EB !important;
}
.footer__socials-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 0.5rem;
  border-radius: 50%;
}
.footer__socials-link:focus-visible {
  position: relative;
  outline: 0;
  border: 0;
}
.footer__socials-link:focus-visible:before {
  position: absolute;
  left: calc(-0.625rem / 2);
  top: calc(-0.625rem / 2);
  width: calc(100% + 0.625rem);
  height: calc(100% + 0.625rem);
  outline: 0.1875rem solid #646464;
  -moz-outline-radius: 50%;
  border-radius: 50%;
  content: "";
}
@media (max-width: 1199.98px) {
  .footer__socials-link:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
.footer__socials-link:hover {
  opacity: 0.8;
}
.footer__socials-link:hover:before {
  opacity: 0.8;
}
.footer__socials-link--instagram {
  background: url("/public/svg/footer-ig.svg") center center/100% no-repeat;
}
.footer__socials-link--twitter {
  background: url("/public/svg/footer-tw.svg") center center/100% no-repeat;
}
.footer__socials-link--facebook {
  background: url("/public/svg/footer-fb.svg") center center/100% no-repeat;
}
.footer__mid-row {
  position: relative;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.footer__mid-row:before, .footer__mid-row:after {
  position: absolute;
  width: calc(100% - 2.5rem);
  height: 0.0625rem;
  left: 1.25rem;
  background-color: rgba(255, 255, 255, 0.5);
  content: "";
}
.footer__mid-row:before {
  top: 0;
}
.footer__mid-row:after {
  bottom: 0;
}
.footer__col--no-padding-left {
  padding-left: 0;
}
.footer__col-title {
  font-family: "Playfair Display";
  font-size: 1.0625rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: white;
  margin-bottom: 1.125rem;
}
.footer__nav-list {
  display: inline-flex;
  flex-flow: column nowrap;
}
.footer__nav-list-item {
  display: inline-flex;
  font-size: 0.875rem;
  line-height: 1rem;
  margin-bottom: 0.625rem;
  color: white;
  text-decoration: none;
}
.footer__nav-list-item:focus-visible {
  position: relative;
  outline: 0;
  border: 0;
}
.footer__nav-list-item:focus-visible:before {
  position: absolute;
  left: calc(-1.25rem / 2);
  top: calc(-0rem / 2);
  width: calc(100% + 1.25rem);
  height: calc(100% + 0rem);
  outline: 0.1875rem solid #646464;
  -moz-outline-radius: 0.625rem;
  border-radius: 0.625rem;
  content: "";
}
@media (max-width: 1199.98px) {
  .footer__nav-list-item:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
.footer__nav-list-item:hover {
  color: white;
  text-decoration: underline;
}
.footer__ul {
  display: flex;
  flex-flow: column nowrap;
  padding-left: 0;
  margin-bottom: 0;
}
.footer__text {
  display: flex;
  font-size: 0.875rem;
  line-height: 1rem;
  margin-bottom: 0.625rem;
  font-weight: 700;
  color: white;
}
.footer__text:focus-visible {
  position: relative;
  outline: 0;
  border: 0;
}
.footer__text:focus-visible:before {
  position: absolute;
  left: calc(-1.25rem / 2);
  top: calc(-0rem / 2);
  width: calc(100% + 1.25rem);
  height: calc(100% + 0rem);
  outline: 0.1875rem solid #646464;
  -moz-outline-radius: 0.625rem;
  border-radius: 0.625rem;
  content: "";
}
@media (max-width: 1199.98px) {
  .footer__text:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
.footer__text dt, .footer__text dd {
  line-height: 1rem;
  font-weight: 500;
  margin-bottom: 0;
}
.footer__text--phone-link, .footer__text--mail-link {
  padding-left: 1.625rem;
  text-decoration: none;
}
.footer__text--phone-link:hover, .footer__text--mail-link:hover {
  text-decoration: underline;
  color: white;
}
.footer__text--phone-link:after, .footer__text--mail-link:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  opacity: 0.5;
}
.footer__text--phone-link {
  position: relative;
}
.footer__text--phone-link:after {
  top: 0.0625rem;
  width: 1rem;
  height: 1rem;
  background: url("/public/svg/phone.svg") top left/1rem no-repeat;
}
.footer__text--mail-link {
  position: relative;
}
.footer__text--mail-link:after {
  top: 0.125rem;
  width: 1rem;
  height: 1rem;
  background: url("/public/svg/at.svg") top left/1rem no-repeat;
}
.footer__text--split {
  display: flex;
  justify-content: space-between;
  max-width: 10.625rem;
}
.footer__text--regular {
  font-weight: 500;
}
.footer__text--link {
  position: relative;
  display: inline-flex;
  text-decoration: none;
}
.footer__text--link:focus-visible {
  position: relative;
  outline: 0;
  border: 0;
}
.footer__text--link:focus-visible:before {
  position: absolute;
  left: calc(-1.875rem / 2);
  top: calc(-0rem / 2);
  width: calc(100% + 1.875rem);
  height: calc(100% + 0rem);
  outline: 0.1875rem solid #646464;
  -moz-outline-radius: 0.625rem;
  border-radius: 0.625rem;
  content: "";
}
@media (max-width: 1199.98px) {
  .footer__text--link:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
.footer__text--link:after {
  position: absolute;
  top: 0.1875rem;
  right: -0.9375rem;
  width: 0.5rem;
  height: 0.9375rem;
  content: "";
  background: url("/public/svg/chevron-right-white.svg") top left/0.4375rem no-repeat;
}
.footer__text--link:hover {
  text-decoration: underline;
  color: white;
}
.footer__bottom-row {
  padding-top: 2.125rem;
}
.footer__bottom-inner {
  display: flex;
}
.footer__copyright {
  font-size: 0.6875rem;
  line-height: 0.875rem;
  color: white;
  font-weight: 700;
}
.footer__bottom-nav {
  display: flex;
  flex-flow: row wrap;
  padding-left: 1.25rem;
}
.footer__bottom-nav-item {
  font-size: 0.6875rem;
  line-height: 0.875rem;
  color: white;
  margin: 0 1rem;
  text-decoration: none;
  margin-bottom: 0.625rem;
}
.footer__bottom-nav-item:focus-visible {
  position: relative;
  outline: 0;
  border: 0;
}
.footer__bottom-nav-item:focus-visible:before {
  position: absolute;
  left: calc(-1.25rem / 2);
  top: calc(-0.375rem / 2);
  width: calc(100% + 1.25rem);
  height: calc(100% + 0.375rem);
  outline: 0.1875rem solid #646464;
  -moz-outline-radius: 0.625rem;
  border-radius: 0.625rem;
  content: "";
}
@media (max-width: 1199.98px) {
  .footer__bottom-nav-item:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
.footer__bottom-nav-item:hover {
  text-decoration: underline;
  color: white;
}
.footer__mobile-only {
  display: none;
}
.footer__right-col {
  padding-top: 0.3rem;
}
.footer__contact-col .footer__col {
  padding-top: 0.25rem;
}
@media (max-width: 1399.98px) {
  .footer__top-row {
    padding-bottom: 1.5625rem;
  }
  .footer__mid-row {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
  .footer__mid-row .col-lg-6 {
    width: 100%;
  }
  .footer__mid-row .col-lg-6:nth-of-type(2) {
    margin-top: 1.25rem;
  }
  .footer__col-title:not(.footer__col-title--large) {
    margin-bottom: 0.625rem;
  }
}
@media (max-width: 991.98px) {
  .footer {
    margin-top: 5rem;
  }
  .footer__top-row {
    padding-bottom: 0.1875rem;
  }
  .footer__mid-row:before {
    display: none;
  }
  .footer__mid-row .col-lg-6:nth-of-type(1) {
    border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.5);
  }
  .footer__mid-row .col-6:nth-of-type(2) {
    padding-right: 1.25rem !important;
  }
  .footer__mid-row .col-6:nth-of-type(3) {
    padding-left: 1.25rem !important;
  }
  .footer__col {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-bottom: 1.25rem;
  }
  .footer__col--has-nav:nth-of-type(1) {
    padding-right: 1.25rem !important;
  }
  .footer__col--has-nav:nth-of-type(2) {
    padding-left: 1.25rem !important;
  }
  .footer__socials {
    flex-flow: column nowrap;
  }
  .footer__socials-text {
    font-size: 1.125rem;
    margin-bottom: 1.5rem;
  }
  .footer__socials-list {
    margin-right: 0;
  }
  .footer__col-title:not(.footer__col-title--large) {
    font-size: 1.125rem;
    min-width: 7.8125rem;
    line-height: 1.25rem;
    transform: translateY(-0.1875rem);
  }
  .footer__bottom-inner {
    flex-flow: column nowrap;
  }
  .footer__copyright {
    margin-bottom: 0.625rem;
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  .footer {
    border-radius: 3.125rem 0 0 0;
    padding: 2.5rem 0;
    margin-top: 3.75rem;
  }
  .footer__mid-row {
    padding-top: 1.25rem;
    padding-bottom: 4.375rem;
  }
  .footer__mid-row .col-6:nth-of-type(1) {
    border-right: 0.0625rem solid rgba(255, 255, 255, 0.5);
    padding-right: 0.9375rem !important;
  }
  .footer__mid-row .col-6:nth-of-type(2) {
    padding-left: 0.9375rem !important;
  }
  .footer__socials-text {
    font-size: 0.9375rem;
  }
  .footer__socials-link {
    width: 2.5rem;
    height: 2.5rem;
  }
  .footer__nav-list-item {
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin-bottom: 0.5rem;
  }
  .footer__row-centered {
    justify-content: center;
  }
  .footer__col {
    margin-bottom: 1.25rem;
  }
  .footer__col--has-nav {
    display: flex;
    flex-flow: row nowrap;
  }
  .footer__col--has-nav:nth-of-type(1) {
    padding-right: 0 !important;
  }
  .footer__col--has-nav:nth-of-type(2) {
    padding-left: 0 !important;
  }
  .footer__col-title--large {
    text-align: center;
    font-size: 1.75rem;
  }
  .footer__main-info-col {
    display: flex;
    justify-content: center;
    padding-bottom: 1.25rem;
    border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.5);
  }
  .footer__main-info {
    display: inline-flex;
    flex-flow: column nowrap;
  }
  .footer__bottom-nav {
    justify-content: center;
    padding-left: 0;
  }
  .footer__bottom-nav-item {
    font-size: 0.75rem;
    margin: 0 0.625rem 0.625rem 0.625rem;
  }
  .footer__text {
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin-bottom: 0.3125rem;
  }
  .footer__text--title {
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 0.6875rem;
  }
  .footer__text--phone-link:after {
    top: 0.0625rem;
    opacity: 0.7;
  }
  .footer__text--mail-link:after {
    top: 0.1875rem;
    opacity: 0.7;
  }
  .footer__text--link {
    text-decoration: underline;
  }
  .footer__text--link:after {
    top: 0.5625rem;
  }
  .footer__appointment-link {
    position: absolute;
    bottom: 1.625rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
  .footer__appointment-link:before {
    top: 0.5rem;
  }
  .footer__quick-col {
    display: none;
  }
  .footer__bottom-inner--working-at {
    padding: 1.25rem 0;
    border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.5);
  }
  .footer__bottom-inner--working-at .footer__bottom-nav-item {
    font-size: 0.875rem;
    line-height: 1.125rem;
    text-decoration: underline;
    margin: 0 0 0.625rem 0;
    padding: 0 0.625rem;
    border-right: 0.0625rem solid white;
  }
  .footer__bottom-inner--working-at .footer__bottom-nav-item:last-child {
    border-right: 0;
  }
  .footer__contact-col {
    padding-top: 1.25rem;
    margin-top: 0 !important;
    border-top: 0.0625rem solid rgba(255, 255, 255, 0.5);
  }
  .footer__mobile-only {
    display: flex;
  }
  .footer__working-at {
    align-self: center;
    font-size: 1rem;
    line-height: 2.25rem;
    font-weight: 700;
    margin-bottom: 0.6875rem;
  }
}

.mainnav {
  position: absolute;
  left: 0;
  top: 5rem;
  width: 100%;
}
.mainnav.show .mainnav__close-button {
  opacity: 1;
}
.mainnav__collapse-inner {
  min-height: 36.875rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 3.375rem;
  padding: 3.375rem 0 5rem;
  background-color: white;
  max-height: calc(100vh - 5rem);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.mainnav__inner {
  --bs-gutter-x: 1.875rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
  max-width: 95rem;
}
.mainnav__inner-row {
  margin-left: -1.875rem;
  margin-right: -1.875rem;
}
.mainnav__inner-col {
  padding-left: 1.875rem !important;
  padding-right: 1.875rem !important;
  display: flex;
  flex-flow: column nowrap;
}
.mainnav__col-title {
  font-size: 1.625rem;
  line-height: 1.875rem;
  margin-bottom: 2.125rem;
  text-decoration: none;
  cursor: default;
  pointer-events: none;
}
.mainnav__col-title:before {
  display: none !important;
}
.mainnav__list {
  display: flex;
  flex-flow: column nowrap;
  padding-left: 0;
  margin-bottom: 0;
}
.mainnav__sub-list {
  display: none;
  flex-flow: column nowrap;
  width: 100%;
  padding-left: 0;
  margin-bottom: 0;
}
.mainnav__li {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  border-bottom: 0.0625rem solid rgba(15, 29, 55, 0.3);
}
.mainnav__li:last-child {
  border-bottom-color: transparent;
}
.mainnav__li:hover > .mainnav__list-item {
  color: #0E7A3D;
  font-weight: 600;
}
.mainnav__li:hover .mainnav__list-item-chevron {
  background-color: #0E7A3D;
}
.mainnav__li:hover .mainnav__list-item-chevron svg path {
  fill: white;
}
.mainnav__li--sub-li {
  padding-left: 1.25rem;
}
.mainnav__li--sub-li:hover > .mainnav__list-item {
  font-weight: 500;
  color: #0E7A3D;
  text-decoration: underline;
}
.mainnav__li--has-sub-list .mainnav__list-item-chevron {
  display: flex;
  transform: translateX(0.375rem) rotate(90deg);
}
.mainnav__li--open > .mainnav__list-item {
  color: #0E7A3D;
  font-weight: 600;
}
.mainnav__li--open .mainnav__sub-list {
  display: flex;
}
.mainnav__li--open .mainnav__list-item-chevron {
  background-color: #0E7A3D;
  transform: translateX(0.375rem) rotate(270deg);
}
.mainnav__li--open .mainnav__list-item-chevron svg path {
  fill: white;
}
.mainnav__list-item {
  font-size: 1rem;
  line-height: 2rem;
  padding: 0.25rem 0;
  color: #323232;
  text-decoration: none;
  width: 100%;
}
.mainnav__list-item:focus-visible {
  position: relative;
  outline: 0;
  border: 0;
}
.mainnav__list-item:focus-visible:before {
  top: 0;
}
.mainnav__list-item:focus-visible:before {
  position: absolute;
  left: calc(-1.875rem / 2);
  top: calc(-0rem / 2);
  width: calc(100% + 1.875rem);
  height: calc(100% + 0rem);
  outline: 0.1875rem solid #646464;
  -moz-outline-radius: 2.5rem;
  border-radius: 2.5rem;
  content: "";
}
@media (max-width: 1199.98px) {
  .mainnav__list-item:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
.mainnav__list-item-chevron {
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  top: 0.625rem;
  right: 0.375rem;
  width: 1.1875rem;
  height: 1.1875rem;
  border-radius: 50%;
  transform: translateX(0.375rem);
  pointer-events: none;
}
.mainnav__list-item-chevron svg {
  transform: translateX(0rem) scale(0.85);
}
.mainnav__close-button {
  position: absolute;
  right: 2.8125rem;
  bottom: 3.125rem;
  font-weight: 500;
}
.mainnav__close-button:focus-visible {
  position: absolute;
  outline: 0;
  border: 0;
}
.mainnav__close-button:focus-visible:before {
  position: absolute;
  left: calc(-1.25rem / 2);
  top: calc(-1.25rem / 2);
  width: calc(100% + 1.25rem);
  height: calc(100% + 1.25rem);
  outline: 0.1875rem solid #646464;
  -moz-outline-radius: 0.625rem;
  border-radius: 0.625rem;
  content: "";
}
@media (max-width: 1199.98px) {
  .mainnav__close-button:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
.mainnav__bottom-nav {
  display: none;
}
@media (max-width: 1440px), (max-height: 1024px) {
  .mainnav__collapse-inner {
    padding: 1.875rem 0 2.5rem;
    min-height: 28.75rem;
  }
  .mainnav__col-title {
    font-size: 1.5rem;
    margin-bottom: 1.625rem;
  }
  .mainnav__list-item {
    font-size: 0.875rem;
    line-height: 1.875rem;
    padding: 0rem;
  }
  .mainnav__list-item-chevron {
    top: 0.25rem;
  }
  .mainnav__close-button {
    bottom: 1.875rem;
    right: 1.875rem;
  }
}
@media (max-width: 1199.98px) {
  .mainnav__collapse-inner {
    padding: 1.25rem 0 2.5rem;
    min-height: 0;
  }
  .mainnav__inner-col {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
    margin-bottom: 1.5rem;
  }
  .mainnav__col-title {
    font-size: 1.375rem;
    line-height: 1.75rem;
    margin-bottom: 1rem;
  }
}
@media (max-width: 991.98px) {
  .mainnav {
    top: 2.875rem;
  }
  .mainnav__inner {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
  .mainnav__bottom-nav {
    margin-top: 1.25rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 1.25rem;
  }
  .mainnav__bottom-nav-link {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  .mainnav__bottom-nav-link:focus-visible {
    position: relative;
    outline: 0;
    border: 0;
  }
  .mainnav__bottom-nav-link:focus-visible:before {
    position: absolute;
    left: calc(-0rem / 2);
    top: calc(-0.625rem / 2);
    width: calc(100% + 0rem);
    height: calc(100% + 0.625rem);
    outline: 0.1875rem solid #646464;
    -moz-outline-radius: 0.625rem;
    border-radius: 0.625rem;
    content: "";
  }
}
@media (max-width: 991.98px) and (max-width: 1199.98px) {
  .mainnav__bottom-nav-link:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
@media (max-width: 991.98px) {
  .mainnav__bottom-nav-link-icon-container {
    display: flex;
    justify-content: center;
    width: 1.375rem;
    margin-right: 0.625rem;
  }
}
@media (max-width: 991.98px) {
  .mainnav__bottom-nav-link-icon {
    height: 1.375rem;
    width: auto;
  }
  .mainnav__bottom-nav-link-icon--my-apeldoorn {
    height: 1.0625rem;
  }
  .mainnav__bottom-nav-link-icon--search {
    height: 1.125rem;
  }
}
@media (max-width: 991.98px) {
  .mainnav__bottom-nav-link-text {
    font-family: "interstate", sans-serif;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: #0E7A3D;
  }
}
@media (max-width: 767.98px) {
  .mainnav__collapse-inner {
    padding: 1.25rem 0 6.25rem;
  }
  .mainnav__inner-col {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
    margin-bottom: 0;
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .mainnav__inner-col--open .mainnav__list {
    max-height: 62.5rem;
    transition: max-height 1s ease;
    visibility: visible;
    display: flex;
  }
  .mainnav__inner-col--open .mainnav__col-title {
    color: #0E7A3D;
    font-weight: 700;
  }
  .mainnav__inner-col--open .mainnav__col-title:after {
    background: #0E7A3D url("/public/svg/chevron-right-white.svg") center center/0.4375rem no-repeat;
    transform: translateY(-50%) translateX(0.3125rem) rotate(90deg);
  }
  .mainnav__col-title {
    position: relative;
    font-size: 1.125rem;
    line-height: 1.5rem;
    padding: 0.5rem 0;
    margin-bottom: 0;
    border-bottom: 0.0625rem solid rgba(15, 29, 55, 0.3);
    pointer-events: auto;
  }
  .mainnav__col-title:focus-visible {
    position: relative;
    outline: 0;
    border: 0;
  }
  .mainnav__col-title:focus-visible:before {
    position: absolute;
    left: calc(-0.625rem / 2);
    top: calc(--0.375rem / 2);
    width: calc(100% + 0.625rem);
    height: calc(100% + -0.375rem);
    outline: 0.1875rem solid #646464;
    -moz-outline-radius: 0.625rem;
    border-radius: 0.625rem;
    content: "";
  }
}
@media (max-width: 767.98px) and (max-width: 1199.98px) {
  .mainnav__col-title:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
@media (max-width: 767.98px) {
  .mainnav__col-title:focus-visible:before {
    top: 0.1875rem;
    display: block !important;
  }
}
@media (max-width: 767.98px) {
  .mainnav__col-title:after {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%) translateX(0.3125rem);
    content: "";
    width: 1.375rem;
    height: 1.375rem;
    border-radius: 50%;
    transition: transform 0.2s ease;
    background: transparent url("/public/svg/chevron-right.svg") center center/0.4375rem no-repeat;
  }
}
@media (max-width: 767.98px) {
  .mainnav__list {
    height: 100%;
    max-height: 0;
    display: none;
    transition: max-height 0.4s ease;
    visibility: hidden;
  }
}
@media (max-width: 767.98px) {
  .mainnav__li--sub-li .mainnav__list-item {
    padding: 0.625rem 0 0.625rem 1.25rem;
    color: #323232 !important;
    font-weight: 500 !important;
  }
  .mainnav__li--has-sub-list {
    border-bottom: 0;
  }
  .mainnav__li--has-sub-list .mainnav__list-item {
    color: #323232 !important;
    font-weight: 500 !important;
  }
  .mainnav__li--has-sub-list .mainnav__list-item-chevron {
    right: 0.375rem;
  }
  .mainnav__li--open {
    border-bottom: 0.0625rem solid rgba(15, 29, 55, 0.3);
  }
  .mainnav__li--open > .mainnav__list-item {
    color: #0E7A3D !important;
    font-weight: 700 !important;
  }
  .mainnav__li--open .mainnav__sub-list {
    max-height: 62.5rem;
    transition: max-height 1s ease;
  }
}
@media (max-width: 767.98px) {
  .mainnav__sub-list {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    padding-left: 0;
    height: 100%;
    max-height: 0;
    transition: max-height 0.4s ease;
    background-color: #f6f7f7;
    border-top: 0.0625rem solid rgba(15, 29, 55, 0.3);
    overflow: hidden;
  }
}
@media (max-width: 767.98px) {
  .mainnav__list-item {
    font-size: 0.9375rem;
    line-height: 1.25rem;
    padding: 0.625rem 0 0.625rem 0.625rem;
  }
  .mainnav__list-item:last-child {
    border-bottom-color: rgba(15, 29, 55, 0.3);
  }
  .mainnav__list-item:focus-visible {
    position: relative;
    outline: 0;
    border: 0;
  }
  .mainnav__list-item:focus-visible:before {
    position: absolute;
    left: calc(-0.625rem / 2);
    top: calc(--0.375rem / 2);
    width: calc(100% + 0.625rem);
    height: calc(100% + -0.375rem);
    outline: 0.1875rem solid #646464;
    -moz-outline-radius: 0.625rem;
    border-radius: 0.625rem;
    content: "";
  }
}
@media (max-width: 767.98px) and (max-width: 1199.98px) {
  .mainnav__list-item:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
@media (max-width: 767.98px) {
  .mainnav__list-item:focus-visible:before {
    top: 0.1875rem;
  }
}
@media (max-width: 767.98px) {
  .mainnav__list-item-chevron {
    top: 0.625rem;
    transform: translateX(0.25rem) translateY(0);
  }
  .mainnav__list-item-chevron svg {
    width: 0.5rem;
  }
}
@media (max-width: 767.98px) {
  .mainnav__close-button {
    display: none;
  }
}

.section {
  position: relative;
  width: 100%;
}
.section > .container {
  position: relative;
}
.section__title-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.section__title {
  font-family: "Playfair Display";
  font-size: 4.3125rem;
  line-height: 4.5625rem;
  font-weight: 900;
  color: black;
  text-align: center;
}
.section__title--medium {
  font-size: 3.375rem;
  line-height: 3.75rem;
  font-weight: 500;
}
.section__title--medium--heavy {
  font-weight: 900;
}
.section__title--slider-small {
  font-size: 2.875rem;
  line-height: 3.75rem;
  font-weight: 500;
}
.section__title--white {
  color: white;
}
.section__title--small {
  font-size: 3.375rem;
  line-height: 3.75rem;
}
.section__title--light {
  font-weight: 400;
}
.section__title--article {
  font-weight: 400;
}
.section__intro {
  font-weight: 400;
  font-size: 1.375rem;
  line-height: 1.875rem;
  color: black;
  margin-top: 1.875rem;
  text-align: center;
  margin-bottom: 1.1875rem;
}
.section__intro--white {
  color: white;
}
.section__intro--regular {
  font-weight: 500;
}
.section__more {
  display: flex;
  justify-content: center;
}
.section__more-link {
  font-family: "interstate", sans-serif;
  font-size: 1.125rem;
  line-height: 1.625rem;
  color: black;
  text-decoration: none;
}
.section__more-link:hover {
  text-decoration: underline;
}
.section__hash-list {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin-top: 1.125rem;
  margin-bottom: 1.25rem;
  padding-left: 0;
  list-style-type: none;
}
.section__hash-list--in-hero-slider {
  margin-top: 0;
}
.section__hash-list--in-hero-slider .section__hash {
  color: white;
  font-size: 1.125rem;
  line-height: 1.375rem;
  font-weight: 500;
}
.section__hash-li {
  padding: 0 1.125rem;
}
.section__hash {
  font-size: 1.375rem;
  line-height: 1.875rem;
  font-weight: 300;
  color: black;
  text-decoration: none;
}
.section__hash:not(span):hover {
  text-decoration: underline;
}
.section--border-radius {
  border-radius: 4.6875rem 0 4.6875rem 0;
}
.section--border-bottom-right-radius {
  padding: 0 0 4.1875rem;
  border-bottom-right-radius: 4.6875rem;
}
.section--grey {
  background-color: #EBECEC;
}
.section--grey:last-of-type {
  border-radius: 0 0 4.6875rem 0;
}
.section--blue-gradient {
  background: #68b9d5;
  background: linear-gradient(190deg, #68b9d5 0%, #365270 45%, black 100%);
}
.section--green-lighter {
  background-color: #E5F1EB;
}
.section--has-leaf {
  overflow: visible;
}
.section--has-leaf:before {
  position: absolute;
  right: 1.25rem;
  bottom: 1.25rem;
  width: 60rem;
  height: 66.25rem;
  content: "";
  opacity: 0.3;
  background: url("/public/svg/leaf-white.svg") center center/100% no-repeat;
}
.section--blue {
  background-color: #CDE7F1;
}
.section--blue:last-of-type {
  border-radius: 0 0 4.6875rem 0;
}
.section--orange-light {
  background-color: #FDEFE5;
}
.section--orange {
  background-color: #FACBAA;
}
.section--transparent {
  padding: 6.25rem 0;
}
.section--topical {
  padding: 3.75rem 0 6rem;
  border-bottom-right-radius: 4.6875rem;
  overflow: hidden;
}
.section--quote {
  padding: 6.25rem 0 6.25rem;
}
.section--article-slider {
  padding: 4rem 0;
}
.section--discover {
  padding: 6.25rem 0 4.75rem;
}
.section--council {
  position: relative;
  padding: 5.625rem 0 9.375rem;
  overflow: hidden;
}
.section--council:before {
  position: absolute;
  top: 5.5rem;
  left: 50%;
  width: 107.6875rem;
  height: 119.125rem;
  background: url("/public/svg/leaf.svg") center center/100% no-repeat;
  transform: translateX(-50%);
  content: "";
}
.section--council > .container {
  position: relative;
}
.section--page-topical {
  position: relative;
  padding: 2.25rem 0 4.375rem 0;
}
.section--page-topical .container {
  position: relative;
}
.section--page-topical:before {
  position: absolute;
  bottom: -4.375rem;
  left: 0;
  width: 100%;
  height: calc(100% - 24.375rem);
  background-color: #EBECEC;
  content: "";
  border-radius: 4.6875rem 0 0 0;
}
.section--topical-slider {
  position: relative;
  padding: 3.625rem 0 4.375rem;
}
.section--file-slider {
  padding: 5.3125rem 0 6.5625rem;
}
.section--article-first {
  padding: 0 0 4.1875rem;
}
.section--article-images {
  padding: 2.5rem 0 0;
}
.section--page-file {
  padding: 4.375rem 0 4.0625rem 0;
}
.section--page-file .container {
  position: relative;
}
.section--page-file:before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 28.75rem);
  background-color: #CDE7F1;
  content: "";
  border-radius: 4.6875rem 0 4.6875rem 0;
}
.section--page-file--detail {
  padding: 4.375rem 0 15rem 0;
}
.section--page-file--detail:before {
  height: calc(100% - 36.25rem);
}
.section--page-file--detail .section__intro {
  margin-bottom: 0;
}
.section--page-file--detail .section__intro p {
  margin-bottom: 0;
}
.section--administrative:before, .section--parking:before {
  background-color: #E5F1EB;
  height: calc(100% - 28.75rem);
}
.section--more-info--blue-only {
  padding: 5rem 0 0;
}
.section--products {
  z-index: 3;
}
.section--bottom-search {
  margin-top: -11.25rem;
}
.section--breadcrumbs--fluid > .container {
  max-width: 76.25rem;
}
.section--has-cta-side-block {
  position: relative;
  width: 100%;
  height: 0;
}
.section--facts {
  padding: 5rem 0;
}
.section--contact {
  padding: 0 0 5rem 0;
}
.section--related-articles-no-swiper {
  padding: 5.625rem 0 3.75rem !important;
}
@media (max-width: 1399.98px) {
  .section__title--small {
    font-size: 3.375rem;
    line-height: 3.75rem;
  }
  .section__title--slider-small {
    font-size: 2.3125rem;
    line-height: 3.25rem;
  }
  .section--topical {
    padding: 3.75rem 0 6rem;
  }
  .section--article-slider {
    padding: 3.75rem 0;
  }
  .section--council {
    padding: 5.625rem 0 8.75rem;
  }
  .section--file-slider {
    padding: 3.125rem 0 5rem;
  }
  .section--page-file--detail {
    padding: 5.25rem 0 20rem 0;
  }
  .section--page-file--detail:before {
    height: calc(100% - 36.25rem);
  }
  .section--administrative {
    padding-bottom: 15rem;
  }
}
@media (max-width: 1199.98px) {
  .section__title {
    font-size: 3.75rem;
    line-height: 4.375rem;
  }
  .section__title--medium {
    font-size: 3rem;
    line-height: 3.25rem;
  }
  .section__title--small {
    font-size: 3.25rem;
    line-height: 3.75rem;
  }
  .section__title--slider-small {
    font-size: 2.3125rem;
    line-height: 3.25rem;
  }
  .section--topical {
    padding: 2.5rem 0 5.375rem;
  }
  .section--quote {
    padding: 5.625rem 0 5.625rem;
  }
  .section--article-slider {
    padding: 2.5rem 0 3.75rem;
  }
  .section--discover {
    padding: 5.375rem 0 3.75rem;
  }
  .section--topical-slider, .section--file-slider {
    padding: 1.875rem 0 2.5rem;
  }
  .section--page-file {
    padding: 4rem 0 2.5rem 0;
  }
  .section--page-file:before {
    height: calc(100% - 23.75rem);
  }
  .section--page-file--detail {
    padding: 4rem 0 20rem 0;
  }
  .section--page-file--detail:before {
    height: calc(100% - 31.25rem);
  }
  .section--administrative {
    padding-bottom: 15rem;
  }
  .section--council {
    padding: 5.25rem 0 5rem;
  }
}
@media (max-width: 991.98px) {
  .section__title--medium {
    font-size: 2.625rem;
    line-height: 3.125rem;
  }
  .section__title--small {
    font-size: 2.625rem;
    line-height: 3.125rem;
  }
  .section__title--slider-small {
    font-size: 2.625rem;
    line-height: 3.125rem;
  }
  .section__intro {
    font-size: 1.125rem;
    line-height: 1.625rem;
    margin-top: 0.9375rem;
    margin-bottom: 0.5625rem;
  }
  .section__hash-list {
    margin-bottom: 0;
  }
  .section__hash-list--in-hero-slider {
    margin-bottom: 1.25rem;
  }
  .section__hash-li {
    padding: 0 0.375rem;
  }
  .section__hash {
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 300;
  }
  .section--quote {
    padding: 5rem 0 5.3125rem;
  }
  .section--page-topical {
    padding-top: 2rem;
    padding-bottom: 0;
  }
  .section--page-topical:before {
    border-radius: 3.125rem 0 0 0;
    height: calc(100% - 18.75rem);
  }
  .section--article-first {
    padding: 0 0 2.5rem;
  }
  .section--page-file {
    padding: 2.125rem 0 2.5rem 0;
  }
  .section--page-file:before {
    border-radius: 3.125rem 0 3.125rem 0;
  }
  .section--page-file--detail {
    padding: 2.125rem 0 20rem 0;
  }
  .section--page-file--detail:before {
    height: calc(100% - 26.25rem);
  }
  .section--administrative {
    padding-bottom: 15rem;
  }
  .section--products {
    padding: 1.25rem 0;
  }
  .section--products > .container {
    z-index: 2;
  }
  .section--breadcrumbs--fluid {
    background-color: #EBECEC;
  }
  .section--has-cta-side-block {
    display: flex;
    order: -1;
    height: auto;
  }
  .section--related-articles-no-swiper {
    padding: 3.125rem 0 !important;
  }
}
@media (max-width: 767.98px) {
  .section__title {
    font-size: 2.875rem;
    line-height: 3.5rem;
  }
  .section__title--medium {
    font-size: 1.875rem;
    line-height: 2.125rem;
  }
  .section__title--small {
    font-size: 1.875rem;
    line-height: 2.125rem;
  }
  .section__title--slider-small {
    font-size: 1.875rem;
    line-height: 2.125rem;
  }
  .section__intro {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-top: 0.9375rem;
    margin-bottom: 0.5625rem;
  }
  .section__more-link {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .section--border-radius {
    border-radius: 2.5rem 0 2.5rem 0;
  }
  .section__hash-list--in-hero-slider {
    margin-bottom: 0.875rem;
  }
  .section__hash-list--in-hero-slider .section__hash {
    font-size: 0.875rem;
  }
  .section--topical {
    padding: 2.5rem 0 3.875rem;
    border-bottom-right-radius: 2.5rem;
  }
  .section--quote {
    padding: 3.75rem 0;
  }
  .section--article-slider {
    padding: 2.5rem 0 3.125rem;
  }
  .section--discover {
    padding: 3.125rem 0 1.25rem;
  }
  .section--council {
    padding: 3.125rem 0 4.375rem;
    margin-bottom: 3.75rem;
  }
  .section--border-bottom-right-radius {
    border-bottom-right-radius: 2.5rem;
  }
  .section--page-file--detail:before {
    height: calc(100% - 31.25rem);
  }
  .section--administrative {
    padding-bottom: 12.5rem;
  }
  .section--more-info--blue-only {
    padding: 2.5rem 0 0;
  }
  .section--bottom-search {
    display: none;
  }
  .section--facts {
    padding: 2.5rem 0 1.875rem;
  }
  .section--related-articles-no-swiper > .container {
    max-width: 1535px !important;
  }
  .section--contact {
    padding: 0 0 1.875rem 0;
  }
}

.section--transparent.article-slider--related-articles + .section--more-info {
  padding-top: 0;
}

.section--administrative + .section--more-info {
  margin-top: -11.25rem;
}

.hero-slider {
  position: relative;
}
.hero-slider__slide {
  position: relative;
  width: 100%;
  height: calc(100vh - 5rem);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.hero-slider__bg-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.hero-slider__bg-image-container:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.28);
  content: "";
}
.hero-slider__bg-image {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero-slider__slide-content {
  position: relative;
}
.hero-slider__slide-title {
  font-family: "Playfair Display";
  font-weight: 900;
  color: white;
  font-size: 3.375rem;
  line-height: 3.75rem;
  margin-bottom: 0;
  transform: translateY(-0.5rem);
}
.hero-slider__slide-title a {
  position: relative;
  color: white;
  text-decoration: none;
}
.hero-slider__slide-title a:before {
  outline-color: #fff;
}
.hero-slider__slide-title a:hover {
  text-decoration: underline;
  text-decoration-thickness: 0.125rem;
}
.hero-slider--topstory .hero-slider__slide-title {
  text-align: center;
}
@media (max-width: 1399.98px) {
  .hero-slider__slide-title {
    font-size: 3.375rem;
    line-height: 4.25rem;
  }
}
@media (max-width: 1199.98px) {
  .hero-slider__slide-title {
    font-size: 2.75rem;
    line-height: 3.5rem;
  }
}
@media (max-width: 991.98px) {
  .hero-slider__slide-content {
    padding: 0 2.5rem;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    opacity: 1;
    visibility: visible;
  }
  .hero-slider__slide-content--show {
    opacity: 1;
    visibility: visible;
    transition: all 0.3s 0.3s ease;
  }
  .hero-slider__slide {
    height: calc(100vh - 2.875rem);
    min-height: 27.5rem;
  }
  .hero-slider__slide-title {
    font-size: 2rem;
    line-height: 2.375rem;
    text-align: center;
  }
  .hero-slider__slide-title a {
    text-decoration: underline;
    text-decoration-thickness: 0.0625rem;
  }
  .hero-slider__pagination {
    bottom: auto;
    top: calc(50% + 3.875rem);
  }
  .hero-slider--topstory .hero-slider__slide-content {
    opacity: 1;
    visibility: visible;
  }
  .hero-slider--topstory .hero-slider__slide {
    min-height: 18.75rem;
  }
  .hero-slider--topstory .hero-image__description-container {
    bottom: 3.25rem;
  }
}
@media (max-width: 575.98px) {
  .hero-slider__slide-title {
    max-width: 21.875rem;
    margin: 0 auto;
  }
}

.hero-image {
  position: relative;
  width: 100%;
  height: 33.125rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.hero-image__image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.hero-image__image {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero-image__image--align-bottom {
  object-position: bottom;
}
.hero-image__description-container,
.hero-image figcaption {
  padding: 0.5625rem 1.25rem;
  position: absolute;
  right: 5.5rem;
  bottom: 2.5rem;
  background-color: white;
  border-radius: 0.625rem;
  width: auto;
  max-width: 28.875rem;
}
.hero-image__description-container--white,
.hero-image figcaption--white {
  padding: 0;
  background-color: transparent;
  width: auto;
  display: inline-flex;
}
.hero-image__description {
  font-size: 0.75rem;
  line-height: 1.0625rem;
  margin-bottom: 0;
}
.hero-image__description--white {
  padding: 0 !important;
  background-color: transparent !important;
  width: auto !important;
  display: inline-flex;
  color: white;
}
.hero-image__description--photographer {
  font-size: 0.875rem;
  line-height: 1.375rem;
  text-shadow: 0px 0px 3px #000000;
}
.hero-image__photographer {
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: white;
}
.hero-image__gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.hero-image__gradient:before {
  position: absolute;
  width: 100%;
  height: 45rem;
  left: 0;
  top: -22.5rem;
  content: "";
  z-index: 1;
  background: #60b5d3;
  background: linear-gradient(238deg, #60b5d3 0%, #5db3ce 36%, #0e7a3d 90%, #0e7a3d 100%);
}
.hero-image__content {
  display: flex;
  flex-flow: column nowrap;
  padding: 2rem 3rem 2.375rem 3rem;
  border-radius: 2.8125rem 0 2.8125rem 0;
  background-color: white;
  margin-bottom: 3.125rem;
}
.hero-image__content--product {
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
  margin-top: -10rem;
  margin-bottom: 0;
}
.hero-image__content--shadow {
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
}
.hero-image__absolute-content {
  position: absolute;
  top: 4.375rem;
  left: 0;
}
.hero-image__content-title {
  font-family: "Playfair Display";
  font-size: 2.5rem;
  line-height: 2.625rem;
  font-weight: 900;
  color: black;
  margin-bottom: 0;
}
.hero-image__content-text {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 300;
  margin-top: 1.875rem;
  margin-bottom: 0;
}
.hero-image__content-text p {
  margin-bottom: 0;
}
.hero-image__tags {
  margin-top: 2.1875rem;
}
.hero-image__artwork-logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.hero-image__artwork-logo:after {
  position: absolute;
  width: 91.125rem;
  height: 100.8125rem;
  left: calc(50% - 2.8125rem);
  transform: translateX(-50%);
  top: -39.375rem;
  content: "";
  background: url("/public/svg/leaf-header.svg") center center/100% no-repeat;
  mix-blend-mode: multiply;
}
.hero-image__artwork-v3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.hero-image__artwork-v3-top {
  position: absolute;
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  bottom: 62%;
  transform: translateY(50%);
}
.hero-image__artwork-v3-top:before {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  background: url("/public/svg/header-artwork-v3-top.svg") center center/100% no-repeat;
}
.hero-image__gradient-behind-logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #0e7a3d;
  background: linear-gradient(80deg, #0e7a3d 0%, #54adbd 100%);
}
.hero-image__artwork-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.hero-image__artwork-inner:before {
  position: absolute;
  width: 49.125rem;
  height: 54.8125rem;
  top: -38.875rem;
  right: 49%;
  content: "";
  z-index: 2;
  background: url("/public/svg/article-artwork-left.svg") center center/100% no-repeat;
}
.hero-image__artwork-inner:after {
  position: absolute;
  width: 49.125rem;
  height: 54.8125rem;
  left: 56%;
  top: 4.1875rem;
  content: "";
  z-index: 2;
  background: url("/public/svg/article-artwork-right.svg") center center/100% no-repeat;
}
.hero-image__artwork-inner--in-products {
  display: none;
}
.hero-image__table {
  display: flex;
  flex-flow: column nowrap;
  margin-top: 1.5625rem;
}
.hero-image__table--large .hero-image__table-tr {
  margin-bottom: 1.625rem;
}
.hero-image__table--large .hero-image__table-tr:last-of-type {
  margin-bottom: 0;
}
.hero-image__table--large .hero-image__table-td-right {
  padding-top: 0;
}
.hero-image__table--large .hero-image__table-td-right p {
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: 300;
  margin-bottom: 0;
}
.hero-image__table--large .hero-image__table-td-right a {
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: 300;
  text-decoration: none;
}
.hero-image__table--large .hero-image__table-td-right a:hover {
  text-decoration: underline;
}
.hero-image__table-tr {
  display: flex;
  flex-flow: row nowrap;
}
.hero-image__table-tr--inner {
  max-width: 15rem;
  justify-content: space-between;
  margin-bottom: 0 !important;
}
.hero-image__table-tr--inner p:last-of-type {
  min-width: 8.125rem;
  text-align: left;
}
.hero-image__table-td-left {
  display: flex;
  width: 9.375rem;
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: 600;
}
.hero-image__table-td-right {
  display: inline;
  width: 100%;
  padding-top: 0.1875rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-bottom: 0;
}
.hero-image__table-td-right strong {
  font-weight: 700;
}
.hero-image__table-td-right a {
  font-weight: 700;
  text-decoration: underline;
}
.hero-image__table-td-right a:hover u {
  text-decoration: none;
}
.hero-image__table-anchor {
  font-size: 1.75rem !important;
  line-height: 2.375rem !important;
  font-weight: 700 !important;
}
.hero-image__more {
  font-size: 1.125rem;
  line-height: 1.625rem;
  margin-top: 1.125rem;
  font-weight: 700;
}
.hero-image__more:hover {
  text-decoration: none;
}
.hero-image__bottom {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.hero-image__buttons {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  margin-top: 3.125rem;
}
.hero-image__buttons a {
  margin-top: 1.0625rem;
  margin-right: 1.0625rem;
}
.hero-image__buttons a:first-of-type {
  margin-top: 0;
}
.hero-image__logos {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: flex-end;
}
.hero-image__logos--horizontal {
  flex-flow: row nowrap;
  margin-left: auto;
  margin-top: 1.25rem;
}
.hero-image__logos--horizontal .hero-image__logo {
  margin-left: 2.5rem;
}
.hero-image__logo {
  width: auto;
  margin-top: 0.9375rem;
  max-height: 4.8125rem;
  max-width: 11.25rem;
}
.hero-image__logo img {
  max-height: 4.8125rem;
  max-width: 11.25rem;
}
.hero-image__logo:first-of-type {
  margin-top: 0;
}
.hero-image__gradient-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 5rem);
  height: 33.125rem;
  overflow: hidden;
  background: #0e7a3d;
  background: linear-gradient(45deg, #0e7a3d 0%, #5eb3cf 80%, #60b5d3 100%);
}
.hero-image__gradient-2:before {
  position: absolute;
  bottom: 11.625rem;
  right: 19%;
  width: 50.3125rem;
  height: 58rem;
  content: "";
  background: url("/public/svg/product-artwork-center.svg") center center/100% no-repeat;
}
.hero-image__gradient-2:after {
  position: absolute;
  bottom: -43.125rem;
  right: -17%;
  width: 50.3125rem;
  height: 58rem;
  content: "";
  background: url("/public/svg/product-artwork-center.svg") center center/100% no-repeat;
}
.hero-image--artwork {
  justify-content: flex-start;
  align-items: flex-start;
  height: 14.375rem;
}
.hero-image--artwork > .container {
  z-index: 2;
}
.hero-image--narrower {
  height: 14.375rem;
}
.hero-image--product-detail, .hero-image--contact-special {
  flex-flow: column nowrap;
  height: auto;
  padding-top: 5.625rem;
}
.hero-image--product-detail .hero-image__content, .hero-image--contact-special .hero-image__content {
  margin-bottom: 0;
  width: 100%;
  max-width: 42.5rem;
}
.hero-image--contact-special {
  height: 38.125rem;
}
.hero-image--contact-special .hero-image__image-container {
  height: calc(100% - 5rem);
}
.hero-image--narrow {
  height: 26.875rem;
  padding: 0;
}
.hero-image--content-offset {
  height: 36.25rem;
}
.hero-image--content-offset .hero-image__image-container {
  height: 33.125rem;
}
.hero-image--content-offset .hero-image__content {
  margin-bottom: 0;
}
.hero-image--content-offset.hero-image--narrow {
  height: 30rem;
}
.hero-image--content-offset.hero-image--narrow .hero-image__image-container {
  height: 26.875rem;
}
@media (max-width: 1399.98px) {
  .hero-image {
    padding: 1.25rem 2.5rem 1.25rem 1.25rem;
  }
  .hero-image__gradient-2:before {
    right: 30%;
  }
  .hero-image--narrow {
    padding: 0;
  }
  .hero-image--artwork {
    height: 14.375rem;
  }
  .hero-image--product-detail, .hero-image--contact-special {
    padding: 5.625rem 0 0 0;
  }
}
@media (max-width: 1199.98px) {
  .hero-image {
    height: 31.25rem;
  }
  .hero-image__content {
    padding: 1.625rem 1.875rem 1.875rem 1.875rem;
  }
  .hero-image__content-title {
    font-size: 1.9375rem;
    line-height: 2.0625rem;
  }
  .hero-image__content-text {
    font-size: 0.9375rem;
    line-height: 1.5rem;
    margin-top: 0.9375rem;
  }
  .hero-image--narrow {
    height: 25rem;
    padding: 0;
  }
  .hero-image--artwork {
    height: 14.375rem;
  }
  .hero-image--product-detail, .hero-image--contact-special {
    height: auto;
    padding-top: 5.625rem;
  }
  .hero-image--narrower {
    height: 14.375rem;
  }
  .hero-image--content-offset {
    height: 34.375rem;
  }
  .hero-image--content-offset .hero-image__image-container {
    height: 31.25rem;
  }
  .hero-image--content-offset.hero-image--narrow {
    height: 28.125rem;
  }
  .hero-image--content-offset.hero-image--narrow .hero-image__image-container {
    height: 25rem;
  }
}
@media (max-width: 991.98px) {
  .hero-image {
    height: 25.25rem;
    padding: 1.25rem;
  }
  .hero-image__description-container,
.hero-image figcaption {
    padding: 0.5625rem 1rem;
    right: 1.25rem;
    bottom: 1.25rem;
  }
  .hero-image__description-container--white,
.hero-image figcaption--white {
    right: 0.625rem;
    bottom: 0.625rem;
  }
  .hero-image__description {
    font-size: 0.6875rem;
    line-height: 0.875rem;
  }
  .hero-image__content {
    padding: 1.25rem;
    border-radius: 1.25rem 0 1.25rem 0;
  }
  .hero-image__content--product {
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 1.25rem 0 1.25rem 0;
  }
  .hero-image__content--no-photographer {
    margin-bottom: 1.25rem;
  }
  .hero-image__content-title {
    font-size: 1.5rem;
    line-height: 1.625rem;
  }
  .hero-image__content-text {
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin-top: 0.9375rem;
  }
  .hero-image__tags {
    margin-top: 1.25rem;
  }
  .hero-image__artwork-inner--in-products {
    display: flex;
  }
  .hero-image__gradient-2 {
    height: 100%;
    background: #288c6c;
    background: linear-gradient(10deg, #288c6c 0%, #47a2a6 100%);
  }
  .hero-image__gradient-2:before {
    right: auto;
    left: 0;
    transform: translateX(-60%);
  }
  .hero-image__gradient-2:after {
    right: 0;
    transform: translateX(60%);
  }
  .hero-image__artwork-logo:after {
    width: 62.5rem;
  }
  .hero-image--artwork {
    height: 12.5rem;
  }
  .hero-image--artwork--product {
    display: none;
  }
  .hero-image--artwork--with-cta {
    padding: 0;
  }
  .hero-image--product-detail, .hero-image--contact-special {
    height: auto;
    padding: 0 0 1.25rem 0;
  }
  .hero-image--product-detail .container, .hero-image--contact-special .container {
    order: 2;
  }
  .hero-image--product-detail .hero-image__content, .hero-image--contact-special .hero-image__content {
    margin-top: 1.25rem;
    border-radius: 1.25rem 0 1.25rem 0;
  }
  .hero-image--product-detail .hero-image__image-container, .hero-image--contact-special .hero-image__image-container {
    height: 100%;
  }
  .hero-image--narrower {
    height: 14.375rem;
  }
  .hero-image--content-offset {
    height: 25.25rem;
  }
  .hero-image--content-offset .hero-image__image-container {
    height: 100%;
  }
  .hero-image--content-offset .hero-image__content {
    margin-bottom: 3.125rem;
    transform: translateY(0);
  }
  .hero-image--content-offset .hero-image__content--no-photographer {
    margin-bottom: 1.25rem;
  }
  .hero-image--content-offset.hero-image--narrow {
    height: 25rem;
  }
  .hero-image--content-offset.hero-image--narrow .hero-image__image-container {
    height: 100%;
  }
}
@media (max-width: 767.98px) {
  .hero-image {
    padding: 0;
  }
  .hero-image__description-container,
.hero-image figcaption {
    max-width: calc(100% - 2.5rem);
  }
  .hero-image__content {
    margin-bottom: 3.75rem;
  }
  .hero-image__content--product {
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 1.25rem 0 1.25rem 0;
  }
  .hero-image__content--no-photographer {
    margin-bottom: 1.25rem;
  }
  .hero-image__content-title {
    font-size: 1.5rem;
    line-height: 1.625rem;
  }
  .hero-image__content-text {
    margin-top: 0.75rem;
    font-size: 0.6875rem;
    line-height: 0.875rem;
  }
  .hero-image__table {
    margin-top: 0.9375rem;
    margin-bottom: 0.625rem;
  }
  .hero-image__table--large .hero-image__table-td-right {
    font-size: 0.6875rem;
    line-height: 1rem;
    font-weight: 300;
  }
  .hero-image__table--large .hero-image__table-td-right p {
    font-size: 0.6875rem;
    line-height: 1rem;
    font-weight: 300;
  }
  .hero-image__table--large .hero-image__table-td-right strong {
    font-weight: 700;
  }
  .hero-image__table--large .hero-image__table-td-right a {
    font-size: 0.6875rem;
    line-height: 1rem;
  }
  .hero-image__table-tr--inner {
    max-width: 9.375rem;
  }
  .hero-image__table-tr--inner p:last-of-type {
    min-width: 5.3125rem;
  }
  .hero-image__table-anchor {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
  }
  .hero-image__table-td-left {
    font-size: 0.6875rem;
    line-height: 1rem;
    width: 5rem;
  }
  .hero-image__table-td-right {
    padding-top: 0;
    font-size: 0.6875rem;
    line-height: 1rem;
    font-weight: 300;
  }
  .hero-image__table-td-right strong {
    font-weight: 700;
  }
  .hero-image__bottom {
    flex-flow: column nowrap;
  }
  .hero-image__buttons {
    order: 2;
    margin-top: 1rem;
  }
  .hero-image__buttons a:last-of-type {
    margin-right: 0;
  }
  .hero-image__more {
    font-size: 0.6875rem;
    margin-top: 0;
  }
  .hero-image__logos {
    order: 1;
    flex-flow: row wrap;
    align-items: center;
    margin-top: 0.625rem;
  }
  .hero-image__logos--horizontal {
    align-items: flex-end;
  }
  .hero-image__logos--horizontal .hero-image__logo {
    margin-left: 0.625rem;
    margin-top: 0.625rem;
  }
  .hero-image__logo {
    max-width: 6.875rem;
    max-height: 2.8125rem;
    margin-left: 0.8125rem;
    margin-top: 0;
  }
  .hero-image__logo img {
    max-width: 100%;
    max-height: 2.8125rem;
  }
  .hero-image--artwork--with-cta {
    display: flex;
    flex-flow: column nowrap;
    height: auto;
  }
  .hero-image--artwork--with-cta .hero-image__artwork-inner {
    position: relative;
    height: 12.5rem;
    left: auto;
    top: auto;
    display: flex;
    order: 2;
  }
  .hero-image--product-detail, .hero-image--contact-special {
    padding-bottom: 1.25rem;
  }
  .hero-image--artwork-v3 {
    height: auto;
    padding: 1.25rem 0;
  }
  .hero-image--artwork-v3 .hero-image__content {
    margin-bottom: 0;
  }
  .hero-image--narrow {
    height: 12.5rem;
  }
  .hero-image--content-offset {
    height: 25rem;
  }
}
@media (max-width: 575.98px) {
  .hero-image--artwork {
    height: 5.375rem;
  }
  .hero-image--artwork:before {
    transform: scale(0.6);
    right: -4%;
    top: -43.875rem;
  }
  .hero-image--artwork:after {
    transform: scale(0.6);
    left: -11%;
    top: -10.375rem;
  }
  .hero-image--artwork .hero-image__artwork-inner:before {
    transform: scale(0.3);
    right: -27%;
    top: -31.875rem;
  }
  .hero-image--artwork .hero-image__artwork-inner:after {
    transform: scale(0.3);
    left: -16%;
    top: -17.9375rem;
  }
  .hero-image--artwork--with-cta {
    display: flex;
    flex-flow: column nowrap;
    height: auto;
  }
  .hero-image--artwork--with-cta .hero-image__artwork-inner {
    position: relative;
    height: 5.375rem;
    left: auto;
    top: auto;
    display: flex;
    order: 2;
  }
  .hero-image__artwork-logo:after {
    width: 33.25rem;
    left: 49%;
    top: -40.25rem;
  }
}
@media (max-width: 767px) and (orientation: landscape) {
  .hero-image {
    height: auto;
    min-height: 11.25rem;
    max-height: 25.25rem;
  }
  .hero-image--contact-special, .hero-image--product-detail {
    max-height: 100%;
  }
  .hero-image--artwork--with-cta {
    max-height: 12.5rem;
  }
}

.slider__pagination {
  display: inline-flex;
  position: absolute;
  left: 50% !important;
  bottom: 4.625rem !important;
  width: auto !important;
  transform: translateX(-50%);
  z-index: 2;
  height: 1.0625rem;
}
.slider__pagination--more-info {
  bottom: 0 !important;
}
.slider__pagination--more-info .slider__bullet {
  border: 0.125rem solid black;
}
.slider__pagination--more-info .slider__bullet--active, .slider__pagination--more-info .slider__bullet:hover {
  background-color: #C95100;
}
.slider__bullet {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  border: 0.125rem solid white;
  margin: 0 0.625rem;
  cursor: pointer;
}
.slider__bullet:hover {
  background-color: white;
}
.slider__bullet--active {
  background-color: white;
  cursor: default;
}
.slider__navigation {
  position: absolute;
  top: calc(50% - 1.125rem);
  left: 0;
  width: 100%;
  height: 0;
  z-index: 2;
}
.slider__navigation--more-info .slider__nav-button {
  background-color: #D8D8D8;
}
.slider__navigation--more-info .slider__nav-button:hover {
  background-color: rgba(216, 216, 216, 0.8);
}
.slider__navigation--more-info .slider__nav-button--prev {
  left: -2.375rem !important;
}
.slider__navigation--more-info .slider__nav-button--next {
  right: -2.375rem !important;
}
.slider__nav-button {
  position: absolute !important;
  top: 0;
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  padding: 0;
  margin: 0;
  border: 0;
}
.slider__nav-button:focus-visible {
  position: relative;
  outline: 0;
  border: 0;
}
.slider__nav-button:focus-visible:before {
  position: absolute;
  left: calc(-0rem / 2);
  top: calc(-0rem / 2);
  width: calc(100% + 0rem);
  height: calc(100% + 0rem);
  outline: 0.1875rem solid #646464;
  -moz-outline-radius: 50%;
  border-radius: 50%;
  content: "";
}
@media (max-width: 1199.98px) {
  .slider__nav-button:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
.slider__nav-button:hover {
  background-color: white;
}
.slider__nav-button svg {
  transform: scale(0.75);
}
.slider__nav-button--prev {
  left: 1.75rem !important;
}
.slider__nav-button--prev svg {
  transform: translateX(-0.125rem) scale(0.75);
}
.slider__nav-button--next {
  right: 1.75rem !important;
}
.slider__nav-button--next svg {
  transform: rotate(180deg) translateX(-0.0625rem) scale(0.75);
}
.slider__nav-button--scroll-to {
  top: auto;
  bottom: 1.5625rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
.slider__nav-button--scroll-to svg {
  margin-top: 0.25rem;
  transform: rotate(-90deg) scale(0.75);
}
.slider__nav-button--scroll-to--alt {
  background-color: transparent !important;
}
.slider__nav-button--scroll-to--alt path {
  fill: white;
}
.slider__nav-button.swiper-button-disabled {
  opacity: 0.3;
  pointer-events: none;
}
@media (max-width: 1160px) {
  .slider__nav-button--scroll-to--alt {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .slider__bullet {
    width: 0.625rem;
    height: 0.625rem;
    margin: 0 0.375rem;
    border-width: 0.0625rem;
  }
  .slider__pagination--more-info {
    bottom: -0.75rem !important;
  }
  .slider__navigation--more-info .slider__nav-button--prev {
    left: 0.625rem !important;
  }
  .slider__navigation--more-info .slider__nav-button--next {
    right: 0.625rem !important;
  }
  .slider__nav-button {
    width: 2rem;
    height: 2rem;
  }
  .slider__nav-button--prev {
    left: 0.625rem !important;
  }
  .slider__nav-button--prev svg {
    transform: scale(0.7);
  }
  .slider__nav-button--next {
    right: 0.625rem !important;
  }
  .slider__nav-button--next svg {
    transform: rotate(180deg) translateX(0rem) scale(0.7);
  }
  .slider__nav-button--scroll-to {
    bottom: 0.625rem;
  }
  .slider__nav-button--scroll-to svg {
    transform: rotate(-90deg) translateX(0.0625rem) scale(0.7);
  }
}

.how-to-help {
  position: absolute;
  top: 0;
  left: calc((100vw - 95.625rem) / 2);
  left: calc((100% - 73.75rem) / 2);
  max-width: 27.375rem;
  width: 100%;
  height: 100%;
}
.how-to-help__el {
  position: absolute;
  top: 50%;
  z-index: 3;
  display: flex;
  align-items: center;
  transform: translateY(-50%);
}
.how-to-help__block {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  max-width: 20.625rem;
  background-color: white;
  border-radius: 3.4375rem 0 3.4375rem 0;
}
.how-to-help__title-container {
  padding: 1.875rem 0.875rem 1.25rem 2.875rem;
}
.how-to-help__title {
  font-family: "Playfair Display";
  font-size: 2.5rem;
  line-height: 1;
  color: black;
  font-weight: 900;
}
.how-to-help__form {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  padding: 0 1.25rem;
}
.how-to-help__form-input-container {
  position: relative;
  display: flex;
  height: 3.5rem;
  width: 100%;
  border-top: 0.0625rem solid rgba(0, 0, 0, 0.3);
  border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.3);
}
.how-to-help__form-label {
  position: absolute;
  left: 0;
  top: 1.0625rem;
  font-size: 1rem;
  line-height: 1.3;
  font-weight: 700;
  color: black;
  z-index: 1;
  margin-bottom: 0;
}
.how-to-help__form-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 3.375rem;
  padding: 0 1.875rem 0 3.75rem;
  font-size: 1rem;
  line-height: 1.3;
  color: #646464;
  border: 0;
}
.how-to-help__form-submit {
  position: absolute !important;
  top: 0.1875rem;
  right: 0.625rem;
  width: 3.125rem;
  height: 3.125rem;
  border: 0;
  z-index: 3;
  background: url("/public/svg/search-icon.svg") center center/1.4375rem no-repeat;
}
.how-to-help__form-submit:before {
  top: 0;
}
.how-to-help__tags {
  padding: 1.875rem 2.875rem 2rem;
}
.how-to-help__close {
  display: none;
}
.how-to-help__form-container {
  position: relative;
}
.how-to-help__search-results {
  position: absolute;
  top: 3.5rem;
  left: -1.25rem;
  padding: 0 1.25rem;
  width: calc(100% + 2.5rem);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: white;
  border-radius: 0 0 1.25rem 1.25rem;
  opacity: 0;
  visibility: hidden;
  height: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}
.how-to-help__search-results ul li a {
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.375rem 0;
}
.how-to-help__search-results--show {
  height: 18.75rem;
  opacity: 1;
  visibility: visible;
}
@media (max-width: 1300px) {
  .how-to-help {
    left: 6.25rem;
  }
}
@media (max-height: 820px) {
  .how-to-help {
    margin-top: 0;
  }
}
@media (max-width: 1199.98px) {
  .how-to-help {
    width: 17.5rem;
  }
  .how-to-help__block {
    padding-top: 0.375rem;
  }
  .how-to-help__title-container {
    padding: 1.25rem 1.25rem 0.9375rem 1.875rem;
  }
  .how-to-help__title {
    font-size: 2rem;
    line-height: 2.125rem;
  }
  .how-to-help__tags {
    padding: 1.5625rem 0.875rem 1.5625rem 1.375rem;
  }
  .how-to-help__form {
    padding: 0 0.8125rem;
  }
  .how-to-help__form-input-container {
    height: 3rem;
  }
  .how-to-help__form-label {
    font-size: 0.875rem;
    top: 0.875rem;
  }
  .how-to-help__form-input {
    font-size: 0.875rem;
    padding-left: 3.3125rem;
    padding-right: 1.25rem;
    height: 2.875rem;
  }
  .how-to-help__form-submit {
    top: 0.0625rem;
    right: 0.3125rem;
    width: 2.375rem;
    height: 2.875rem;
    background-size: 1.125rem;
  }
  .how-to-help__search-results {
    top: 2.875rem;
    padding: 0 0.8125rem;
    left: -0.8125rem;
    width: calc(100% + 1.625rem);
  }
}
@media (max-width: 991.98px) {
  .how-to-help {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    transform: translateX(-50%) translateY(-50%) scale(0.9);
    height: auto;
    width: 14.75rem;
    opacity: 0;
    visibility: hidden;
  }
  .how-to-help--show {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) translateY(-50%) scale(1);
    transition: opacity 0.5s ease, visibility 0.5s ease, transform 0.5s ease;
  }
  .how-to-help__el {
    position: relative;
    margin-top: 0;
    top: auto;
    transform: translateY(0);
  }
  .how-to-help__title-container {
    padding: 0rem 1.25rem 0.9375rem 1.875rem;
    margin-top: -0.625rem;
  }
  .how-to-help__close {
    display: inline-flex;
    align-self: flex-end;
    opacity: 1;
    visibility: visible;
    justify-content: flex-end;
    height: 2.5rem;
    font-size: 0.6875rem;
    line-height: 1.25rem;
    font-weight: 500;
    padding-right: 1.625rem;
    margin-right: 0.6rem;
    background: transparent url("/public/svg/close-small.svg") center right/1.25rem no-repeat;
  }
  .how-to-help__close:focus-visible {
    position: relative;
    outline: 0;
    border: 0;
  }
  .how-to-help__close:focus-visible:before {
    position: absolute;
    left: calc(-0rem / 2);
    top: calc(-0rem / 2);
    width: calc(100% + 0rem);
    height: calc(100% + 0rem);
    outline: 0.1875rem solid #646464;
    -moz-outline-radius: 0.625rem;
    border-radius: 0.625rem;
    content: "";
  }
}
@media (max-width: 991.98px) and (max-width: 1199.98px) {
  .how-to-help__close:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
@media (max-width: 767.98px) {
  .how-to-help__search-results {
    height: 12.5rem;
  }
}

.tags {
  display: flex;
  flex-flow: row wrap;
}

.tag {
  display: flex;
  align-items: center;
  height: 1.9375rem;
  padding: 0 0.875rem;
  border: 0.0625rem solid #b6bac2;
  border-radius: 0.3125rem;
  margin: 0 0.375rem 0.375rem 0;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  color: black;
  text-decoration: none;
  border-width: 0.0625rem !important;
}
.tag:focus-visible {
  position: relative;
  outline: 0;
  border: 0;
  border: 0.0625rem solid transparent !important;
}
.tag:focus-visible:before {
  position: absolute;
  left: calc(-0 / 2);
  top: calc(-0rem / 2);
  width: calc(100% + 0);
  height: calc(100% + 0rem);
  outline: 0.1875rem solid #646464;
  -moz-outline-radius: 0.3125rem;
  border-radius: 0.3125rem;
  content: "";
}
@media (max-width: 1199.98px) {
  .tag:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
.tag:hover {
  color: white !important;
  background-color: #0E7A3D;
  border-color: #0E7A3D;
}
.tag--alert {
  color: white !important;
  background-color: #C95100;
  border-color: #C95100;
}
.tag--large-rounded {
  height: 2.625rem;
  font-size: 0.9375rem;
  line-height: 1.3125rem;
  border-radius: 1.3125rem;
  border-color: #8D8D8D;
  padding: 0 1.25rem;
  margin-right: 0.625rem;
}
@media (max-width: 1199.98px) {
  .tag {
    height: 1.75rem;
    padding: 0 0.75rem;
    margin: 0 0.3125rem 0.3125rem 0;
    font-size: 0.6875rem;
    line-height: 0.875rem;
  }
}

.articles {
  padding: 3.75rem 0 0;
  max-width: 1220px;
}
.articles__row {
  padding-bottom: 0.9375rem;
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}
.articles__row > div[class^=col-] {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.articles__row--start {
  align-items: flex-start;
}
.articles__row--stretch {
  align-items: stretch;
}
.articles__list-col {
  align-self: flex-start;
}
.articles__col--start {
  align-self: flex-start;
}
.articles--file {
  padding: 6.25rem 0 0 0;
}
.articles--file .articles__row > div[class^=col-] {
  margin-bottom: 1.25rem;
}
.articles--page-topical .article--list {
  margin-bottom: 4rem;
}
@media (max-width: 1399.98px) {
  .articles {
    padding: 4.375rem 0 0;
  }
}
@media (max-width: 1199.98px) {
  .articles {
    padding: 2.5rem 0 0;
  }
}
@media (max-width: 991.98px) {
  .articles__col--mobile-end {
    align-self: flex-end;
  }
  .articles__list-col {
    display: flex;
    justify-content: center;
  }
  .articles__list-col--mobile-2 {
    order: 2 !important;
  }
  .articles__list-col--mobile-3 {
    order: 3 !important;
    align-self: flex-end;
  }
  .articles__list-col--mobile-4 {
    order: 4 !important;
  }
  .articles__list-col--mobile-5 {
    order: 5 !important;
  }
  .articles--topical .articles__row > div[class^=col-]:nth-of-type(3) .article__image-container {
    height: 9.625rem;
  }
  .articles--topical .articles__row > div[class^=col-]:nth-of-type(3) .article__text-container {
    min-height: 9.625rem;
  }
  .articles--topical .articles__row > div[class^=col-]:nth-of-type(4) .article__image-container {
    height: 11.75rem;
  }
  .articles--topical .articles__row > div[class^=col-]:nth-of-type(4) .article__text-container {
    min-height: 11.75rem;
  }
  .articles--topical .articles__row > div[class^=col-]:nth-of-type(6) {
    order: 7;
  }
  .articles--topical .articles__row > div[class^=col-]:nth-of-type(7) {
    order: 6;
    align-self: flex-end;
  }
  .articles--topical .articles__row > div[class^=col-]:nth-of-type(8) {
    order: 8;
  }
  .articles--topical .articles__row > div[class^=col-]:nth-of-type(9) {
    order: 9;
  }
  .articles--topical .articles__row > div[class^=col-]:nth-of-type(10) {
    order: 10;
  }
  .articles--topical .articles__row > div[class^=col-]:nth-of-type(10) .article__image-container {
    height: 12.5rem;
  }
  .articles--topical .articles__row > div[class^=col-]:nth-of-type(10) .article__text-container {
    min-height: 12.5rem;
  }
  .articles--topical .articles__row > div[class^=col-]:nth-of-type(11) {
    order: 12;
  }
  .articles--topical .articles__row > div[class^=col-]:nth-of-type(12) {
    order: 11;
  }
  .articles--topical .articles__row > div[class^=col-]:nth-of-type(12) .article__image-container {
    height: 12.5rem;
  }
  .articles--topical .articles__row > div[class^=col-]:nth-of-type(12) .article__text-container {
    min-height: 12.5rem;
  }
  .articles--file {
    padding: 6.25rem 0 0 0;
  }
  .articles--file .articles__row > div[class^=col-] {
    margin-bottom: 0.625rem;
  }
  .articles--file .articles__row > div[class^=col-].articles__list-col {
    margin-bottom: 0;
  }
}
@media (max-width: 767.98px) {
  .articles {
    padding: 1.875rem 0 0 0;
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .articles__row {
    --bs-gutter-x: 0.625rem;
  }
  .articles__row > div[class^=col-]:first-of-type {
    align-self: flex-end;
  }
  .articles__list-col--larger {
    padding: 0 3.75rem;
    margin: 1.875rem 0 3.125rem !important;
  }
  .articles--topical {
    padding: 2.5rem 0 0 0;
  }
}
@media (max-width: 575.98px) {
  .articles__row {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .articles__row > div[class^=col-] {
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
    padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
  }
  .articles__list-col--mobile-4, .articles__list-col--mobile-5 {
    display: none;
  }
}

.article {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1.875rem;
  text-decoration: none;
  color: black;
}
.article:focus-visible {
  position: relative;
  outline: 0;
  border: 0;
}
.article:focus-visible:before {
  position: absolute;
  left: calc(-0rem / 2);
  top: calc(-0rem / 2);
  width: calc(100% + 0rem);
  height: calc(100% + 0rem);
  outline: 0.1875rem solid #646464;
  -moz-outline-radius: 1.25rem;
  border-radius: 1.25rem;
  content: "";
}
@media (max-width: 1199.98px) {
  .article:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
.article:hover .article__title {
  text-decoration: underline;
}
.article__image-container {
  position: relative;
  display: flex;
  overflow: hidden;
  border-radius: 1.25rem;
  height: 20rem;
  order: 1;
}
.article__image-container--small {
  height: 16.5rem;
}
.article__image-container--medium {
  height: 18.25rem;
}
.article__image-container--large {
  height: 22.9375rem;
}
.article__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.article__play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.75);
}
.article__play-icon--large {
  width: 7.5rem;
  height: 7.5rem;
}
.article__text-container {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 1.25rem;
  background-color: #C9D32C;
  min-height: 18.3125rem;
}
.article__text-container--large {
  min-height: 23.1875rem;
}
.article__text-container--orange {
  background-color: #C95100;
}
.article__text-container--orange .article__text {
  color: white;
}
.article__text-container--green {
  background-color: #0E7A3D;
}
.article__text-container--green .article__text {
  color: white;
}
.article__text-container--with-max-height {
  max-height: 18.125rem;
}
.article__text-container--force-height-auto {
  height: auto !important;
}
.article__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Playfair Display";
  font-size: 2.1875rem;
  line-height: 2.3125rem;
  font-weight: 900;
  color: black;
  text-align: center;
  width: 100%;
  padding: 0 1.25rem;
}
.article__content {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 1.25rem;
  order: 2;
}
.article__meta {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 0.5625rem;
  padding-left: 0;
  justify-content: center;
  list-style-type: none;
}
.article__meta li {
  font-size: 0.75rem;
  line-height: 0.9375rem;
  color: black;
  padding: 0 0.5rem;
  border-left: 0.0625rem solid black;
}
.article__meta li:first-child {
  border-left: 0;
}
.article__meta li a {
  text-decoration: none;
}
.article__meta li a:hover {
  text-decoration: underline;
}
.article__title {
  font-family: "Playfair Display";
  font-size: 1.3125rem;
  line-height: 1.5625rem;
  color: black;
  text-align: center;
  font-weight: 900;
  order: 1;
}
.article__title--list {
  position: relative;
  font-size: 1.875rem;
  line-height: 2rem;
  color: white;
  margin-bottom: 0.75rem;
  padding: 0 0.25rem;
}
.article__title a {
  text-decoration: none;
}
.article__title a:hover {
  text-decoration: underline;
}
.article__list-items {
  position: relative;
  z-index: 2;
  padding-left: 0;
  margin-bottom: 0;
  order: 2;
  list-style-type: none;
}
.article__list-items > li {
  border-top: 0.0625rem solid rgba(255, 255, 255, 0.5);
}
.article__list-items--large .article__list-item {
  padding: 1.25rem 0.3125rem;
}
.article__list-items--large .article__list-item-title {
  font-size: 1.3125rem;
  line-height: 1.25rem;
}
.article__list-items--large .article__list-item-meta {
  font-size: 0.9375rem;
  line-height: 1.25rem;
}
.article__list-item {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 0.3125rem;
  text-decoration: none;
}
.article__list-item:hover .article__list-item-title,
.article__list-item:hover .article__list-item-meta, .article__list-item:focus-visible .article__list-item-title,
.article__list-item:focus-visible .article__list-item-meta {
  color: white;
  text-decoration: underline;
}
.article__list-item:focus-visible {
  position: relative;
  outline: 0;
  border: 0;
}
.article__list-item:focus-visible:before {
  position: absolute;
  left: calc(-0.625rem / 2);
  top: calc(-0rem / 2);
  width: calc(100% + 0.625rem);
  height: calc(100% + 0rem);
  outline: 0.1875rem solid #646464;
  -moz-outline-radius: 0.9375rem;
  border-radius: 0.9375rem;
  content: "";
}
@media (max-width: 1199.98px) {
  .article__list-item:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
.article__list-item:focus-visible:before {
  outline-color: white !important;
}
.article__list-item--follow {
  padding-bottom: 0;
}
.article__list-item--follow .article__list-item-title {
  color: white;
  text-decoration: none !important;
}
.article__list-item-title {
  font-family: "interstate", sans-serif;
  font-size: 1.125rem;
  line-height: 1.1875rem;
  color: black;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0;
}
.article__list-item-meta {
  font-family: "interstate", sans-serif;
  font-size: 0.875rem;
  line-height: 1.1875rem;
  color: black;
  text-align: center;
}
.article__socials-list {
  display: flex;
  flex-flow: row nowrap;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 0.625rem;
  list-style-type: none;
}
.article__socials-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.9375rem;
  height: 1.9375rem;
  margin: 0 0.3125rem;
  border-radius: 50%;
}
.article__socials-link:focus-visible {
  position: relative;
  outline: 0;
  border: 0;
}
.article__socials-link:focus-visible:before {
  position: absolute;
  left: calc(-0rem / 2);
  top: calc(-0rem / 2);
  width: calc(100% + 0rem);
  height: calc(100% + 0rem);
  outline: 0.1875rem solid #646464;
  -moz-outline-radius: 50%;
  border-radius: 50%;
  content: "";
}
@media (max-width: 1199.98px) {
  .article__socials-link:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
.article__socials-link:hover {
  opacity: 0.8;
}
.article__socials-link--instagram {
  background: url("/public/svg/footer-ig.svg") center center/100% no-repeat;
}
.article__socials-link--twitter {
  background: url("/public/svg/footer-tw.svg") center center/100% no-repeat;
}
.article__socials-link--facebook {
  background: url("/public/svg/footer-fb.svg") center center/100% no-repeat;
}
.article__intro-title {
  font-family: "Playfair Display";
  font-size: 3.0625rem;
  line-height: 3.375rem;
  font-weight: 900;
  color: white;
  text-align: center;
}
.article__intro {
  font-size: 1.0625rem;
  line-height: 1.5625rem;
  margin-bottom: 0;
  color: white;
  text-align: center;
  margin-top: 1rem;
  max-width: 28.125rem;
}
.article--list {
  position: relative;
  overflow: hidden;
  padding: 1.25rem;
  background-color: #64AA78;
  border-radius: 2.8125rem 0 2.8125rem 0;
  justify-content: center;
}
.article--list:before {
  position: absolute;
  width: 22.5rem;
  height: 66.1875rem;
  left: -3.25rem;
  bottom: -11.125rem;
  content: "";
  pointer-events: none;
  background: url("/public/svg/article-list-bg-green.svg") left bottom/100% no-repeat;
}
.article--list:hover .article__title {
  text-decoration: none;
}
.article--list--blue {
  background-color: #3BA6CC;
}
.article--list--blue:before {
  bottom: -16.5rem;
  pointer-events: none;
  background: url("/public/svg/article-list-bg-blue.svg") left bottom/100% no-repeat;
}
.article--list--blue-light {
  background-color: #9FD2E4;
}
.article--list--blue-light:before {
  bottom: -16.5rem;
  pointer-events: none;
  background: url("/public/svg/article-list-bg-light-blue.svg") left bottom/100% no-repeat;
}
.article--list--orange {
  background-color: #C95100;
}
.article--list--orange:before {
  display: none;
}
.article--list--lower-bg:before {
  bottom: -23.625rem;
}
.article--list--file {
  height: 100%;
}
.article--list--file .article__title--list {
  margin-bottom: 1.5rem;
}
.article--list--file .article__list-item {
  padding: 0.625rem;
}
.article--list--alt-list-item {
  justify-content: flex-start;
  padding-top: 1.875rem;
}
.article--list--alt-list-item .article__list-item {
  min-height: 4.375rem;
  padding: 0.625rem 1.25rem;
  justify-content: flex-start;
}
.article--in-news-slider, .article--white-content-big {
  margin-bottom: 0;
  height: 100%;
}
.article--in-news-slider .article__image-container,
.article--in-news-slider .article__text-container, .article--white-content-big .article__image-container,
.article--white-content-big .article__text-container {
  height: 18.125rem;
  min-height: 18.125rem;
  border-radius: 0.9375rem 0.9375rem 0 0;
}
.article--in-news-slider .article__content, .article--white-content-big .article__content {
  background-color: white;
  border-radius: 0 0 0.9375rem 0.9375rem;
  flex-grow: 1;
}
.article--in-news-slider .article__title, .article--white-content-big .article__title {
  font-size: 1.3125rem;
  line-height: 1.5625rem;
}
.article--in-news-slider:focus-visible .article__title, .article--white-content-big:focus-visible .article__title {
  text-decoration: underline;
}
.article--in-news-slider:focus-visible {
  position: relative;
  outline: 0;
  border: 0;
}
.article--in-news-slider:focus-visible:before {
  position: absolute;
  left: calc(-0rem / 2);
  top: calc(-0rem / 2);
  width: calc(100% + 0rem);
  height: calc(100% + 0rem);
  outline: 0.1875rem solid #646464;
  -moz-outline-radius: 0.9375rem;
  border-radius: 0.9375rem;
  content: "";
}
@media (max-width: 1199.98px) {
  .article--in-news-slider:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
.article--in-news-slider:focus-visible:before {
  outline-color: white;
}
.article--in-news-articles .article__image-container,
.article--in-news-articles .article__text-container {
  height: 17.1875rem;
  min-height: 17.1875rem;
}
.article--white-content-big:focus-visible {
  position: relative;
  outline: 0;
  border: 0;
}
.article--white-content-big:focus-visible:before {
  position: absolute;
  left: calc(-0rem / 2);
  top: calc(-0rem / 2);
  width: calc(100% + 0rem);
  height: calc(100% + 0rem);
  outline: 0.1875rem solid #646464;
  -moz-outline-radius: 0.9375rem;
  border-radius: 0.9375rem;
  content: "";
}
@media (max-width: 1199.98px) {
  .article--white-content-big:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
.article--white-content-big .article__image-container--large {
  height: 22.625rem;
}
.article--white-content-big .article__text {
  color: white;
}
.article--white-content-big .article__content {
  padding: 1.4375rem 1.875rem 2.5rem;
}
.article--white-content-big .article__meta {
  margin-bottom: 1rem;
}
.article--white-content-big .article__title {
  font-size: 1.3125rem;
  line-height: 1.5625rem;
}
.article--intro-block {
  align-items: center;
  justify-content: center;
  padding: 3.25rem 2.5rem;
  background-color: #2A3957;
  border-radius: 4.6875rem 0 4.6875rem 0;
  margin-bottom: 0;
}
.article--intro-block--lime {
  background-color: #C9D32C;
}
.article--intro-block--lime .article__intro-title,
.article--intro-block--lime .article__intro {
  color: black;
}
.article--intro-block--orange {
  background-color: #C95100;
}
.article--intro-block--orange .article__intro-title,
.article--intro-block--orange .article__intro {
  color: white;
}
.article--green, .article--blue, .article--lime {
  margin-bottom: 0;
}
.article--green .article__image-container, .article--blue .article__image-container, .article--lime .article__image-container {
  height: 28.75rem;
}
.article--green .article__content, .article--blue .article__content, .article--lime .article__content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2.0625rem 1.875rem;
  min-height: 11.125rem;
  background-color: rgba(14, 122, 61, 0.5);
}
.article--green .article__title, .article--blue .article__title, .article--lime .article__title {
  position: relative;
  color: white;
  text-decoration-thickness: 0.0625rem !important;
  word-break: break-word;
}
.article--green:focus-visible .article__title, .article--blue:focus-visible .article__title, .article--lime:focus-visible .article__title {
  text-decoration: underline;
}
.article--green--gradient .article__content, .article--blue--gradient .article__content, .article--lime--gradient .article__content {
  justify-content: center;
  min-height: 8.125rem;
  background: #107b40;
  background: linear-gradient(45deg, #107b40 0%, #44a09f 100%);
}
.article--blue .article__content {
  justify-content: center;
  background-color: #2A3957;
  min-height: 0;
}
.article--lime .article__content {
  justify-content: center;
  background-color: #C9D32C;
  min-height: 0;
}
.article--lime .article__title {
  color: black;
}
@media (max-width: 1399.98px) {
  .article--in-related-articles .article__image-container {
    height: 18.75rem;
  }
  .article--green .article__image-container, .article--blue .article__image-container, .article--lime .article__image-container {
    height: 28.75rem;
  }
}
@media (max-width: 1199.98px) {
  .article__image-container--small {
    height: 16.4375rem;
  }
  .article__text-container {
    min-height: 18.75rem;
  }
  .article__meta {
    margin-bottom: 0.3125rem;
  }
  .article__meta li {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
  .article__text {
    font-size: 1.875rem;
    line-height: 2rem;
  }
  .article__list-item-meta {
    font-size: 1rem;
    line-height: 1.375rem;
  }
  .article__intro-title {
    font-size: 2.75rem;
    line-height: 3.125rem;
  }
  .article__title--list {
    font-size: 1.625rem;
    line-height: 1.875rem;
  }
  .article--in-news-slider .article__meta, .article--white-content-big .article__meta {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
  .article--white-content-big .article__content {
    padding: 1.4375rem 0.625rem 1.875rem;
  }
  .article--in-related-articles .article__image-container {
    height: 14.75rem;
  }
  .article--green .article__image-container, .article--blue .article__image-container, .article--lime .article__image-container {
    height: 25rem;
  }
  .article--green .article__content, .article--blue .article__content, .article--lime .article__content {
    min-height: 9.5rem;
    padding: 1.25rem;
  }
}
@media (max-width: 991.98px) {
  .article {
    margin-bottom: 1rem;
  }
  .article__intro {
    max-width: 34.375rem;
  }
  .article--list {
    margin-bottom: 0.625rem !important;
    width: 100%;
    height: auto !important;
  }
  .article__list-items--large .article__list-item {
    padding: 0.625rem 0.3125rem;
  }
  .article__list-items--large .article__list-item-title {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
  .article__list-items--large .article__list-item-meta {
    font-size: 0.6875rem;
    line-height: 0.8125rem;
  }
  .article--intro-block {
    padding: 3rem 2.1875rem;
    height: auto !important;
  }
  .article--list:before {
    left: -0.75rem;
    bottom: -42.5rem;
    width: 40.5rem;
    height: 119.125rem;
  }
  .article--in-news-slider .article__image-container, .article--white-content-big .article__image-container {
    height: 15rem;
    min-height: 0;
  }
  .article--in-news-slider .article__text-container, .article--white-content-big .article__text-container {
    height: 15rem !important;
    min-height: 15rem;
  }
  .article--in-related-slider .article__image-container {
    height: 14.75rem;
  }
  .article--green, .article--blue, .article--lime {
    margin-bottom: 0;
  }
}
@media (max-width: 767.98px) {
  .article__content {
    padding: 0.75rem 0.75rem;
  }
  .article__title {
    font-size: 1.0625rem;
    line-height: 1.1875rem;
  }
  .article__title--list {
    font-size: 1.125rem;
    line-height: 1;
    margin-bottom: 0.625rem;
  }
  .article__text {
    font-size: 1.375rem;
    line-height: 1.5rem;
    padding: 0 0.25rem;
  }
  .article__meta {
    justify-content: center;
  }
  .article__meta li {
    font-size: 0.6875rem;
    line-height: 0.8125rem;
    padding: 0 0.25rem;
  }
  .article__list-item {
    padding: 0.25rem 0.3125rem;
  }
  .article__list-item-title {
    font-size: 0.875rem;
    line-height: 1;
  }
  .article__list-item-meta {
    font-size: 0.5625rem;
    line-height: 0.875rem;
  }
  .article__play-icon--large {
    width: 3.125rem;
    height: 3.125rem;
  }
  .article--intro-block {
    max-width: calc(100% - 6.25rem);
    margin-left: 3.125rem;
    border-radius: 2.8125rem 0 2.8125rem 0;
    padding: 2.25rem 1.25rem;
  }
  .article__intro-title {
    font-size: 1.875rem;
    line-height: 2rem;
  }
  .article__intro {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
  .article--list {
    padding: 1rem;
    border-radius: 1.625rem 0 1.625rem 0;
  }
  .article--list:before {
    width: calc(100% + 2.5rem);
    left: -1.25rem;
  }
  .article--in-news-slider:focus-visible:before, .article--white-content-big:focus-visible:before {
    border-radius: 0.625rem;
  }
  .article--in-news-slider .article__image-container, .article--white-content-big .article__image-container {
    height: 12.625rem;
    border-radius: 0.625rem 0.625rem 0 0;
  }
  .article--in-news-slider .article__content, .article--white-content-big .article__content {
    border-radius: 0 0 0.625rem 0.625rem;
  }
  .article--in-news-slider .article__text-container, .article--white-content-big .article__text-container {
    height: 12.625rem !important;
    min-height: 12.625rem;
    border-radius: 0.625rem 0.625rem 0 0;
  }
  .article--in-news-slider .article__title, .article--white-content-big .article__title {
    font-size: 1.0625rem;
    line-height: 1.1875rem;
  }
  .article--white-content-big .article__image-container {
    height: 9.625rem !important;
  }
  .article--white-content-big .article__image-container--large {
    height: 13.125rem !important;
  }
  .article--white-content-big .article__content {
    padding: 1.4375rem 0.625rem 0.625rem;
    border-radius: 0 0 0.625rem 0.625rem;
  }
  .article--white-content-big--bigger .article__image-container {
    height: 12.625rem !important;
  }
  .article--green .article__image-container, .article--blue .article__image-container, .article--lime .article__image-container {
    height: 15.5rem;
  }
  .article--green .article__content, .article--blue .article__content, .article--lime .article__content {
    min-height: 7.9375rem;
    padding: 0.75rem;
  }
  .article--green--gradient .article__content, .article--blue--gradient .article__content, .article--lime--gradient .article__content {
    min-height: 5rem;
  }
  .article--blue .article__content, .article--lime .article__content {
    min-height: 5rem;
  }
}
@media (max-width: 575.98px) {
  .article {
    margin-bottom: 0.625rem;
  }
  .article:focus-visible {
    position: relative;
    outline: 0;
    border: 0;
  }
  .article:focus-visible:before {
    position: absolute;
    left: calc(-0rem / 2);
    top: calc(-0rem / 2);
    width: calc(100% + 0rem);
    height: calc(100% + 0rem);
    outline: 0.1875rem solid #646464;
    -moz-outline-radius: 0.625rem;
    border-radius: 0.625rem;
    content: "";
  }
}
@media (max-width: 575.98px) and (max-width: 1199.98px) {
  .article:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
@media (max-width: 575.98px) {
  .article__image-container {
    height: 10.9375rem;
    border-radius: 0.625rem;
  }
  .article__image-container--small {
    height: 9.625rem;
  }
  .article__image-container--large {
    height: 12.6875rem;
  }
}
@media (max-width: 575.98px) {
  .article__text-container {
    min-height: 10rem;
    border-radius: 0.625rem;
  }
}
@media (max-width: 575.98px) {
  .article__content {
    padding: 0.75rem 0.25rem;
  }
}
@media (max-width: 575.98px) {
  .article__list-item:focus-visible {
    position: relative;
    outline: 0;
    border: 0;
  }
  .article__list-item:focus-visible:before {
    position: absolute;
    left: calc(-0.625rem / 2);
    top: calc(-0rem / 2);
    width: calc(100% + 0.625rem);
    height: calc(100% + 0rem);
    outline: 0.1875rem solid #646464;
    -moz-outline-radius: 0.625rem;
    border-radius: 0.625rem;
    content: "";
  }
}
@media (max-width: 575.98px) and (max-width: 1199.98px) {
  .article__list-item:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
@media (max-width: 575.98px) {
  .article--list {
    max-width: 14.75rem;
  }
  .article--list:before {
    bottom: -6.25rem;
  }
  .article--list--file .article__list-item {
    padding: 0.625rem 0;
  }
}
@media (max-width: 575.98px) {
  .article--in-news-slider .article__meta li, .article--white-content-big .article__meta li {
    font-size: 0.5625rem;
    line-height: 0.6875rem;
  }
}
@media (max-width: 575.98px) {
  .article--in-news-slider:focus-visible:before {
    outline-color: white;
  }
}
@media (max-width: 575.98px) {
  .article--in-related-articles:focus-visible:before {
    border-radius: 0.625rem;
  }
}
@media (max-width: 575.98px) {
  .article--green, .article--blue, .article--lime {
    margin-bottom: 0;
  }
}

.quote {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  text-align: center;
}
.quote__author {
  font-size: 1.125rem;
  line-height: 1.1875rem;
  margin-bottom: 1.625rem;
}
.quote__quote {
  display: flex;
  font-family: "Playfair Display";
  font-weight: 400;
  font-size: 2.75rem;
  line-height: 3.75rem;
}
.quote__quote a {
  text-decoration: none;
}
.quote__quote a:hover {
  text-decoration: underline;
  text-decoration-thickness: 0.125rem;
}
.quote__type {
  position: relative;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-top: 5.625rem;
}
.quote__type:before {
  position: absolute;
  width: 0.0625rem;
  height: 3.625rem;
  top: -0.75rem;
  left: 50%;
  transform: translateY(-100%);
  background-color: black;
  content: "";
}
.quote__type a {
  text-decoration: none;
}
.quote__type a:focus-visible {
  position: relative;
  outline: 0;
  border: 0;
}
.quote__type a:focus-visible:before {
  position: absolute;
  left: calc(-0.625rem / 2);
  top: calc(-0.375rem / 2);
  width: calc(100% + 0.625rem);
  height: calc(100% + 0.375rem);
  outline: 0.1875rem solid #646464;
  -moz-outline-radius: 0.625rem;
  border-radius: 0.625rem;
  content: "";
}
@media (max-width: 1199.98px) {
  .quote__type a:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
.quote__type a:hover {
  text-decoration: underline;
}
@media (max-width: 991.98px) {
  .quote__author {
    font-size: 1rem;
  }
  .quote__quote {
    font-size: 2.25rem;
    line-height: 2.875rem;
  }
  .quote__type {
    font-size: 1rem;
  }
}
@media (max-width: 767.98px) {
  .quote__author {
    font-size: 0.6875rem;
  }
  .quote__quote {
    font-size: 1.625rem;
    line-height: 2.25rem;
  }
  .quote__type {
    font-size: 0.6875rem;
    margin-top: 4.75rem;
  }
  .quote__type:before {
    height: 2.75rem;
  }
}

.article-slider {
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 1.25rem 0 4.875rem;
}
.article-slider__wrapper {
  max-width: 30.5rem;
  overflow: visible;
}
.article-slider__slide {
  padding: 0 0.75rem;
}
.article-slider__slide--file {
  padding: 0;
  margin-bottom: 1.375rem;
}
.article-slider__slide--file .article-slider__slide-inner {
  height: auto;
  flex-flow: column nowrap;
  text-decoration: none;
  border-radius: 0;
}
.article-slider__slide--file .article-slider__slide-inner:hover .article-slider__image, .article-slider__slide--file .article-slider__slide-inner:focus-visible .article-slider__image {
  opacity: 1;
}
.article-slider__slide--file .article-slider__slide-inner:hover .article-slider__bottom-title, .article-slider__slide--file .article-slider__slide-inner:focus-visible .article-slider__bottom-title {
  text-decoration: underline;
}
.article-slider__slide--file .article-slider__slide-inner:before {
  display: none;
}
.article-slider__slide--file .article-slider__image-container {
  position: relative;
  height: 20.1875rem;
  border-radius: 1.25rem;
  overflow: hidden;
}
.article-slider__slide--file .article-slider__image-container:before {
  background-color: black;
  opacity: 0.4;
  mix-blend-mode: normal;
}
.article-slider__slide--file .article-slider__image-container:after {
  background-color: #0E7A3D;
}
.article-slider__slide--file .article-slider__title {
  font-size: 2.875rem;
  line-height: 3.125rem;
  font-weight: 400;
}
.article-slider__slide--file--small .article-slider__slide-inner:hover .article-slider__bottom-title {
  text-decoration-thickness: 0.0625rem;
}
.article-slider__slide--file--small .article-slider__image-container {
  height: 20.25rem;
}
.article-slider__slide--file--small .article-slider__title {
  font-size: 2.3125rem;
  line-height: 2.625rem;
}
.article-slider__slide--file--small .article-slider__bottom-title {
  font-size: 1.375rem;
  line-height: 1.5625rem;
  color: white;
}
.article-slider__slide--file--dark .article-slider__slide-inner:hover .article-slider__image-container:before {
  opacity: 0.2;
}
.article-slider__slide--file--dark .article-slider__image-container:before {
  opacity: 0.5;
}
.article-slider__slide--file--dark .article-slider__image-container:after {
  display: none;
}
.article-slider__slide-inner {
  display: flex;
  position: relative;
  width: 100%;
  height: 25rem;
  border-radius: 1.25rem;
  overflow: hidden;
}
.article-slider__slide-inner:hover .article-slider__image-container:before, .article-slider__slide-inner:hover .article-slider__image-container:after, .article-slider__slide-inner:focus-visible .article-slider__image-container:before, .article-slider__slide-inner:focus-visible .article-slider__image-container:after {
  opacity: 0;
}
.article-slider__slide-inner:hover .article-slider__image, .article-slider__slide-inner:focus-visible .article-slider__image {
  opacity: 0.7;
}
.article-slider__slide-inner:hover .article-slider__title, .article-slider__slide-inner:focus-visible .article-slider__title {
  text-decoration: underline;
  text-decoration-thickness: 0.125rem;
}
.article-slider__slide-inner:focus-visible .article-slider__content-container {
  border-color: white;
}
.article-slider__image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.article-slider__image-container:before, .article-slider__image-container:after {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #54A176;
  opacity: 0.5;
  content: "";
  z-index: 2;
  border-radius: 1.25rem;
  transition: opacity 0.3s ease;
}
.article-slider__image-container:before {
  mix-blend-mode: multiply;
}
.article-slider__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  transition: opacity 0.3s ease;
}
.article-slider__content-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 1.25rem;
  text-align: center;
  border: 0.1875rem solid transparent;
  border-radius: 20px;
  transition: border 0.3s ease;
}
.article-slider__subtitle {
  font-size: 1.1875rem;
  line-height: 1.4375rem;
  color: white;
  font-weight: 300;
  margin-bottom: 0.625rem;
}
.article-slider__title {
  font-family: "Playfair Display";
  font-size: 1.875rem;
  line-height: 2.3125rem;
  color: white;
  font-weight: 700;
  text-decoration-thickness: 0.0625rem;
  text-decoration-color: transparent;
  transition: all 0.3s ease;
}
.article-slider__tags {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 0.625rem;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
}
.article-slider__tags li {
  font-size: 1.125rem;
  line-height: 1.625rem;
  padding: 0 0.625rem;
  color: white;
  border-left: 0.125rem solid white;
}
.article-slider__tags li:first-child {
  border-left: 0;
}
.article-slider__bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.375rem 0.9375rem;
}
.article-slider__bottom-title {
  font-family: "Playfair Display";
  font-weight: 500;
  font-size: 1.375rem;
  line-height: 1.5625rem;
  text-align: center;
}
.article-slider__navigation {
  top: 12.625rem;
}
.article-slider__navigation--related {
  display: none;
}
.article-slider__navigation--files {
  display: none;
}
.article-slider__pagination {
  bottom: 0rem !important;
}
.article-slider__news-pagination {
  bottom: 0rem !important;
}
.article-slider__news-slide {
  padding: 0 0.625rem;
  height: 100%;
}
.article-slider__related-article-slide {
  padding: 0 0.625rem;
}
.article-slider__files-slider > .row, .article-slider__files-slider-in-blue > .row {
  margin-left: 0;
}
.article-slider--discover {
  padding: 1.25rem 0 0 0;
}
.article-slider--discover .article-slider__wrapper {
  max-width: 50rem;
}
.article-slider--discover .article-slider__slide-inner {
  display: flex;
  flex-flow: column nowrap;
  height: auto;
  text-decoration: none;
}
.article-slider--discover .article-slider__slide-inner:hover .article-slider__title {
  text-decoration: underline !important;
  text-decoration-thickness: 0.125rem !important;
}
.article-slider--discover .article-slider__slide-inner:focus-visible:before {
  border-radius: 1.25rem;
  border: 0.1875rem solid #646464;
  z-index: 1;
}
.article-slider--discover .article-slider__slide-inner:focus-visible .article-slider__content-container {
  border: 0;
}
.article-slider--discover .article-slider__image-container {
  position: relative;
  height: 30.4375rem;
  border-radius: 1.25rem;
  overflow: hidden;
  order: 1;
}
.article-slider--discover .article-slider__image-container:before, .article-slider--discover .article-slider__image-container:after {
  display: none;
}
.article-slider--discover .article-slider__image {
  opacity: 1 !important;
}
.article-slider--discover .article-slider__content-container {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  width: auto;
  height: auto;
  top: auto;
  left: auto;
  padding: 1.875rem 1.25rem 1.875rem;
  border: 0 !important;
  order: 2;
}
.article-slider--discover .article-slider__subtitle {
  color: black;
  order: 1;
}
.article-slider--discover .article-slider__title {
  font-size: 1.375rem;
  line-height: 2.0625rem;
  color: black !important;
  font-weight: 400;
  text-decoration-thickness: 0.0625rem !important;
  text-decoration: none !important;
  max-width: 27.875rem;
  order: 2;
}
.article-slider--discover .swiper-slide {
  max-width: 24.375rem;
  width: 100%;
  transition: max-width 0.5s ease;
}
.article-slider--discover .swiper-slide.swiper-slide-active {
  max-width: 30.5rem;
}
.article-slider--discover .swiper-slide.swiper-slide-active .article-slider__content-container {
  border: 0;
}
.article-slider--discover .slider__navigation {
  top: 15.25rem;
}
.article-slider--news-slider {
  padding: 2.625rem 0 5.625rem 0;
}
.article-slider--news-slider .article-slider__wrapper {
  max-width: 100%;
  overflow: hidden;
  padding: 0.625rem 0;
}
.article-slider--news-slider .article-slider__navigation {
  top: 17.5rem;
}
.article-slider--news-slider .swiper-slide {
  height: auto !important;
}
.article-slider--files {
  padding: 6.25rem 0 4.1875rem;
}
.article-slider--files .swiper-slide.swiper-slide-active .article-slider__image-container:before {
  opacity: 0 !important;
}
.article-slider--files .swiper-slide.swiper-slide-active .article-slider__image-container:after {
  opacity: 0 !important;
}
.article-slider--files .swiper-slide.swiper-slide-active .article-slider__image {
  opacity: 1;
}
.article-slider--files .swiper-slide.swiper-slide-active .article-slider__bottom-title {
  text-decoration: underline;
}
.article-slider--files--in-blue {
  padding: 5rem 0 0 0;
}
.article-slider--files--in-blue > .container {
  max-width: 1440px;
}
.article-slider--files--in-blue .swiper-slide.swiper-slide-active .article-slider__image-container:before {
  opacity: 0.4 !important;
}
.article-slider--files--in-blue .swiper-slide.swiper-slide-active .article-slider__image-container:after {
  opacity: 0.5 !important;
}
.article-slider--files--in-blue .swiper-slide.swiper-slide-active .article-slider__bottom-title {
  text-decoration: none;
}
.article-slider--files--is-slider .swiper-wrapper {
  flex-wrap: nowrap !important;
}
.article-slider--files--is-slider .article-slider__navigation--files {
  display: flex;
}
.article-slider--related-articles {
  padding: 3.75rem 0;
}
.article-slider--narrow {
  padding-top: 2.8125rem;
}
.article-slider--narrow .article__text-container {
  height: 17.1875rem !important;
}
.article-slider--no-swiper {
  display: flex;
}
.article-slider--no-swiper .article-slider__related-article-slide {
  width: 25%;
}
@media (max-width: 1399.98px) {
  .article-slider__slide--file .article-slider__title {
    font-size: 2.5rem;
    line-height: 2.75rem;
  }
  .article-slider--files--in-blue {
    padding: 2.5rem 0 0 0;
  }
}
@media (max-width: 1199.98px) {
  .article-slider {
    padding: 1.25rem 0 3.625rem;
  }
  .article-slider--discover {
    padding: 0;
  }
  .article-slider__wrapper {
    max-width: 31.25rem;
  }
  .article-slider__navigation {
    top: 12.625rem;
  }
  .article-slider__slide--file {
    margin-bottom: 0.9375rem;
  }
  .article-slider__slide--file .article-slider__image-container {
    height: 13.625rem;
  }
  .article-slider__slide--file .article-slider__title {
    font-size: 1.375rem;
    line-height: 1.625rem;
  }
  .article-slider__slide--file .article-slider__bottom-title {
    font-size: 1.25rem;
    line-height: 1.4375rem;
  }
  .article-slider__slide--file--small .article-slider__bottom-title {
    font-size: 1.25rem;
    line-height: 1.4375rem;
  }
  .article-slider--discover .article-slider__wrapper {
    max-width: 43.75rem;
  }
  .article-slider--discover .article-slider__image-container {
    height: 30.375rem;
  }
  .article-slider--discover .swiper-slide {
    max-width: 26.25rem;
  }
  .article-slider--discover .swiper-slide.swiper-slide-active {
    max-width: 33.375rem;
  }
  .article-slider--discover .slider__navigation {
    top: 15.1875rem;
  }
  .article-slider--news-slider {
    padding: 1.375rem 0 3.75rem 0;
  }
  .article-slider--files {
    padding: 3.75rem 0 1.875rem;
  }
  .article-slider--files--in-blue {
    padding: 2.5rem 0 0 0;
  }
  .article-slider--related-articles {
    padding: 2.5rem 0;
  }
  .article-slider--no-swiper {
    flex-flow: row wrap;
  }
  .article-slider--no-swiper .article-slider__related-article-slide {
    width: 50%;
  }
  .article-slider--no-swiper .article--in-news-articles {
    margin-bottom: 0;
  }
  .article-slider--narrow .article__text-container,
.article-slider--narrow .article__image-container {
    min-height: 14.375rem !important;
    height: 14.375rem !important;
  }
  .article-slider--narrow .article__text {
    font-size: 1.375rem;
    line-height: 1.75rem;
  }
}
@media (max-width: 991.98px) {
  .article-slider {
    padding: 1.25rem 0 2.375rem;
  }
  .article-slider__wrapper {
    max-width: 21.875rem;
  }
  .article-slider__slide-inner {
    height: 19.75rem;
  }
  .article-slider__title {
    font-size: 1.75rem;
    line-height: 2rem;
  }
  .article-slider__tags li {
    font-size: 1.125rem;
    line-height: 1.25rem;
  }
  .article-slider__navigation {
    top: 10.125rem;
  }
  .article-slider__navigation--related {
    display: flex;
  }
  .article-slider__related-article-slide {
    padding: 0 0.3125rem;
  }
  .article-slider--discover .article-slider__wrapper {
    max-width: 37.5rem;
  }
  .article-slider--discover .article-slider__image-container {
    height: 24.125rem;
  }
  .article-slider--discover .article-slider__content-container {
    padding: 1.25rem 0.625rem 1.25rem;
  }
  .article-slider--discover .article-slider__subtitle {
    font-size: 1.25rem;
    line-height: 1.625rem;
    margin-bottom: 0.75rem;
  }
  .article-slider--discover .article-slider__title {
    font-size: 1.375rem;
    line-height: 2rem;
    max-width: 16.625rem;
  }
  .article-slider--discover .swiper-slide {
    max-width: 20rem;
  }
  .article-slider--discover .swiper-slide.swiper-slide-active {
    max-width: 27.125rem;
  }
  .article-slider--discover .slider__navigation {
    top: 12.0625rem;
  }
  .article-slider__related-articles-slider {
    overflow: visible;
  }
  .article-slider--related-articles {
    padding: 1.25rem 0;
  }
  .article-slider--related-articles > .container {
    max-width: 76vw;
  }
  .article-slider--files {
    padding: 3.75rem 0 1.875rem;
  }
  .article-slider--files--in-blue {
    padding: 2.5rem 0 0 0;
  }
  .article-slider--files .swiper-wrapper {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .article-slider--no-swiper {
    padding-top: 1.25rem;
  }
}
@media (max-width: 767.98px) {
  .article-slider {
    padding: 1.25rem 0 2.375rem;
  }
  .article-slider__wrapper {
    max-width: 15.5rem;
  }
  .article-slider__slide {
    padding: 0 0.375rem;
  }
  .article-slider__slide--file {
    padding: 0 !important;
  }
  .article-slider__slide--file .article-slider__title {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
  .article-slider__slide--file .article-slider__bottom {
    padding: 0.625rem 0.625rem;
  }
  .article-slider__slide--file .article-slider__bottom-title {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
  .article-slider__slide-inner {
    height: 14.75rem;
  }
  .article-slider__title {
    font-size: 1.625rem;
    line-height: 1.75rem;
    text-decoration: underline;
    font-weight: 500;
  }
  .article-slider__tags {
    margin-top: 0;
  }
  .article-slider__tags li {
    padding: 0 0.5rem;
    font-size: 0.6875rem;
    line-height: 1rem;
  }
  .article-slider__navigation {
    top: 7.5rem;
  }
  .article-slider__navigation--files {
    display: flex;
    top: 7.125rem;
  }
  .article-slider__files-slider, .article-slider__files-slider-in-blue {
    overflow: visible;
  }
  .article-slider__files-slider .swiper-wrapper, .article-slider__files-slider-in-blue .swiper-wrapper {
    flex-wrap: nowrap !important;
  }
  .article-slider__files-slider .article-slider__wrapper, .article-slider__files-slider-in-blue .article-slider__wrapper {
    overflow: visible;
  }
  .article-slider .swiper-slide.swiper-slide-active .article-slider__image-container:before, .article-slider .swiper-slide.swiper-slide-active .article-slider__image-container:after {
    opacity: 0.5;
  }
  .article-slider .swiper-slide.swiper-slide-active .article-slider__image {
    opacity: 1;
  }
  .article-slider .swiper-slide.swiper-slide-active .article-slider__content-container {
    border-color: transparent;
  }
  .article-slider--discover .article-slider__wrapper {
    max-width: 18.75rem;
  }
  .article-slider--discover .article-slider__image-container {
    height: 15.625rem;
  }
  .article-slider--discover .article-slider__content-container {
    padding: 0.9375rem 0rem 0rem;
  }
  .article-slider--discover .article-slider__subtitle {
    font-size: 0.6875rem;
    line-height: 0.8125rem;
    margin-bottom: 0.75rem;
  }
  .article-slider--discover .article-slider__title {
    font-size: 0.875rem;
    line-height: 1.125rem;
    max-width: 11.6875rem;
  }
  .article-slider--discover .swiper-slide {
    max-width: 12.9375rem;
  }
  .article-slider--discover .swiper-slide.swiper-slide-active {
    max-width: 15.5rem;
  }
  .article-slider--discover .slider__navigation {
    top: 7.8125rem;
  }
  .article-slider--news-slider .article-slider__wrapper {
    overflow: visible;
  }
  .article-slider--news-slider .swiper-slide {
    opacity: 0.5;
    transition: opacity 0.3s ease;
  }
  .article-slider--news-slider .swiper-slide.swiper-slide-active {
    opacity: 1;
  }
  .article-slider--files {
    padding: 3.75rem 0 1.875rem;
  }
  .article-slider--files--in-blue {
    padding: 1.875rem 0 0 0;
  }
  .article-slider--files--in-blue .swiper-slide.swiper-slide-active .article-slider__image-container:before {
    opacity: 0 !important;
  }
  .article-slider--files--in-blue .swiper-slide.swiper-slide-active .article-slider__image-container:after {
    opacity: 0 !important;
  }
  .article-slider--files--in-blue .swiper-slide.swiper-slide-active .article-slider__bottom-title {
    text-decoration: underline;
  }
  .article-slider--files .container {
    max-width: 67vw;
  }
  .article-slider--no-swiper .article__image-container,
.article-slider--no-swiper .article__text-container {
    height: 9.625rem !important;
    min-height: 9.625rem !important;
  }
  .article-slider--no-swiper .article__text {
    padding: 0 !important;
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
  .article-slider--narrow {
    padding-top: 1.875rem;
  }
  .article-slider--narrow .article__text {
    padding: 0 1.875rem;
  }
}
@media (max-width: 575.98px) {
  .article-slider__slide--file .article-slider__image-container {
    height: 12.1875rem;
  }
  .article-slider--news-slider .container {
    max-width: 67vw;
  }
  .article-slider--news-slider .article-slider__navigation {
    top: 10.625rem;
  }
}

.council__main {
  padding-top: 1.25rem;
}
.council__row {
  margin-bottom: 3.25rem;
}
.council__row:last-of-type {
  margin-bottom: 4.125rem;
}
.council__image-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 67%;
  overflow: hidden;
  border-radius: 3.75rem 0 3.75rem;
}
.council__image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.council__content {
  display: flex;
  flex-flow: column nowrap;
}
.council__content--align-right {
  text-align: right;
}
.council__title {
  font-family: "Playfair Display";
  font-size: 2.1875rem;
  line-height: 2.625rem;
  font-weight: 600;
  margin-bottom: 2.25rem;
}
.council__text {
  font-size: 1.375rem;
  line-height: 1.875rem;
  font-weight: 400;
}
@media (max-width: 1199.98px) {
  .council__title {
    font-size: 1.5rem;
    line-height: 1.75rem;
    margin-bottom: 1.25rem;
  }
  .council__text {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}
@media (max-width: 991.98px) {
  .council__row {
    margin-bottom: 1.875rem;
  }
  .council__row:last-of-type {
    margin-bottom: 3.125rem;
  }
}
@media (max-width: 767.98px) {
  .council__row:last-of-type {
    margin-bottom: 1.25rem;
  }
  .council__row:last-of-type > div[class^=col-]:first-of-type {
    order: 2;
  }
  .council__row:last-of-type > div[class^=col-]:last-of-type {
    order: 1;
  }
  .council__image-container {
    margin-bottom: 1.875rem;
  }
  .council__title {
    text-align: left !important;
  }
  .council__text {
    text-align: left !important;
    font-size: 0.8125rem;
    line-height: 1.25rem;
  }
}

.a-col {
  display: flex;
  flex-flow: column nowrap;
  padding: 1.625rem 0 2.3125rem;
}
.a-col__title {
  font-size: 1.75rem;
  line-height: 2.75rem;
  margin-bottom: 1.75rem;
}
.a-col__link-list {
  display: flex;
  flex-flow: column nowrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.a-col__li {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  border-bottom: 0.0625rem solid rgba(15, 29, 55, 0.3);
}
.a-col__li:last-child {
  border-bottom-color: transparent;
}
.a-col__li:hover .a-col__list-item {
  font-weight: 600;
}
.a-col__li:hover .a-col__list-item-chevron {
  background-color: black;
}
.a-col__li:hover .a-col__list-item-chevron svg path {
  fill: white;
}
.a-col__list-item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-size: 1.0625rem;
  line-height: 1.5rem;
  padding: 0.4375rem 0.625rem 0.4375rem 0;
  color: #323232;
  font-weight: 500;
  text-decoration: none;
  width: 100%;
}
.a-col__list-item:focus-visible {
  font-weight: 600;
  position: relative;
  outline: 0;
  border: 0;
}
.a-col__list-item:focus-visible:before {
  top: 0;
}
.a-col__list-item:focus-visible:before {
  position: absolute;
  left: calc(-1.875rem / 2);
  top: calc(-0rem / 2);
  width: calc(100% + 1.875rem);
  height: calc(100% + 0rem);
  outline: 0.1875rem solid #646464;
  -moz-outline-radius: 2.5rem;
  border-radius: 2.5rem;
  content: "";
}
@media (max-width: 1199.98px) {
  .a-col__list-item:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
.a-col__list-item:focus-visible + .a-col__list-item-chevron {
  background-color: black;
}
.a-col__list-item:focus-visible + .a-col__list-item-chevron svg path {
  fill: white;
}
.a-col__list-item--date {
  width: 50%;
}
.a-col__list-item-chevron {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  bottom: 0.5rem;
  width: 1.1875rem;
  height: 1.1875rem;
  border-radius: 50%;
  transform: translateX(0.375rem);
}
.a-col__list-item-chevron svg {
  transform: translateX(0.0625rem) scale(0.85);
}
.a-col--with-bg {
  background-color: #BCDBC9;
  padding: 1.625rem 1.25rem 2.3125rem 1.75rem;
  border-radius: 1.25rem;
}
@media (max-width: 1399.98px) {
  .a-col {
    padding: 1.25rem 0 1.75rem;
  }
  .a-col__title {
    font-size: 1.875rem;
    line-height: 1.75rem;
    margin-bottom: 1.5rem;
  }
  .a-col__list-item {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
  .a-col__list-item--date {
    width: 60%;
  }
  .a-col__list-item-chevron {
    bottom: 0.75rem;
  }
  .a-col--with-bg {
    padding: 1.25rem 1.875rem 1.75rem;
  }
  .a-col--in-more-info .a-col__title {
    font-size: 1.625rem;
    line-height: 1.75rem;
  }
}
@media (max-width: 1199.98px) {
  .a-col__title {
    font-size: 1.375rem;
    line-height: 1.75rem;
    margin-bottom: 1rem;
  }
  .a-col__list-item {
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 0.3125rem 0.625rem 0.3125rem 0;
  }
  .a-col__list-item--date {
    width: 60%;
  }
  .a-col__list-item-chevron {
    bottom: 0.375rem;
  }
  .a-col--in-more-info .a-col__title {
    font-size: 1.25rem;
    line-height: 1.375rem;
  }
}
@media (max-width: 991.98px) {
  .a-col__list-item--date {
    width: 50%;
  }
}
@media (max-width: 767.98px) {
  .a-col {
    padding: 1.25rem 0 0.375rem;
  }
  .a-col__list-item {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
  .a-col--with-bg {
    margin-top: 1.25rem;
    padding: 0.625rem 1rem 0.625rem;
  }
}
@media (max-width: 575.98px) {
  .a-col__list-item {
    flex-flow: column nowrap;
  }
  .a-col__list-item--date {
    width: 100%;
    font-size: 0.75rem;
    line-height: 1.125rem;
    opacity: 0.7;
  }
  .a-col__list-item-chevron svg {
    width: 0.5rem;
  }
}

.breadcrumbs {
  display: flex;
  flex-flow: row nowrap;
  padding-left: 0;
  margin-top: 2.375rem;
}
.breadcrumbs__list-item {
  position: relative;
  padding: 0 0.875rem;
  list-style-type: none;
}
.breadcrumbs__list-item:after {
  position: absolute;
  top: 0.375rem;
  right: 0;
  width: 0.125rem;
  height: 0.875rem;
  content: "";
  background-color: rgba(0, 0, 0, 0.7);
}
.breadcrumbs__list-item:first-child {
  padding-left: 0;
}
.breadcrumbs__list-item:last-child:after {
  display: none;
}
.breadcrumbs__item {
  font-size: 0.875rem;
  line-height: 1.625rem;
  color: black;
  text-decoration: none;
}
.breadcrumbs__item:hover {
  text-decoration: underline;
}
.breadcrumbs__item--current {
  pointer-events: none;
}
.breadcrumbs--mobile {
  display: none;
}
@media (max-width: 991.98px) {
  .breadcrumbs {
    display: none;
  }
  .breadcrumbs--mobile {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 0;
  }
  .breadcrumbs--mobile .breadcrumbs__list-item {
    position: relative;
    padding-left: 0.625rem;
  }
  .breadcrumbs--mobile .breadcrumbs__list-item:before {
    position: absolute;
    left: 0;
    top: 0.5rem;
    width: 0.375rem;
    height: 0.625rem;
    background: url("/public/svg/chevron-left-black.svg") center center/100% no-repeat;
    content: "";
  }
  .breadcrumbs--mobile .breadcrumbs__item {
    text-decoration: underline;
    font-size: 0.6875rem;
  }
}

.article-content > .container {
  position: relative;
}
.article-content__title {
  font-family: "Playfair Display";
  font-size: 4.3125rem;
  line-height: 4.6875rem;
  font-weight: 400;
  color: black;
  text-align: center;
  margin-top: 4.625rem;
}
.article-content__title--small {
  font-weight: 900;
  font-size: 2.5rem;
  line-height: 3.5rem;
  margin-bottom: 2.3125rem;
  text-align: left;
}
.article-content__meta {
  display: flex;
  flex-flow: row wrap;
  margin-top: 4.375rem;
  padding-left: 0;
  list-style-type: none;
}
.article-content__meta li {
  font-size: 1.125rem;
  line-height: 1.375rem;
  font-weight: 300;
  padding: 0 1.125rem;
}
.article-content__meta li:not(:first-child) {
  border-left: 0.0625rem solid black;
}
.article-content__meta li a {
  text-decoration: none;
}
.article-content__meta li a:hover {
  text-decoration: underline;
}
.article-content__meta--regular li {
  font-weight: 500;
}
.article-content__content-custom-880 {
  max-width: 44.125rem;
}
.article-content--has-three-blocks {
  padding-bottom: 13.75rem;
}
@media (max-width: 1199.98px) {
  .article-content__title {
    font-size: 3.75rem;
    line-height: 4.375rem;
    margin-top: 3.125rem;
  }
  .article-content__title--small {
    font-size: 2.5rem;
    line-height: 3.5rem;
    margin-bottom: 2.3125rem;
  }
  .article-content__meta {
    margin-top: 3.125rem;
  }
}
@media (max-width: 991.98px) {
  .article-content__title {
    margin-top: 2.5rem;
    font-size: 2.5rem;
    line-height: 3.125rem;
  }
  .article-content__meta {
    margin-top: 2.5rem;
  }
  .article-content--has-three-blocks {
    padding-bottom: 20rem;
  }
}
@media (max-width: 767.98px) {
  .article-content__title {
    font-size: 1.875rem;
    line-height: 2.125rem;
    margin-top: 1.875rem;
  }
  .article-content__meta {
    margin-top: 1.25rem;
  }
  .article-content__meta li {
    padding: 0 0.625rem;
    font-size: 0.6875rem;
    line-height: 0.875rem;
  }
}

.article-images {
  overflow: hidden;
}
.article-images__col {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 4.6875rem;
  position: relative;
}
.article-images__col:nth-child(odd) {
  align-items: flex-end;
}
.article-images__col:nth-child(odd) .article-images__figure {
  align-items: flex-end;
}
.article-images__col:nth-child(odd) .article-images__image {
  border-radius: 0 1.25rem 1.25rem 0;
}
.article-images__col:nth-child(odd) .editor__description,
.article-images__col:nth-child(odd) figcaption {
  padding-left: 1.25rem;
}
.article-images__col:nth-child(odd):last-of-type {
  align-items: flex-start;
}
.article-images__col:nth-child(odd):last-of-type .article-images__figure {
  align-items: flex-start;
}
.article-images__col:nth-child(odd):last-of-type .article-images__image {
  border-radius: 1.25rem;
}
.article-images__col:nth-child(even) .article-images__image {
  border-radius: 1.25rem 0 0 1.25rem;
}
.article-images__figure,
.article-images figure {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}
.article-images figcaption {
  font-size: 0.75rem !important;
  line-height: 1.1875rem !important;
  color: black;
  margin-top: 1.875rem;
  margin-bottom: 0 !important;
  max-width: 44.5625rem;
}
.article-images__image-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 69%;
}
.article-images__image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.article-images--larger .article-images__image-container {
  padding-bottom: 93%;
}
@media (max-width: 991.98px) {
  .article-images__col .editor__description {
    max-width: 100%;
  }
}
@media (max-width: 767.98px) {
  .article-images__col {
    margin-bottom: 3.125rem;
  }
  .article-images__col:nth-child(odd) {
    padding-right: 2.5rem;
  }
  .article-images__col:nth-child(even) {
    padding-left: 2.5rem;
  }
  .article-images__col:last-of-type:nth-child(odd) .article-images__image {
    border-radius: 0 1.25rem 1.25rem 0;
  }
}

.cta-side-block {
  position: absolute;
  z-index: 3;
  display: flex;
  flex-flow: column nowrap;
  background-color: #C95100;
  border-radius: 2.8125rem 0 2.8125rem 0;
  padding: 3.125rem 1.25rem;
  max-width: 17.5rem;
}
.cta-side-block__title {
  font-family: "Playfair Display" !important;
  font-size: 2.125rem !important;
  line-height: 2.3125rem !important;
  font-weight: 900 !important;
  text-align: center;
  padding-top: 0 !important;
  margin-bottom: 0 !important;
}
.cta-side-block__title--white {
  color: white;
}
.cta-side-block__link {
  font-size: 1.125rem;
  line-height: 1;
  color: white !important;
  font-weight: 700;
  text-align: center;
  margin-top: 1.5625rem;
  text-decoration: none;
}
.cta-side-block__link:hover {
  text-decoration: underline;
}
.cta-side-block__link--large {
  font-size: 1.375rem;
  color: black !important;
  margin-top: 0;
}
.cta-side-block__link--large:hover {
  color: white !important;
}
.cta-side-block__outer {
  position: relative;
  height: 0;
}
.cta-side-block__list {
  margin-top: 1.5rem;
  border-bottom: 0.0625rem solid white;
  padding-left: 0;
}
.cta-side-block__list li {
  border-top: 0.0625rem solid white;
  padding: 1rem 0;
  text-align: center;
}
.cta-side-block__list li:before {
  display: none;
}
.cta-side-block__image {
  max-height: 4.375rem;
  width: auto;
  padding: 0.9375rem 1.875rem;
  background-color: white;
  border-radius: 1.75rem;
  margin-top: 0.75rem;
  align-self: center;
}
.cta-side-block--between {
  top: 50%;
  transform: translateY(-50%) translateX(100%);
  right: -4.375rem;
}
.cta-side-block--in-wide-image {
  right: -4.375rem;
}
.cta-side-block--in-wide-image--top {
  top: -11.875rem;
}
.cta-side-block--in-wide-image--bottom {
  bottom: -3.5rem;
}
.cta-side-block--on-page-header {
  bottom: 5.625rem;
  transform: translateY(50%);
  right: calc((100vw - 73.75rem) / 2);
  text-align: center;
  padding: 1.5625rem 1.5rem;
}
.cta-side-block--on-page-header .cta-side-block__title {
  color: white;
  margin-bottom: 1.25rem !important;
  font-size: 1.875rem !important;
}
.cta-side-block--on-page-header p {
  font-size: 1.0625rem;
  line-height: 1.3125rem;
}
.cta-side-block--on-page-header--top-aligned {
  bottom: auto;
  top: 1.875rem;
  transform: translateY(0);
}
.cta-side-block--on-page-header--bottom-aligned {
  bottom: 6.25rem;
  transform: translateY(100%);
}
.cta-side-block p {
  text-align: center;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 500;
}
.cta-side-block p a {
  display: inline-block;
  font-weight: 700;
  text-decoration: underline;
}
@media (max-width: 1765px) {
  .cta-side-block--in-wide-image {
    right: 2.5rem;
  }
  .cta-side-block--in-wide-image--top {
    top: -3.75rem;
  }
}
@media (max-width: 1399.98px) {
  .cta-side-block__title {
    font-size: 1.875rem !important;
    line-height: 2.125rem !important;
  }
}
@media (max-width: 1380px) {
  .cta-side-block__outer {
    height: auto;
    display: flex;
    justify-content: center;
  }
  .cta-side-block--between {
    position: relative;
    top: auto !important;
    bottom: auto !important;
    right: auto !important;
    transform: translateX(0) translateY(0);
    margin-top: 0;
    margin-bottom: 1.875rem;
  }
}
@media (max-width: 1260px) {
  .cta-side-block--on-page-header {
    right: 1.25rem;
    max-width: 16.25rem;
  }
  .cta-side-block--on-page-header p {
    font-size: 0.875rem;
  }
}
@media (max-width: 991.98px) {
  .cta-side-block {
    padding: 1.875rem 1.25rem;
    max-width: 15rem;
  }
  .cta-side-block__title {
    font-size: 1.5rem !important;
    line-height: 1.5rem !important;
  }
  .cta-side-block__link {
    font-size: 0.875rem;
    margin-top: 1.25rem;
  }
  .cta-side-block--on-page-header {
    position: relative;
    top: auto !important;
    bottom: auto !important;
    right: auto !important;
    transform: translateY(0);
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    padding: 1rem 2.5rem;
    order: 1;
    align-items: center;
  }
  .cta-side-block--on-page-header .cta-side-block__title {
    font-size: 1rem !important;
    line-height: 1.3 !important;
    margin-bottom: 0.625rem !important;
  }
  .cta-side-block--on-page-header p {
    font-size: 0.6875rem;
    line-height: 1rem;
    max-width: 40rem;
  }
  .cta-side-block__image {
    max-height: 3.125rem;
    width: auto;
    max-width: 9.375rem;
    padding: 0.625rem 1.25rem;
    background-color: white;
    border-radius: 1.75rem;
    margin-top: 0.625rem;
  }
}
@media (max-width: 767.98px) {
  .cta-side-block {
    position: relative;
    top: auto !important;
    bottom: auto !important;
    right: auto !important;
    margin-top: 0;
    margin-bottom: 1.875rem;
  }
  .cta-side-block--on-page-header {
    margin-bottom: 0;
  }
}

@media (max-width: 767.98px) {
  .cta-side-block--in-wide-image + .editor__image-container {
    margin-top: -3.75rem;
  }
}
.more-info .row:first-of-type {
  position: relative;
  z-index: 2;
}
.more-info:last-of-type {
  padding-bottom: 0;
}
.more-info__container {
  display: flex;
  flex-flow: column nowrap;
  text-align: center;
  align-items: center;
  padding: 5.625rem 2.5rem 4.6875rem;
  border-radius: 4.6875rem 0 4.6875rem 0;
  background: #68b9d5;
  background: linear-gradient(190deg, #68b9d5 0%, #2a3957 85%, #2a3957 100%);
}
.more-info__container--has-link-block-above {
  position: relative;
  margin-top: -15rem;
  z-index: 3;
}
.more-info__container--search {
  padding: 5.625rem 4.6875rem 7.5rem;
}
.more-info__title {
  font-family: "Playfair Display";
  font-weight: 500;
  color: white;
  font-size: 2.875rem;
  line-height: 3.8125rem;
  margin-bottom: 1.875rem;
}
.more-info__title--slider {
  color: black;
  font-weight: 900;
  font-size: 3.4375rem;
  line-height: 4.875rem;
  margin-bottom: 0;
}
.more-info p {
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 300;
  margin-bottom: 2.5rem;
  color: white;
}
.more-info__content p, .more-info__content ul li, .more-info__content ol li, .more-info__content h1, .more-info__content h2, .more-info__content h3, .more-info__content h4, .more-info__content h5, .more-info__content h6 {
  font-size: 1.3125rem;
  line-height: 1.9375rem;
  font-weight: 500;
  margin-bottom: 2.1875rem;
  color: white;
}
.more-info__content a {
  color: white;
}
.more-info__content h2, .more-info__content h3, .more-info__content h4, .more-info__content h5, .more-info__content h6 {
  font-weight: 700;
}
.more-info__content h2, .more-info__content h3, .more-info__content h4 {
  font-size: 1.875rem;
}
.more-info__content ul, .more-info__content ol {
  padding-left: 0;
}
.more-info__content ul li, .more-info__content ol li {
  margin-bottom: 0;
}
.more-info__links {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1.375rem;
  padding-left: 0;
  list-style-type: none;
}
.more-info__link {
  font-size: 1.375rem;
  line-height: 1.9375rem;
  text-decoration: none;
  font-weight: 300;
  color: white !important;
}
.more-info__link:hover {
  text-decoration: underline;
}
.more-info__link:focus-visible {
  position: relative;
  outline: 0;
  border: 0;
}
.more-info__link:focus-visible:before {
  position: absolute;
  left: calc(-0.625rem / 2);
  top: calc(-0rem / 2);
  width: calc(100% + 0.625rem);
  height: calc(100% + 0rem);
  outline: 0.1875rem solid #646464;
  -moz-outline-radius: 0.625rem;
  border-radius: 0.625rem;
  content: "";
}
@media (max-width: 1199.98px) {
  .more-info__link:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
.more-info__link:focus-visible:before {
  outline-color: white;
}
.more-info__call {
  font-size: 2.3125rem;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 1.875rem;
  color: white !important;
  text-decoration: none;
}
.more-info__call:hover {
  text-decoration: underline;
}
.more-info__call:focus-visible {
  position: relative;
  outline: 0;
  border: 0;
}
.more-info__call:focus-visible:before {
  position: absolute;
  left: calc(-0.625rem / 2);
  top: calc(-0rem / 2);
  width: calc(100% + 0.625rem);
  height: calc(100% + 0rem);
  outline: 0.1875rem solid #646464;
  -moz-outline-radius: 0.625rem;
  border-radius: 0.625rem;
  content: "";
}
@media (max-width: 1199.98px) {
  .more-info__call:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
.more-info__call:focus-visible:before {
  outline-color: white;
}
.more-info__contact-list {
  display: flex;
  flex-flow: column nowrap;
  padding-left: 0;
  list-style-type: none;
}
.more-info__contact-list li {
  font-size: 1.0625rem;
  line-height: 1.5625rem;
  color: white;
}
.more-info__contact-list li a {
  text-decoration: none;
  color: white;
}
.more-info__contact-list li a:hover {
  text-decoration: underline;
}
.more-info__contact-list li a:focus-visible {
  position: relative;
  outline: 0;
  border: 0;
}
.more-info__contact-list li a:focus-visible:before {
  position: absolute;
  left: calc(-0.625rem / 2);
  top: calc(-0rem / 2);
  width: calc(100% + 0.625rem);
  height: calc(100% + 0rem);
  outline: 0.1875rem solid #646464;
  -moz-outline-radius: 0.625rem;
  border-radius: 0.625rem;
  content: "";
}
@media (max-width: 1199.98px) {
  .more-info__contact-list li a:focus-visible:before {
    outline-width: 0.1875rem;
  }
}
.more-info__contact-list li a:focus-visible:before {
  outline-color: white;
}
.more-info__link-block {
  padding: 2.3125rem 5rem 1.625rem;
  background-color: #C4DFD0;
  border-radius: 0 5rem 0 5rem;
}
.more-info__link-block--grey {
  background-color: #EBECEC;
}
.more-info__link-block--has-info-above {
  padding-top: 14.25rem;
  margin-top: -11.875rem;
}
.more-info__link-block--has-info-below {
  padding-bottom: 16.75rem;
}
.more-info__title-block-container {
  position: relative;
}
.more-info__title-block {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  padding: 3.75rem 1.875rem;
  margin-top: 3.25rem;
  border-radius: 2.8125rem 0 2.8125rem 0;
  z-index: 2;
  opacity: 0.85;
}
.more-info__title-block:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0E7A3D;
  mix-blend-mode: multiply;
  border-radius: 2.8125rem 0 2.8125rem 0;
  content: "";
}
.more-info__title-block .more-info__title {
  position: relative;
  font-family: "interstate", sans-serif;
  font-size: 2.3125rem;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 0;
  text-align: center;
}
.more-info__content-block-inner {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin-top: -6.25rem;
  padding: 8.625rem 3.125rem 0.5rem;
  background-color: rgba(201, 211, 44, 0.7);
  border-radius: 0 2.8125rem 0 2.8125rem;
  z-index: 1;
}
.more-info__content-block-inner p, .more-info__content-block-inner a {
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-bottom: 1.875rem;
  text-align: center;
  color: black;
}
.more-info__content-block-inner a {
  text-decoration: none;
}
.more-info__content-block-inner a:hover {
  text-decoration: underline;
}
.more-info__form {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  max-width: 25rem;
  width: 100%;
  margin-top: 1.5rem;
}
.more-info__form-input-container {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
}
.more-info__form-label {
  font-size: 1.125rem !important;
  line-height: 0.75rem;
  color: white;
  margin-right: 0.625rem;
  margin-bottom: 0;
  white-space: nowrap;
  transform: translateY(0) translateX(0) !important;
}
.more-info__form-input {
  background-color: white;
  height: 3.375rem;
  border-radius: 1.75rem;
  font-size: 1.125rem;
  line-height: 0.75rem;
  font-weight: 500;
  color: #646464;
  padding: 0 3.75rem 0 1.25rem;
  border: 0.0625rem solid #707070;
}
.more-info__form-submit {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 2.375rem;
  height: 2.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.15);
  border: 0;
}
.more-info__form-submit svg {
  transform: rotate(180deg) translateX(-0.125rem) scale(0.75);
}
.more-info__form-submit:hover {
  background-color: rgba(0, 0, 0, 0.25);
}
.more-info__article-slider {
  position: relative;
  padding-top: 3.125rem;
}
.more-info--has-padding {
  padding: 7rem 0 0 0;
}
.more-info--three-blocks {
  overflow: hidden;
  padding: 3.75rem 0 7rem;
}
.more-info--three-blocks .more-info__content p {
  font-size: 1.375rem;
  line-height: 1.875rem;
}
.more-info--three-blocks--orange .more-info__title-block {
  opacity: 1;
}
.more-info--three-blocks--orange .more-info__title-block:before {
  background-color: #C95100;
}
.more-info--three-blocks--orange .more-info__content-block-inner {
  background-color: rgba(250, 203, 170, 0.5);
}
.more-info--negative-margin {
  padding-top: 0;
  margin-top: -12.375rem;
}
@media (max-width: 1399.98px) {
  .more-info__container {
    padding: 5.3125rem 4.375rem 4.375rem;
  }
  .more-info__container--search {
    padding: 5.3125rem 4.375rem 6.875rem;
  }
  .more-info__link-block {
    padding: 2.3125rem 4.0625rem 1.5rem;
  }
  .more-info__link-block--has-info-above {
    padding-top: 14.25rem;
  }
  .more-info__link-block--has-info-below {
    padding-bottom: 14.25rem;
  }
  .more-info--has-padding {
    padding: 6.25rem 0 0 0;
  }
}
@media (max-width: 1199.98px) {
  .more-info__container {
    padding: 4.0625rem 3.125rem 3.125rem;
  }
  .more-info__container--search {
    padding: 4.0625rem 3.125rem 5.625rem;
  }
  .more-info__container--has-link-block-above {
    margin-top: -11.25rem;
  }
  .more-info__title {
    font-size: 2.5rem;
    line-height: 3.75rem;
    margin-bottom: 1.875rem;
  }
  .more-info__title--slider {
    margin-bottom: 0;
  }
  .more-info__links {
    margin-bottom: 1.25rem;
  }
  .more-info__link {
    font-size: 1.375rem;
    line-height: 1.875rem;
  }
  .more-info__call {
    font-size: 2.375rem;
    margin-bottom: 1.875rem;
  }
  .more-info__contact-list li {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
  .more-info__link-block {
    padding: 1.875rem 2.8125rem 1.5rem;
  }
  .more-info__link-block--has-info-above {
    padding-top: 17.8125rem;
  }
  .more-info__link-block--has-info-below {
    padding-bottom: 11.875rem;
  }
  .more-info__title-block {
    padding: 3.75rem 1.875rem;
  }
  .more-info__title-block .more-info__title {
    font-size: 2rem;
  }
  .more-info__content-block-inner {
    padding: 10.3125rem 1.875rem 0.625rem;
  }
  .more-info__content-block-inner p, .more-info__content-block-inner a {
    font-size: 1.25rem;
    line-height: 2rem;
    margin-bottom: 1.25rem;
  }
  .more-info--has-padding {
    padding: 5rem 0 0 0;
  }
}
@media (max-width: 991.98px) {
  .more-info__container--has-link-block-above {
    width: calc(100% - 5rem);
    margin-left: 2.5rem;
  }
  .more-info__link-block--has-info-above {
    padding-top: 1.875rem;
    margin-top: 3.75rem;
  }
  .more-info__content-block-inner {
    margin-top: -4.6875rem;
    padding: 6.5625rem 1.875rem 0.625rem;
  }
  .more-info__article-slider {
    padding-top: 0;
  }
  .more-info--has-padding {
    padding: 3.75rem 0 0 0;
  }
  .more-info--three-blocks:not(.more-info--stories) > .container > .row > div[class^=col-]:first-of-type {
    order: 2;
  }
  .more-info--three-blocks:not(.more-info--stories) > .container > .row > div[class^=col-]:last-of-type {
    order: 1;
  }
  .more-info--stories {
    padding-bottom: 0.625rem !important;
  }
  .more-info--stories .more-info__title-block {
    margin-top: 1.25rem;
  }
  .more-info--stories .more-info__article-slider {
    padding-top: 1.875rem;
  }
}
@media (max-width: 767.98px) {
  .more-info__container {
    padding: 2.5rem 1.25rem 1.875rem;
    border-radius: 3.125rem 0 3.125rem;
  }
  .more-info__container--search {
    padding: 2.5rem 1.25rem 3.75rem;
  }
  .more-info__container--has-link-block-above .more-info__content p {
    margin-bottom: 1.25rem;
  }
  .more-info__title {
    font-size: 1.875rem;
    line-height: 2.125rem;
    margin-bottom: 1.5rem;
  }
  .more-info__title--slider {
    margin-bottom: 0;
  }
  .more-info__link {
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-decoration: none;
  }
  .more-info__call {
    font-size: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .more-info__contact-list li {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .more-info__link-block {
    padding: 1.875rem 1.875rem 2.8125rem;
    border-radius: 0 3.125rem 0 3.125rem;
  }
  .more-info__link-block--has-info-below {
    padding-bottom: 11.875rem;
  }
  .more-info__content p {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .more-info__content h2, .more-info__content h3, .more-info__content h4 {
    font-size: 1.25rem;
  }
  .more-info__content p, .more-info__content ul li, .more-info__content ol li, .more-info__content h1, .more-info__content h2, .more-info__content h3, .more-info__content h4, .more-info__content h5, .more-info__content h6 {
    margin-bottom: 1.25rem;
  }
  .more-info__title-block {
    margin-top: 3.125rem;
    padding: 2.5rem 1.875rem;
  }
  .more-info__title-block:before {
    border-radius: 2.5rem 0 2.5rem 0;
  }
  .more-info__content-block-inner {
    border-radius: 0 2.5rem 0 2.5rem;
    padding: 5.9375rem 1.25rem 0.625rem;
  }
  .more-info__content-block-inner p {
    font-weight: 500;
  }
  .more-info__content-block-inner p, .more-info__content-block-inner a {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .more-info__content-block-inner a {
    font-weight: 700;
  }
  .more-info__form-input-container {
    flex-flow: column nowrap;
    align-items: flex-start;
  }
  .more-info__form-label {
    margin-bottom: 0.625rem;
  }
  .more-info__form-submit {
    top: auto;
    bottom: 0.5rem;
  }
  .more-info--three-blocks {
    padding: 2.5rem 0 3.75rem;
  }
  .more-info--three-blocks .more-info__content p {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .more-info--negative-margin {
    padding-top: 0;
    margin-top: -18.4375rem;
  }
}

.split-view__inner {
  padding-top: 1.4375rem;
  overflow: hidden;
}
.split-view__inner > .container {
  max-width: 76.25rem;
}
.split-view__scroll-block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 2.375rem 0;
  border-top: 0.0625rem solid #707070;
  border-bottom: 0.0625rem solid #707070;
  transition: opacity 0.15s ease, visibility 0.15s ease;
}
.split-view__scroll-block--sticky {
  left: calc((100% - 73.75rem) / 2);
  position: fixed;
  max-width: 18.75rem;
}
.split-view__scroll-block--hide {
  position: absolute;
  top: auto !important;
  bottom: 13.75rem;
  left: 0;
  min-width: 18.75rem;
}
.split-view__side {
  position: relative;
  width: 100%;
  max-width: 23.75rem;
  padding-right: 6.5%;
}
.split-view__side-inner {
  position: relative;
  height: 100%;
}
.split-view__side-mobile-select {
  display: none;
}
.split-view__side-mobile-trigger {
  display: none;
}
.split-view__list {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.split-view__list--sub {
  padding-left: 1.25rem;
}
.split-view__list--sub:before {
  position: absolute;
  top: 0.5rem;
  left: 0;
  width: 0.1875rem;
  height: calc(100% - 1rem);
  background: rgba(15, 29, 55, 0.15);
  content: "";
  border-radius: 0.125rem;
}
.split-view__list--sub--no-border:before {
  display: none;
}
.split-view__list-item {
  font-size: 1.0625rem;
  line-height: 1.9375rem;
  font-weight: 400;
}
.split-view__list-item a {
  font-size: 1.0625rem;
  line-height: 1.9375rem;
  font-weight: 400;
  text-decoration: none;
}
.split-view__list-item a:hover {
  font-weight: 700;
}
.split-view__list-item--active {
  font-weight: 700;
}
.split-view__list-item--active > a {
  font-weight: 700;
}
.split-view__content-inner {
  position: relative;
  padding: 1.5625rem 0 14.375rem;
  min-height: 31.25rem;
}
.split-view__content-inner:before {
  position: absolute;
  width: 100%;
  width: calc(100% + (100vw - 73.75rem) / 2);
  height: 100%;
  left: 0;
  top: 0;
  content: "";
  background-color: #EBECEC;
  border-radius: 4.6875rem 0 4.6875rem 0;
}
.split-view__content-inner--with-grey-bg:before {
  display: block !important;
  position: absolute;
  width: 100%;
  width: calc(100% + (100vw - 73.75rem) / 2);
  height: 100%;
  left: 0;
  top: 0;
  content: "";
  background-color: #EBECEC;
  border-radius: 4.6875rem 0 4.6875rem 0;
}
.split-view__content-editor {
  position: relative;
  padding-left: 6.25rem;
}
.split-view__content-top {
  margin-bottom: 4.6875rem;
  max-width: 44.125rem;
}
.split-view--narrow-side .split-view__side {
  max-width: 13.375rem;
  padding-top: 3.75rem;
}
.split-view--narrow-side .split-view__inner {
  padding-top: 3.125rem;
}
.split-view--narrow-side .split-view__content-inner {
  padding-top: 4.375rem;
}
.split-view--no-background .split-view__content-inner {
  padding-top: 0;
}
.split-view--no-background .split-view__content-inner:before {
  display: none;
}
.split-view--no-background .split-view__content-top {
  margin-bottom: 2.5rem;
}
.split-view--no-background .split-view__content-editor {
  padding-left: 0;
}
.split-view--no-background .split-view__side {
  padding-top: 1.5rem;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  max-width: 25%;
}
.split-view--no-background .split-view__side--has-mobile-view {
  padding-top: 0;
}
@media (max-width: 1220px) {
  .split-view__content-inner:before {
    width: calc(100% + 1.25rem);
  }
  .split-view__content-inner--with-grey-bg:before {
    width: calc(100% + 1.25rem);
  }
  .split-view__scroll-block {
    padding: 1.75rem 0;
  }
  .split-view__scroll-block--sticky {
    left: 1.25rem;
  }
  .split-view__scroll-block--hide {
    left: 0;
  }
  .split-view__side {
    padding-right: 0;
    max-width: 17.5rem;
  }
  .split-view__list-item {
    font-size: 0.9375rem;
    line-height: 1.625rem;
  }
  .split-view__list-item a {
    font-size: 0.9375rem;
    line-height: 1.625rem;
  }
  .split-view--narrow-side .split-view__scroll-block--sticky {
    max-width: 15.3125rem;
  }
  .split-view--narrow-side .split-view__scroll-block--hide {
    min-width: 15.3125rem;
  }
}
@media (max-width: 991.98px) {
  .split-view__side {
    display: none;
  }
  .split-view__side--has-mobile-view {
    display: flex;
  }
  .split-view__side--has-mobile-view .split-view__side-inner {
    min-height: 0 !important;
    width: 100%;
  }
  .split-view__side--has-mobile-view .split-view__scroll-block {
    position: relative;
    top: auto !important;
    left: auto !important;
    bottom: auto !important;
    padding: 0;
    border-top: 0;
    border-bottom: 0;
  }
  .split-view__side--has-mobile-view .split-view__nav {
    position: relative;
    border: 0.0625rem solid #707070;
    border-radius: 0.3125rem;
    padding: 1.875rem 0.9375rem 0rem;
    max-height: 2rem;
  }
  .split-view__side--has-mobile-view .split-view__nav:before {
    position: absolute;
    top: 0.375rem;
    left: 0.9375rem;
    font-size: 0.75rem;
    color: black;
    width: calc(100% - 1.875rem);
    height: 1.625rem;
    content: attr(data-mobile-label);
  }
  .split-view__side--has-mobile-view .split-view__nav > ul.split-view__list {
    display: none;
  }
  .split-view__side--has-mobile-view .split-view__nav--open {
    padding: 1.875rem 0.9375rem 0.5rem;
    max-height: 100%;
  }
  .split-view__side--has-mobile-view .split-view__nav--open:before {
    opacity: 0.2;
  }
  .split-view__side--has-mobile-view .split-view__nav--open .split-view__side-mobile-trigger:before {
    transform: rotate(90deg);
  }
  .split-view__side--has-mobile-view .split-view__nav--open > ul.split-view__list {
    display: flex;
  }
  .split-view__side--has-mobile-view .split-view__side-mobile-trigger {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1.875rem;
    z-index: 2;
  }
  .split-view__side--has-mobile-view .split-view__side-mobile-trigger:before {
    position: absolute;
    right: 0.9375rem;
    top: 0.6875rem;
    width: 0.375rem;
    height: 0.625rem;
    background: url("/public/svg/chevron-left-black.svg") center center/100% no-repeat;
    content: "";
    transform: rotate(-90deg);
  }
  .split-view__side--has-mobile-view .split-view__list-item {
    font-size: 0.75rem;
  }
  .split-view__side--has-mobile-view .split-view__list-item a {
    font-size: 0.75rem;
  }
  .split-view__side-mobile-select {
    display: block;
  }
  .split-view__inner {
    background-color: #EBECEC;
    padding-top: 0;
  }
  .split-view__content-inner {
    padding-top: 0;
  }
  .split-view__content-editor {
    padding-left: 0;
  }
  .split-view__content-top {
    max-width: 100%;
    margin-bottom: 1.25rem;
  }
  .split-view--narrow-side .split-view__side {
    max-width: 100%;
  }
  .split-view--narrow-side .split-view__inner {
    padding-top: 0;
  }
  .split-view--narrow-side .split-view__content-inner {
    padding-top: 0;
  }
  .split-view--no-background .split-view__content-top {
    margin-bottom: 1.25rem;
  }
  .split-view--no-background .split-view__side--has-mobile-view {
    padding-top: 1.5rem;
  }
}
@media (max-width: 767.98px) {
  .split-view__inner {
    border-radius: 0 0 1.875rem 0;
  }
  .split-view__content-inner {
    padding-bottom: 1.25rem;
  }
  .split-view__content-inner:before {
    display: none;
  }
  .split-view__content-inner--with-grey-bg:before {
    display: none;
  }
}

.split-view + .section--more-info {
  margin-top: -11.25rem;
  padding-top: 0;
}
@media (max-width: 767.98px) {
  .split-view + .section--more-info {
    margin-top: 0rem;
    padding-top: 2.5rem;
  }
}

.products {
  display: flex;
  flex-flow: column nowrap;
}
.products > .container {
  max-width: 76.25rem !important;
}
.products__section {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 3.125rem 0 3.4375rem 0;
}
.products__section:after {
  position: absolute;
  left: 1.25rem;
  bottom: 0;
  width: calc(100% - 2.5rem);
  height: 0.0625rem;
  background-color: #707070;
  content: "";
}
.products__section:last-of-type:after {
  display: none;
}
.products__section-title {
  font-size: 1.9375rem;
  line-height: 1.3;
  margin-bottom: 2.375rem;
  padding: 0 1.25rem;
}
.products__section-title span {
  font-weight: 700;
}
.products__row {
  display: flex;
  flex-flow: row wrap;
  width: calc(100% + 1.25rem);
  padding: 0 0.625rem;
  margin-right: -2.5rem;
}
.products__more {
  font-size: 1.0625rem;
  line-height: 1.3125rem;
}
.products__more a {
  font-weight: 700;
}
.products__more a:hover {
  text-decoration: none;
}
@media (max-width: 1220px) {
  .products__section {
    padding: 1.875rem 0 2.1875rem 0;
  }
  .products__section-title {
    font-size: 1.625rem;
    margin-bottom: 1.75rem;
  }
}
@media (max-width: 991.98px) {
  .products__row {
    width: calc(100% + 2.5rem);
    margin-right: -1.25rem;
  }
}
@media (max-width: 767.98px) {
  .products__section {
    padding: 1.875rem 0;
  }
  .products__section-title {
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin-bottom: 1.5rem;
    padding: 0;
    text-align: center;
  }
  .products__more {
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-align: center;
  }
}

.product {
  padding-left: 0.625rem !important;
  padding-right: 0.625rem !important;
  margin-bottom: 1.875rem;
}
.product__inner {
  display: flex;
  flex-flow: column nowrap;
  padding: 2.875rem 2.5rem 2.5rem;
  background: white;
  border-radius: 2.8125rem 0;
  height: 100%;
}
.product__title {
  font-family: "Playfair Display";
  font-size: 1.6875rem;
  line-height: 2.0625rem;
  margin-bottom: 1.5rem;
  font-weight: 900;
  hyphens: auto;
}
.product__excerpt {
  font-size: 0.9375rem;
  line-height: 1.4375rem;
  font-weight: 400;
  margin-bottom: 1.1875rem;
}
.product__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}
.product__buttons a:not(.button) {
  text-decoration: none;
}
.product__buttons a:not(.button):hover {
  text-decoration: underline;
}
.product__content {
  padding-top: 5.625rem;
  padding-bottom: 4.375rem;
}
@media (max-width: 1220px) {
  .product__inner {
    padding: 2.25rem 1.875rem 1.875rem;
    border-radius: 2.1875rem 0;
  }
}
@media (max-width: 767.98px) {
  .product {
    margin-bottom: 1.25rem;
  }
  .product__inner {
    padding: 1.25rem;
    border-radius: 1.25rem 0;
  }
  .product__title {
    font-size: 1rem;
    line-height: 1.25rem;
    margin-bottom: 0.4375rem;
  }
  .product__excerpt {
    font-size: 0.6875rem;
    line-height: 1rem;
    margin-bottom: 1.0625rem;
  }
  .product__buttons {
    justify-content: flex-start;
  }
  .product__buttons a:not(.button) {
    margin-right: 1.25rem;
    font-size: 0.6875rem;
    line-height: 1.25rem;
  }
  .product__content {
    padding-top: 3.125rem;
    padding-bottom: 1.875rem;
  }
}

.accordion-item {
  padding-bottom: 3.5rem;
}
.accordion-header {
  padding-top: 0 !important;
  margin-bottom: 0 !important;
  border-bottom: 0.0625rem solid #707070;
}
.accordion-button {
  font-size: 2rem;
  line-height: 1.3;
  font-weight: 700;
  background: transparent;
  border: 0;
  padding: 0;
  display: flex;
  text-align: left;
  margin-bottom: 0.875rem;
  color: black;
}
.accordion-button:not(.collapsed) .accordion-toggle:after {
  transform: rotate(180deg);
}
.accordion-toggle {
  position: relative;
  width: 1.4375rem;
  height: 1.4375rem;
  margin-top: 0.5625rem;
  margin-right: 0.5625rem;
  flex-shrink: 0;
}
.accordion-toggle:before, .accordion-toggle:after {
  position: absolute;
  content: "";
  background-color: black;
  width: 1.4375rem;
  height: 0.3125rem;
  top: 0.5625rem;
  left: 0;
  transition: transform 0.2s ease;
}
.accordion-toggle:after {
  transform: rotate(90deg);
}
.accordion-body {
  padding-top: 1.25rem;
}
.accordion--in-editor {
  margin-top: 3.125rem;
}
@media (max-width: 767.98px) {
  .accordion {
    margin-bottom: 1.25rem;
  }
  .accordion-button {
    font-size: 1.375rem;
  }
  .accordion-item {
    padding-bottom: 0.9375rem;
  }
  .accordion-toggle {
    width: 0.9375rem;
    height: 0.9375rem;
    margin-top: 0.25rem;
    margin-right: 0.25rem;
  }
  .accordion-toggle:before, .accordion-toggle:after {
    width: 0.9375rem;
    height: 0.1875rem;
  }
}

.appointment-modal__dialog {
  max-width: 42.5rem;
  width: 100%;
}
.appointment-modal__content {
  position: relative;
  border-radius: 3.125rem 0 3.125rem 0;
  background-color: white;
  padding: 2.1875rem 1.875rem 3.125rem 1.875rem;
  border: 0;
}
.appointment-modal__body {
  padding: 0.0625rem;
}
.appointment-modal__close-button {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  opacity: 1;
}
@media (max-width: 991.98px) {
  .appointment-modal__content {
    padding: 2.1875rem 1.25rem 1.875rem 1.25rem;
  }
}
@media (max-width: 767.98px) {
  .appointment-modal__dialog {
    margin: 1.25rem;
    width: auto;
  }
  .appointment-modal__content {
    border-radius: 1.25rem 0 1.25rem 0;
    padding: 2.8125rem 0rem 1.25rem 0rem;
  }
}

.facts {
  display: flex;
  padding-top: 3.125rem;
  padding-bottom: 3.75rem;
}
.facts__inner {
  display: flex;
  flex-flow: row wrap;
}
.facts__col {
  display: flex;
  flex-flow: column nowrap;
  padding: 0rem 2.1875rem 1.875rem;
  justify-content: center;
  align-items: center;
  border-right: 0.0625rem solid #707070;
  flex-grow: 1;
}
.facts__col:first-of-type {
  border-left: 0.0625rem solid #707070;
}
.facts__value {
  font-family: "Playfair Display";
  font-size: 10.4375rem;
  line-height: 1;
  color: white;
  margin-bottom: 2.25rem;
  font-weight: 900;
}
.facts__label {
  font-family: "Playfair Display";
  font-size: 1.8125rem;
  line-height: 1;
  font-weight: 400;
  color: black;
}
.facts__list ul li {
  font-size: 1.1875rem;
  line-height: 1.875rem;
  font-weight: 500;
}
.facts__list ul li:before {
  font-size: 2.1875rem;
}
@media (max-width: 1199.98px) {
  .facts__col {
    padding: 0rem 1.5625rem 1.875rem;
  }
  .facts__value {
    font-size: 6.6875rem;
  }
  .facts__label {
    font-size: 1.5rem;
  }
}
@media (max-width: 991.98px) {
  .facts {
    padding-top: 1.875rem;
    padding-bottom: 2.5rem;
  }
  .facts__col {
    padding: 0rem 0.9375rem 1.875rem;
  }
  .facts__value {
    font-size: 4.1875rem;
    margin-bottom: 1.625rem;
  }
  .facts__label {
    font-size: 1.25rem;
  }
  .facts__list ul li {
    font-size: 1.0625rem;
    line-height: 1.5rem;
    font-weight: 500;
  }
}
@media (max-width: 767.98px) {
  .facts {
    padding-bottom: 1.875rem;
  }
  .facts__col {
    width: 50%;
    flex: 0 0 50%;
    margin-bottom: 1.75rem;
    padding: 0rem 2.1875rem 0.625rem;
  }
  .facts__col:nth-of-type(3) {
    border-left: 0.0625rem solid #707070;
  }
  .facts__col .facts__value,
.facts__col .facts__label {
    transform: translateY(-0.625rem);
  }
  .facts__value {
    font-size: 5.4375rem;
  }
  .facts__list ul li {
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
  }
}

.persons,
.folders {
  padding: 5rem 0 2.5rem;
}
@media (max-width: 767.98px) {
  .persons,
.folders {
    padding: 1.875rem 0 0;
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
}

.person,
.folder {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  text-decoration: none;
  margin-bottom: 1.375rem;
}
.person__content,
.folder__content {
  order: 2;
  display: flex;
  flex-flow: column nowrap;
  padding: 1.5rem;
  justify-content: center;
  align-items: center;
}
.person__title,
.folder__title {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  font-family: "Playfair Display";
  font-size: 1.375rem;
  line-height: 1.5625rem;
  color: black;
  font-weight: 500;
  text-align: center;
}
.person__image-container,
.folder__image-container {
  order: 1;
  position: relative;
  width: 100%;
  height: 20.125rem;
  border-radius: 1.25rem;
  overflow: hidden;
}
.person__image,
.folder__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.person__image-content,
.folder__image-content {
  display: flex;
  align-items: center;
  min-height: 5rem;
  margin-top: 1.5rem;
  padding: 0 1rem;
}
.person__image-title,
.folder__image-title {
  position: relative;
  font-size: 2.5rem;
  line-height: 1;
  font-weight: 500;
  color: white;
  text-align: center;
}
.person__icon,
.folder__icon {
  position: relative;
  z-index: 2;
  width: 6.25rem;
}
.person--with-icon .person, .person--with-icon .folder__image-container,
.folder--with-icon .person,
.folder--with-icon .folder__image-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  padding-top: 4.625rem;
  background: #0e713a;
  background: linear-gradient(45deg, #0e713a 0%, #4a9da9 100%);
}
.person--with-icon .person:before, .person--with-icon .folder__image-container:before,
.folder--with-icon .person:before,
.folder--with-icon .folder__image-container:before {
  position: absolute;
  bottom: -3rem;
  left: -3rem;
  width: calc(100% + 6rem);
  height: 0;
  padding-bottom: calc((100% + 6rem) * 1.1);
  content: "";
  background: url("/public/svg/tile-artwork.svg") center center/100% no-repeat;
}
.person:hover .person__title,
.person:hover .folder__title,
.person:hover .person__image-title,
.person:hover .folder__image-title,
.folder:hover .person__title,
.folder:hover .folder__title,
.folder:hover .person__image-title,
.folder:hover .folder__image-title {
  text-decoration: underline !important;
  text-decoration-thickness: 0.0625rem !important;
}
.person:hover .person__image-title,
.person:hover .folder__image-title,
.folder:hover .person__image-title,
.folder:hover .folder__image-title {
  text-decoration-thickness: 0.125rem !important;
}
@media (max-width: 1199.98px) {
  .person__image-container,
.folder__image-container {
    height: 15.625rem;
  }
  .person__icon,
.folder__icon {
    width: 3.125rem;
  }
  .person__image-content,
.folder__image-content {
    margin-top: 1rem;
    min-height: 3.75rem;
  }
  .person__image-title,
.folder__image-title {
    font-size: 1.875rem;
  }
  .person__content,
.folder__content {
    padding: 1rem;
  }
  .person__title,
.folder__title {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
  .person--with-icon .person, .person--with-icon .folder__image-container,
.folder--with-icon .person,
.folder--with-icon .folder__image-container {
    padding-top: 3.875rem;
  }
  .person--with-icon .person:before, .person--with-icon .folder__image-container:before,
.folder--with-icon .person:before,
.folder--with-icon .folder__image-container:before {
    bottom: -4rem;
  }
}
@media (max-width: 991.98px) {
  .person__image-container,
.folder__image-container {
    height: 11.4375rem;
  }
  .person__icon,
.folder__icon {
    width: 3.75rem;
  }
  .person__image-content,
.folder__image-content {
    margin-top: 1rem;
    min-height: 2.5rem;
  }
  .person__image-title,
.folder__image-title {
    font-size: 1.25rem;
  }
  .person--with-icon .person, .person--with-icon .folder__image-container,
.folder--with-icon .person,
.folder--with-icon .folder__image-container {
    padding-top: 2.625rem;
  }
  .person--with-icon .person:before, .person--with-icon .folder__image-container:before,
.folder--with-icon .person:before,
.folder--with-icon .folder__image-container:before {
    bottom: -6rem;
  }
}
@media (max-width: 767.98px) {
  .person__content,
.folder__content {
    padding: 0.625rem 0;
  }
  .person__title,
.folder__title {
    font-size: 1rem;
    line-height: 1.25rem;
  }
  .person__image-container,
.folder__image-container {
    border-radius: 0.625rem;
    height: 8.9375rem;
  }
  .person__image-content,
.folder__image-content {
    min-height: 0;
  }
  .person--with-icon .person, .person--with-icon .folder__image-container,
.folder--with-icon .person,
.folder--with-icon .folder__image-container {
    height: 9.625rem;
    padding-top: 1.875rem;
  }
  .person--with-icon .person:before, .person--with-icon .folder__image-container:before,
.folder--with-icon .person:before,
.folder--with-icon .folder__image-container:before {
    bottom: -12rem;
  }
}
@media (max-width: 400px) {
  .person--with-icon .person:before, .person--with-icon .folder__image-container:before,
.folder--with-icon .person:before,
.folder--with-icon .folder__image-container:before {
    bottom: -7rem;
  }
}

.search-results__inner {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 5.125rem;
}
.search-results__title {
  font-family: "Playfair Display";
  font-size: 2.5rem;
  line-height: 1.3;
  font-weight: 900;
  margin-bottom: 1.375rem;
}
.search-results__scroll-container {
  width: 100%;
}
.search-results__categories-container {
  position: relative;
}
.search-results__categories-container:after, .search-results__categories-container:before {
  position: absolute;
  top: 0;
  right: 0;
  width: 14.375rem;
  height: 100%;
  background: #ebecec;
  background: linear-gradient(45deg, rgba(235, 236, 236, 0) 0%, #ebecec 100%);
  content: "";
  pointer-events: none;
  z-index: 1;
  display: flex;
}
.search-results__categories-container:before {
  right: auto;
  left: 0;
  background: #ebecec;
  background: linear-gradient(45deg, #ebecec 0%, rgba(235, 236, 236, 0) 100%);
  z-index: 2;
}
.search-results__categories-container--begin:after {
  display: flex;
}
.search-results__categories-container--begin:before {
  display: none;
}
.search-results__categories-container--end:after {
  display: none;
}
.search-results__categories-container--end:before {
  display: flex;
}
.search-results__categories {
  padding-left: 0;
  flex-flow: row nowrap;
  width: 100%;
  list-style-type: none;
}
.search-results__categories li {
  width: auto !important;
}
.search-results__navigation {
  top: 0.1875rem;
}
.search-results__nav-button--next, .search-results__nav-button--prev {
  background: transparent !important;
}
.search-results__nav-button--next.swiper-button-disabled, .search-results__nav-button--prev.swiper-button-disabled {
  display: none;
}
.search-results__nav-button--prev {
  left: 0 !important;
}
.search-results__nav-button--next {
  right: 0 !important;
}
.search-results__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  padding-bottom: 1.25rem;
  border-bottom: 0.0625rem solid #707070;
}
.search-results__total {
  font-size: 1.125rem;
  line-height: 1.3125rem;
}
.search-results__sort {
  position: relative;
  width: auto;
  height: 2.625rem;
  border-radius: 0.5rem;
  background: transparent url("/public/svg/chevron-down-black.svg") calc(100% - 0.6875rem) 1rem/0.8125rem no-repeat;
  border-color: #8D8D8D;
  padding-left: 1.125rem;
  padding-right: 2.25rem;
  font-size: 0.9375rem;
  line-height: 1;
  font-weight: 500;
}
.search-results__results {
  display: flex;
  flex-flow: column nowrap;
  padding-left: 0;
}
.search-results__result {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 0.0625rem solid #707070;
}
.search-results__result-left {
  display: flex;
  flex-flow: column nowrap;
  width: calc(100% - 12.5rem);
  padding-right: 1.25rem;
}
.search-results__result-right {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  width: 12.5rem;
}
.search-results__result-right .button {
  justify-content: flex-start;
}
.search-results__result-title {
  font-size: 1.3125rem;
  line-height: 1.625rem;
  margin-bottom: 0.375rem;
  font-weight: 700;
  text-decoration: none;
}
.search-results__result-title:hover {
  text-decoration: underline;
}
.search-results__result-content {
  font-size: 1.125rem;
  line-height: 1.9375rem;
  font-weight: 300;
  margin-bottom: 0;
}
.search-results__result-content span {
  font-weight: 700;
  padding: 0 0.875rem;
  border-right: 0.0625rem solid black;
}
.search-results__result-content span:first-of-type {
  padding-left: 0;
}
.search-results__result-content span:last-of-type {
  margin-right: 0.875rem;
}
.search-results__result-content a {
  text-decoration: underline;
}
.search-results__result-excerpt {
  font-weight: 300 !important;
  border-right: 0 !important;
}
.search-results__result-image-container {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 0.9375rem;
  overflow: hidden;
}
.search-results__result-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 767.98px) {
  .search-results {
    overflow: hidden;
  }
  .search-results__inner {
    padding-top: 1.5rem;
  }
  .search-results__title {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
  .search-results__categories-container {
    padding: 0.625rem 0;
    border-top: 0.0625rem solid rgba(112, 112, 112, 0.25);
    border-bottom: 0.0625rem solid rgba(112, 112, 112, 0.25);
  }
  .search-results__categories-container .tag {
    margin-bottom: 0;
  }
  .search-results__categories-container:before, .search-results__categories-container:after {
    width: 5rem;
  }
  .search-results__categories {
    margin-bottom: 0;
  }
  .search-results__navigation {
    top: 0.5rem;
  }
  .search-results__nav-button--prev {
    left: -0.25rem !important;
  }
  .search-results__nav-button--next {
    right: -0.25rem !important;
  }
  .search-results__top {
    margin-top: 0.625rem;
    padding-bottom: 0.625rem;
    border-bottom: 0.0625rem solid rgba(112, 112, 112, 0.25);
  }
  .search-results__total {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
  .search-results__sort {
    height: 2rem;
    padding-right: 1.75rem;
    padding-left: 0.5625rem;
    font-size: 0.75rem;
    border-radius: 0.3125rem;
    background: transparent url("/public/svg/chevron-down-black.svg") calc(100% - 0.5625rem) 0.8125rem/0.5rem no-repeat;
  }
  .search-results__result-left {
    width: calc(100% - 10.625rem);
  }
  .search-results__result-right {
    width: 10.625rem;
  }
  .search-results__result-title {
    font-size: 1.125rem;
    line-height: 1.375rem;
    text-decoration: underline;
  }
  .search-results__result-content {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .search-results__result-content span {
    padding: 0 0.4375rem;
  }
  .search-results__result-content span:first-of-type {
    padding-left: 0;
  }
  .search-results__result-content span:last-of-type {
    margin-right: 0.4375rem;
  }
  .search-results__result-excerpt {
    display: flex;
    padding: 0 !important;
  }
  .search-results__result-cat {
    border-right: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .search-results__categories-container {
    width: calc(100% + 2.5rem);
    transform: translateX(-1.25rem);
    padding: 0.625rem 0;
  }
  .search-results__categories li:first-of-type {
    padding-left: 1.25rem;
  }
  .search-results__top {
    width: calc(100% + 2.5rem);
    transform: translateX(-1.25rem);
    padding: 0 1.25rem 0.625rem;
  }
  .search-results__result-title {
    font-size: 1rem;
    line-height: 1.25rem;
  }
  .search-results__result-left {
    width: calc(100% - 6.875rem);
    padding-right: 0.625rem;
  }
  .search-results__result-right {
    width: 6.875rem;
  }
  .search-results__result-image-container {
    height: 6.875rem;
  }
}

.pagination {
  display: inline-flex;
  padding-left: 0;
  list-style-type: none;
}
.pagination__nav {
  display: flex;
  justify-content: center;
}
.pagination__outer {
  margin-top: 5rem;
}
@media (max-width: 767.98px) {
  .pagination__outer {
    margin-top: 1.875rem;
  }
}

.page-item {
  padding: 0 0.375rem;
  font-size: 1.0625rem;
  line-height: 1.875rem;
  font-weight: 400;
}
.page-item.active {
  font-weight: 700;
  text-decoration: underline;
}
.page-item--nav {
  position: relative;
  font-weight: 500;
}
.page-item--nav:before {
  position: absolute;
  top: 0.5625rem;
  width: 0.5rem;
  height: 0.8125rem;
  content: "";
  background: url("/public/svg/chevron-left-black.svg") center center/0.4375rem no-repeat;
}
.page-item--nav--prev {
  padding-left: 1.125rem;
  padding-right: 1rem;
}
.page-item--nav--prev:before {
  left: 0;
}
.page-item--nav--next {
  padding-right: 1.125rem;
  padding-left: 1rem;
}
.page-item--nav--next:before {
  right: 0;
  transform: rotate(180deg);
}
@media (max-width: 767.98px) {
  .page-item {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .page-item--nav:before {
    top: 0.25rem;
    background-size: 0.3125rem;
  }
}

.page-link {
  text-decoration: none;
}
.page-link:hover {
  text-decoration: underline;
}

.dp {
  display: flex;
  flex-flow: column nowrap;
}
.dp__grid {
  display: grid;
  row-gap: 0.4375rem;
  column-gap: 0.4375rem;
  grid-template-columns: repeat(7, 1fr);
  font-size: 1.0625rem;
  font-weight: 300;
  color: black;
  text-decoration: none;
  margin-bottom: 2.375rem;
}
.dp__cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.875rem;
  height: 2.875rem;
  border-radius: 0.625rem;
  text-decoration: none !important;
}
.dp__cell--state-available {
  background-color: #D1DA4D;
}
.dp__cell--state-available:hover {
  background-color: #C95100;
  color: white;
  font-weight: 900;
}
.dp__cell--state-selected {
  position: relative;
  background-color: #C95100;
  color: white !important;
  font-size: 1.25rem;
  font-weight: 900;
  border-radius: 0.5rem;
}
.dp__cell--state-selected:before {
  position: absolute;
  left: -0.5625rem;
  top: -0.5625rem;
  width: 4rem;
  height: 4rem;
  border: 0.1875rem solid #C95100;
  border-radius: 0.875rem;
  content: "";
}
.dp__cell--state-disabled {
  pointer-events: none;
  background-color: #EBECEC;
  color: #969696;
  text-decoration: line-through !important;
}
.dp__cell--day {
  height: 1.875rem;
}
.dp__cell--day--disabled {
  color: #C8C8C8;
}
.dp__cell--time {
  width: 6.625rem;
  margin-right: 0.4375rem;
  margin-bottom: 0.5rem;
}
.dp__cell--time.dp__cell--state-selected {
  font-size: 1.0625rem;
}
.dp__cell--time.dp__cell--state-selected:before {
  width: 7.75rem;
}
.dp__flex {
  display: flex;
  flex-flow: row wrap;
}
.dp__nav {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.875rem;
}
.dp__nav-chev {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.625rem;
  height: 1.625rem;
}
.dp__nav-month {
  font-size: 1.3125rem;
  line-height: 1.625rem;
}
.dp__nav-month--first {
  margin-right: auto;
  margin-left: 11%;
}
.dp__nav-month--last {
  margin-left: auto;
  margin-right: 11%;
}
.dp__select {
  padding-top: 0;
  margin-bottom: 2.375rem;
}
.dp__legend {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 1.125rem;
  justify-content: flex-end;
}
.dp__legend-item {
  display: flex;
  align-items: center;
  font-size: 1.0625rem;
  margin-left: 1.875rem;
  margin-bottom: 1.25rem;
}
.dp__legend-item .dp__cell {
  pointer-events: none;
  margin-left: 0.9375rem;
}
.dp__link {
  display: block;
  font-weight: 900;
  font-size: 1.0625rem;
  text-decoration: none;
  margin-bottom: 2.375rem;
}
.dp__link:hover {
  text-decoration: underline;
}
.dp__link--in-cell {
  padding: 0 0.625rem;
  margin-bottom: 0;
  white-space: nowrap;
  align-self: center;
}
@media (max-width: 767.98px) {
  .dp__grid {
    column-gap: 0.375rem;
  }
  .dp__cell {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 0.875rem;
    border-radius: 0.4375rem;
  }
  .dp__cell--state-selected:before {
    top: -0.5rem;
    left: -0.5rem;
    width: 3.5rem;
    height: 3.5rem;
  }
  .dp__cell--time {
    width: 5.5rem;
  }
  .dp__cell--time.dp__cell--state-selected {
    font-size: 0.875rem;
  }
  .dp__cell--time.dp__cell--state-selected:before {
    width: 6.5rem;
  }
  .dp__legend {
    flex-flow: column nowrap;
    align-items: flex-end;
    margin-bottom: 0.25rem;
  }
  .dp__legend-item {
    font-size: 0.875rem;
    margin-left: 2.0625rem;
  }
  .dp__legend-item .dp__cell {
    margin-left: 0.9375rem;
  }
  .dp__nav {
    margin-bottom: 1.5rem;
  }
  .dp__nav-chev svg {
    width: 0.375rem;
  }
  .dp__nav-month {
    font-size: 0.875rem;
  }
}

.progress {
  display: flex;
  flex-flow: row nowrap;
  padding-left: 0;
  counter-reset: progress !important;
  margin-top: 1.875rem;
}
.progress__item {
  position: relative;
  display: flex;
  flex: 1 0 auto;
  width: auto;
  justify-content: center;
  flex-flow: row nowrap;
  padding: 0 0.625rem 2.375rem;
  font-weight: 500;
}
.progress__item:before {
  position: relative !important;
  top: auto !important;
  left: auto !important;
  font-size: 0.75rem;
  line-height: 1.25rem;
  margin-right: 0.25rem;
  content: counter(progress, decimal) "." !important;
  counter-increment: progress !important;
  font-weight: 500;
}
.progress__item:after {
  position: absolute;
  left: 0;
  bottom: 0.5rem;
  width: 100%;
  height: 0.0625rem;
  background-color: #C95100;
  content: "";
}
.progress__item:first-child:after {
  width: 50%;
  right: 0;
  left: auto;
}
.progress__item:last-child:after {
  width: 50%;
}
.progress__item--complete .progress__icon:before {
  position: absolute;
  width: 2.125rem;
  height: 2.125rem;
  left: -0.5625rem;
  top: -0.5625rem;
  border-radius: 50%;
  border: 0.25rem solid #C95100;
  content: "";
}
.progress__label {
  font-size: 0.75rem;
  line-height: 1.25rem;
  white-space: nowrap;
  font-weight: 500;
}
.progress__icon {
  position: absolute;
  left: calc(50% - 0.5rem);
  bottom: 0;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #C95100;
}